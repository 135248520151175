import { useDebounce } from 'use-debounce'
import { useGetLeadsWhatsappQuery } from 'app/features/lead'
import DateAndTime from 'components/shared/date-and-time'
import MySelect from 'components/shared/select/MySelect'
import SimpleTable from 'components/simple-table'
import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'
import dayjs from 'dayjs'
import useApp from 'hooks/useApp'
import { useEffect, useState } from 'react'
import { Label } from 'components/ui/label'

export default function FilterLeads({
  setSelectedLeads,
  open,
}: {
  setSelectedLeads: any
  open: boolean
}) {
  const [keyword, setKeyword] = useState('')
  const [debouncedKeyword] = useDebounce(keyword, 500)

  const [selectedBranches, setSelectedBranches] = useState<any>([])
  const [selectedDepartments, setSelectedDepartments] = useState<any>([])
  const [selectedSpecialties, setSelectedSpecialties] = useState<any>([])

  const [startHour, setStartHour] = useState<string>('1')
  const [startMinute, setStartMinute] = useState<string>('0')
  const [startAmpm, setStartAmpm] = useState<string>('AM')

  const [endHour, setEndHour] = useState<string>('1')
  const [endMinute, setEndMinute] = useState<string>('0')
  const [endAmpm, setEndAmpm] = useState<string>('AM')

  const [startDate, setStartDate] = useState<Date | undefined>(undefined)

  const [endDate, setEndDate] = useState<Date | undefined>(undefined)

  const {
    branches: branchesData,
    departments: departmentsData,
    specialists: specialistsData,
  } = useApp()

  const [branches, setBranches] = useState([])
  const [departments, setDepartments] = useState([])
  const [specialists, setSpecialists] = useState([])

  useEffect(() => {
    setBranches(branchesData)
  }, [branchesData])

  useEffect(() => {
    setDepartments(departmentsData)
  }, [departmentsData])

  useEffect(() => {
    setSpecialists(specialistsData)
  }, [specialistsData])

  /**
   * Filter departments and specialists based on the selected branches.
   */
  useEffect(() => {
    setSelectedDepartments([])

    if (selectedBranches.length) {
      const branchesIds = selectedBranches.map((branch: any) => branch.id)

      const filteredDepartments = departmentsData.filter((department: any) =>
        branchesIds.includes(department.branch.id)
      )

      setDepartments(filteredDepartments)
    } else setDepartments(departmentsData)
  }, [selectedBranches])

  /**
   * Filter specialists based on the selected departments.
   */
  useEffect(() => {
    setSelectedSpecialties([])

    if (selectedDepartments.length) {
      const departmentsIds = selectedDepartments.map(
        (department: any) => department.id
      )

      const filteredSpecialists = specialistsData.filter((specialist: any) =>
        departmentsIds.includes(specialist.department.id)
      )

      setSpecialists(filteredSpecialists)
    } else setSpecialists(specialistsData)
  }, [selectedDepartments])

  const {
    data: leadsData,
    isFetching,
    refetch,
  } = useGetLeadsWhatsappQuery({
    keyword: debouncedKeyword,

    startDate: startDate
      ? dayjs(startDate)
          .hour(parseInt(startHour) + (startAmpm === 'PM' ? 12 : 0))
          .minute(parseInt(startMinute))
          .toDate()
      : startDate,
    endDate: endDate
      ? dayjs(endDate)
          .hour(parseInt(endHour) + (endAmpm === 'PM' ? 12 : 0))
          .minute(parseInt(endMinute))
          .toDate()
      : endDate,
    branchIDs: selectedBranches.map((branch: { id: any }) => branch.id),
    departmentIDs: selectedDepartments.map(
      (department: { id: any }) => department.id
    ),
    specialistIDs: selectedSpecialties.map(
      (specialist: { id: any }) => specialist.id
    ),
  })

  const onSelectionChange = (selectedRows: Array<Record<string, any>>) => {
    setSelectedLeads(selectedRows?.map((lead: any) => lead.id))
  }

  /**
   * Clears all filters and resets the checked state of all leads when the filter modal is closed.
   */
  useEffect(() => {
    if (open === false) handleClearFilters()
    refetch()
  }, [open])

  /**
   * Clears all filters and resets the checked state of all leads.
   * This is called when the filter modal is closed.
   */
  const handleClearFilters = () => {
    setKeyword('')
    setSelectedBranches([])
    setSelectedDepartments([])
    setSelectedSpecialties([])
    setStartDate(undefined)
    setStartHour('1')
    setStartMinute('0')
    setStartAmpm('AM')
    setEndDate(undefined)
    setEndHour('1')
    setEndMinute('0')
    setEndAmpm('AM')
  }

  return (
    <div className="w-full">
      <div className="w-full rounded-lg bg-white dark:bg-gray-900 mb-3">
        <div>
          <div className="flex mb-4 max-md:flex-col gap-2">
            <div className="w-1/3 pe-2 max-md:w-full">
              <div className="space-y-2 flex flex-col">
                <MySelect
                  selected={selectedBranches}
                  setSelected={setSelectedBranches}
                  data={branches}
                  selectMeta="branch"
                />
              </div>
            </div>
            <div className="w-1/3 pe-2 max-md:w-full">
              <div className="space-y-2 flex flex-col">
                <MySelect
                  selected={selectedDepartments}
                  setSelected={setSelectedDepartments}
                  data={departments.map((item: any) => {
                    return {
                      ...item,
                      name: item.name + ' - ' + item?.branch?.location,
                    }
                  })}
                  selectMeta="department"
                />
              </div>
            </div>
            <div className="w-1/3 pe-2 max-md:w-full">
              <div className="space-y-2 flex flex-col">
                <MySelect
                  selected={selectedSpecialties}
                  setSelected={setSelectedSpecialties}
                  //data={specialtiesData}
                  data={specialists}
                  selectMeta="specialist"
                />
              </div>
            </div>
          </div>
          <div className="flex mb-4 max-md:flex-col gap-2">
            <div className="w-1/3 pe-2 max-md:w-full">
              <div className="space-y-1 ">
                <Label className="text-xs text-gray-500">Start Date:</Label>
                <DateAndTime
                  date={startDate}
                  setDate={setStartDate}
                  hour={startHour}
                  setHour={setStartHour}
                  minute={startMinute}
                  setMinute={setStartMinute}
                  ampm={startAmpm}
                  setAmpm={setStartAmpm}
                />
              </div>
            </div>
            <div className="w-1/3 pe-2 max-md:w-full">
              <div className="space-y-1">
                <Label className="text-xs text-gray-500">End Date:</Label>
                <DateAndTime
                  date={endDate}
                  setDate={setEndDate}
                  hour={endHour}
                  setHour={setEndHour}
                  minute={endMinute}
                  setMinute={setEndMinute}
                  ampm={endAmpm}
                  setAmpm={setEndAmpm}
                  beforeSelectedDate={startDate}
                />
              </div>
            </div>
          </div>
          <div className="w-full flex items-center gap-2 mb-4 ">
            <div className=" w-full">
              <Input
                type="search"
                value={keyword}
                onChange={(e: any) => setKeyword(e.target.value)}
                placeholder="Search..."
                className="w-full"
              />
            </div>
            <div className=" ">
              <Button size="sm" type="button" onClick={handleClearFilters}>
                Clear Filters
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* leads table */}
      <SimpleTable
        allowSelection={true}
        onSelectionChange={onSelectionChange}
        columns={[
          { label: 'First name', key: 'firstName' },
          { label: 'Last name', key: 'lastName' },
          { label: 'Whatsapp number', key: 'whatsappNumber' },
        ]}
        data={leadsData}
        pagination={true}
        perPage={10}
        isLoading={isFetching}
      />
    </div>
  )
}
