import { useUpdateSettingsMutation } from 'app/features/settings'
import Error from 'components/shared/error'
import Loader from 'components/shared/loader'
import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import { useEffect, useState } from 'react'
import FollowUpConfirm from './follow-up-confirm'
import { isExist } from 'lib/utils'
import useApp from 'hooks/useApp'

export default function FollowUp() {
  const { settings: oldSettings } = useApp()
  const [error, setError] = useState('')
  const [open, setOpen] = useState(false)
  const [updateSettings, { isLoading, isError }] = useUpdateSettingsMutation()

  const [settings, setSettings] = useState({
    numberOfFollowUps: oldSettings?.[0]?.numberOfFollowUps,
    followUpTimeGap: oldSettings?.[0]?.followUpTimeGap,
    timeAccessType: oldSettings?.[0]?.timeAccessType,
    timeAccessValue: oldSettings?.[0]?.timeAccessValue,
  })

  useEffect(() => {
    if (oldSettings?.[0]) {
      setSettings({
        numberOfFollowUps: oldSettings[0].numberOfFollowUps,
        followUpTimeGap: oldSettings[0].followUpTimeGap,
        timeAccessType: oldSettings[0].timeAccessType,
        timeAccessValue: oldSettings[0].timeAccessValue,
      })
    }
  }, [oldSettings])

  function convertTimeGap(value: any, type: any) {
    if (type === 'HOUR') return value * 60 * 60
    if (type === 'DAY') return value * 24 * 60 * 60
    if (type === 'WEEK') return value * 24 * 7 * 60 * 60
  }

  async function update() {
    setError('')
    if (!isExist(Object.values(settings))) {
      return setError('All fields are required')
    }

    await updateSettings({
      id: oldSettings[0]?.id,
      numberOfFollowUps: parseInt(settings.numberOfFollowUps),
      followUpTimeGap: convertTimeGap(
        settings.timeAccessValue,
        settings.timeAccessType
      ),
      timeAccessType: settings.timeAccessType,
      timeAccessValue: parseInt(settings.timeAccessValue),
    })
    window.location.reload()
  }

  if (isLoading || !oldSettings?.[0]) {
    return (
      <div className="w-full h-full grid place-content-center pt-20">
        <Loader />
      </div>
    )
  }

  if (isError) {
    return (
      <div className="w-full h-full grid place-content-center pt-20">
        <Error message="Server error, contact administrator please" />
      </div>
    )
  }

  return (
    <>
      <div className="flex flex-col p-6 gap-3">
        <div className="py-3 border-b border-dashed grid grid-cols-2 items-center gap-3">
          <div className="flex flex-col gap-1">
            <h1 className="text-lg font-medium">Number of follow ups</h1>
            <p className="text-xs text-gray-500">
              Select the numbers of follow ups. you can select from 1 to 7
            </p>
          </div>
          <div className="flex justify-end">
            <Select
              value={settings.numberOfFollowUps}
              onValueChange={(value: any) =>
                setSettings({
                  ...settings,
                  numberOfFollowUps: value,
                })
              }
            >
              <SelectTrigger className="w-[70px]">
                <SelectValue>{settings.numberOfFollowUps}</SelectValue>
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectItem value="1">1</SelectItem>
                  <SelectItem value="2">2</SelectItem>
                  <SelectItem value="3">3</SelectItem>
                  <SelectItem value="4">4</SelectItem>
                  <SelectItem value="5">5</SelectItem>
                  <SelectItem value="6">6</SelectItem>
                  <SelectItem value="7">7</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        </div>

        <div className="py-3 border-b border-dashed grid grid-cols-2 items-center gap-3">
          <div className="flex flex-col gap-1">
            <h1 className="text-lg font-medium">Follow up time gap</h1>
            <p className="text-xs text-gray-500">
              Select the time gap between each follow up in hours, days or weeks
            </p>
          </div>
          <div className="flex items-center gap-3 justify-end">
            <Input
              className="w-[70px]"
              type="number"
              value={settings.timeAccessValue}
              onChange={(event: any) =>
                setSettings({
                  ...settings,
                  timeAccessValue: event.target.value,
                })
              }
              min={1}
              max={99}
            />
            <Select
              value={settings.timeAccessType}
              onValueChange={(value: any) =>
                setSettings({
                  ...settings,
                  timeAccessType: value,
                })
              }
            >
              <SelectTrigger className="w-[100px]">
                <SelectValue>
                  {!settings.timeAccessType
                    ? ''
                    : settings.timeAccessType === 'HOUR'
                      ? 'Hours'
                      : settings.timeAccessType === 'DAY'
                        ? 'Days'
                        : 'Weeks'}
                </SelectValue>
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectItem value="HOUR">Hours</SelectItem>
                  <SelectItem value="DAY">Days</SelectItem>
                  <SelectItem value="WEEK">Weeks</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        </div>

        <div className="mt-5 flex justify-between items-center">
          {error !== '' && (
            <p className="px-3 py-2 bg-rose-100 rounded-md text-xs font-medium text-rose-600">
              {error}
            </p>
          )}
          <div className="flex-1" />
          <Button onClick={() => setOpen(true)} size="sm">
            Update
          </Button>
        </div>
      </div>
      <FollowUpConfirm update={update} open={open} setOpen={setOpen} />
    </>
  )
}
