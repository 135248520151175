import { useGetAnalyticsQuery } from 'app/features/whatsappStats'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import { toast } from 'components/ui/use-toast'
import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts'
import { DateProps } from './date-filters'
export const WhatsappAnalytics = ({
  startDate,
  startHour,
  startAmpm,
  startMinute,
  endDate,
  endHour,
  endMinute,
  endAmpm,
}: DateProps) => {
  const [whatsAppAnalyticsGranularity, setWhatsAppAnalyticsGranularity] =
    useState('DAY')
  const [
    whatsAppAnalyticsGranularityItems,
    setWhatsAppAnalyticsGranularityItems,
  ] = useState([
    {
      value: 'HALF_HOUR',
      label: 'Half Hour',
    },
    {
      value: 'DAY',
      label: 'Day',
    },
    {
      value: 'MONTH',
      label: 'Month',
    },
  ])

  /**
   * Determines whether the analytics query should be skipped based on the
   * selected granularity and the time difference between the start and end dates.
   *
   * @returns true if the time gap between startDate and endDate
   * is less than the defined granularity duration, indicating that the query should be skipped.
   * Otherwise, returns false.
   *
   * The function checks the granularity setting (HALF_HOUR, DAY, MONTH) and calculates
   * the time difference accordingly. If no granularity is set, it defaults to not skipping the query.
   */
  const shouldSkipAnalyticsQuery = useMemo(() => {
    if (!whatsAppAnalyticsGranularity) return false

    const granularitySecondsItems = {
      HALF_HOUR: 1800,
      DAY: 86400,
      MONTH: 2592000,
    }

    const granularitySeconds =
      granularitySecondsItems[
        whatsAppAnalyticsGranularity as keyof typeof granularitySecondsItems
      ]

    const start = dayjs(startDate)
      .hour(Number(startHour))
      .minute(Number(startMinute))
    const end = dayjs(endDate).hour(Number(endHour)).minute(Number(endMinute))

    if (whatsAppAnalyticsGranularity === 'HALF_HOUR') {
      const gap = end.diff(start, 'second')

      return gap < granularitySeconds
    } else if (whatsAppAnalyticsGranularity === 'DAY') {
      const gap = end.diff(start, 'second')

      return gap < granularitySeconds
    } else if (whatsAppAnalyticsGranularity === 'MONTH') {
      const gap = end.diff(start, 'second')

      return gap < granularitySeconds
    }
    return false
  }, [
    startDate,
    endDate,
    startHour,
    startMinute,
    endHour,
    endMinute,
    whatsAppAnalyticsGranularity,
  ])

  const {
    refetch: refetchAnalytics,
    isLoading: isLoadingAnalytics,
    data: analyticsChartDate,
    isError: isErrorAnalytics,
  } = useGetAnalyticsQuery(
    {
      from: dayjs(startDate)
        .hour(Number(startHour))
        .set('hour', Number(startHour) + (startAmpm === 'PM' ? 12 : 0))
        .minute(Number(startMinute))
        .toISOString(),
      to: dayjs(endDate)
        .hour(Number(endHour))
        .set('hour', Number(endHour) + (endAmpm === 'PM' ? 12 : 0))
        .minute(Number(endMinute))
        .toISOString(),
      granularity: whatsAppAnalyticsGranularity,
    },
    {
      skip: shouldSkipAnalyticsQuery,
    }
  )

  useEffect(() => {
    if (isErrorAnalytics) {
      toast({
        title: 'Error occurred',
        description: 'Error occurred while fetching analytics',
      })
    }
  }, [isErrorAnalytics])

  const formatXAxisAnalytics = (tick: any) => {
    return dayjs(tick).format('MM/DD')
  }

  /**
   * Custom tooltip for WhatsApp analytics chart.
   *
   * @param {Object} param
   * @param {boolean} param.active - Whether the tooltip is active.
   * @param {Array} param.payload - Data payload for the tooltip.
   * @param {string} param.label - Label for the tooltip.
   *
   * @returns {ReactNode} - JSX for the tooltip.
   */
  const customAnalyticsTooltip = ({ active, payload, label }: any) => {
    const g = whatsAppAnalyticsGranularity
    const addedPeriod = g == 'HALF_HOUR' ? 30 : g == 'DAY' ? 1 : 30
    const addedType = g == 'HALF_HOUR' ? 'minute' : g == 'DAY' ? 'day' : 'day'

    if (active && payload && payload.length) {
      return (
        <div className="card bg-white dark:bg-gray-900 p-2 shadow">
          <p>
            {dayjs(label).format('YYYY/MM/DD HH:mm')}
            &nbsp;to&nbsp;
            {dayjs(label).add(addedPeriod, addedType).format('MM/DD HH:mm')}
          </p>
          <p style={{ color: '#8884d8' }}>Sent messages: {payload[0].value}</p>
          <p style={{ color: '#82ca9d' }}>
            Delivered messages: {payload[1].value}
          </p>
        </div>
      )
    }

    return null
  }

  return (
    <>
      <h1 className="text-2xl font-bold mt-10 mb-4">WhatsApp Analytics </h1>
      <div className="text-sm mb-2">
        This chart visualizes the number of sent messages and delivered over the
        date range selected by filters.
      </div>
      <div className="text-sm mb-2">
        You can change the granularity of the chart by selecting the option in
        the dropdown below.
      </div>

      <div className="flex justify-end gap-4 mt-4">
        <div>
          <Select
            value={whatsAppAnalyticsGranularity}
            onValueChange={(value: any) =>
              setWhatsAppAnalyticsGranularity(value)
            }
          >
            <SelectTrigger className="w-full">
              <SelectValue>
                {
                  whatsAppAnalyticsGranularityItems.find(
                    (item) => item.value === whatsAppAnalyticsGranularity
                  )?.label
                }
              </SelectValue>
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {whatsAppAnalyticsGranularityItems.map((item: any) => (
                  <SelectItem key={item.value} value={item.value}>
                    {item.label}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
      </div>

      <div style={{ position: 'relative' }}>
        <div className={shouldSkipAnalyticsQuery ? 'blur-md' : ''}>
          <BarChart
            width={1200}
            height={200}
            data={analyticsChartDate}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="start" tickFormatter={formatXAxisAnalytics} />
            <YAxis />
            <Tooltip content={customAnalyticsTooltip} />
            <Legend />
            <Bar dataKey="sent" fill="#8884d8" name="Messages Sent" />
            <Bar dataKey="delivered" fill="#82ca9d" name="Messages Delivered" />
          </BarChart>
        </div>
        {shouldSkipAnalyticsQuery && (
          <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center ">
            <p className="text-sm mt-4">
              <span className="font-bold">Note:</span> Analytics data is not
              available for the selected date range. Gap should be bigger than
              selected granularity.
            </p>
          </div>
        )}
      </div>
    </>
  )
}
