import { api } from '../api'

export const offersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSales: builder.query({
      query: (filters: { offerId?: string; leadId?: string }) => {
        const params = new URLSearchParams()
        if (filters.offerId) params.append('offer', filters.offerId)
        if (filters.leadId) params.append('lead', filters.leadId)
        const queryString = params.toString()

        return `/sales?${queryString}`
      },
      providesTags: ['Sales'],
    }),
    createSale: builder.mutation({
      query: (data: {
        offer: string
        lead: string
        date: string
        note: string
      }) => ({
        url: `/sales`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Sales'],
    }),
    outstandingAmount: builder.query({
      query: (leadId) => `/payments/outstanding?lead=${leadId}`,
      providesTags: ['Sales'],
    }),
  }),
})

export const {
  useGetSalesQuery,
  useCreateSaleMutation,
  useOutstandingAmountQuery,
} = offersApi
