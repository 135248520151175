import { createContext, useContext, useEffect, useRef, useState } from 'react'
import { useAppDispatch } from '../app/store'
import { IOEvents, SocketClient } from 'lib/socket'
import { api } from 'app/api'
import { useSelector } from 'react-redux'
import { isMobile } from 'react-device-detect'
import { accessibleTab } from 'lib/allowed'
import { useLazyGetActiveTabsQuery } from 'app/features/settings'
import { useLazyGetUserQuery } from 'app/features/users'

export const SocketContext = createContext<any | null>(null)

export const SocketProvider = ({ children }: { children: any }) => {
  const dispatch = useAppDispatch()
  const { socketServerToken, user } = useSelector((state: any) => state.user)
  const [getActiveTabs] = useLazyGetActiveTabsQuery({})
  const [getUser] = useLazyGetUserQuery({})

  const [socket, setSocket] = useState<SocketClient>()
  useEffect(() => {
    setSocket(SocketClient.getInstance(socketServerToken))
  }, [user])

  const [check, setCheck] = useState(false)
  const hasUserInteractedRef = useRef(false)

  const playNotificationSound = () => {
    const isMac = /(Mac|iPhone|iPod|iPad)/i.test(navigator.userAgent)
    if (!isMobile || !isMac) {
      try {
        const audio = new Audio('/notification-sound.wav')
        audio.play()
      } catch (err: any) {}
    } else {
      const event = () => {
        hasUserInteractedRef.current = true
        try {
          const audio = new Audio('/notification-sound.wav')
          audio.play()
        } catch (err: any) {}
        document.removeEventListener('touchstart', event)
      }
      document.addEventListener('touchstart', event)
    }
  }

  useEffect(() => {
    if (socket) {
      socket.onMessage('message', async () => {
        dispatch(api.util.invalidateTags(['Contacts']))
        playNotificationSound()
      })

      socket.on(IOEvents.AUTH_DEVICES, async () => {
        const settingsActiveTabsRes = await getActiveTabs()
        const settingsActiveTabsData = settingsActiveTabsRes?.data
          ? settingsActiveTabsRes.data
          : []
        if (accessibleTab('Login_Requests', user, settingsActiveTabsData)) {
          dispatch(api.util.invalidateTags(['AuthDevices']))
          playNotificationSound()
        }
      })
      socket.on(IOEvents.LEAD_CREATED, () => {
        dispatch(
          api.util.invalidateTags([
            { type: 'Leads', id: 'LIST' },
            'Leads',
            'Dashboard',
          ])
        )
      })
      socket.on(IOEvents.LEAD_UPDATED, (data) => {
        dispatch(
          api.util.invalidateTags([
            { type: 'Leads', id: 'LIST' },
            { type: 'Lead', id: data?.id },
            'Leads',
            'Lead',
            'Dashboard',
          ])
        )
      })
      socket.on(IOEvents.USER_UPDATED, (data) => {
        dispatch(
          api.util.invalidateTags([
            {
              type: 'Users',
              id: data?.id,
            },
            { type: 'Users', id: 'LIST' },
            { type: 'Users' },
          ])
        )
      })
      socket.on(IOEvents.TASK_CREATED, async (data) => {
        if (data?.users?.includes(user?.id)) {
          dispatch(api.util.invalidateTags(['Tasks']))
        }
      })
      socket.on(IOEvents.TASK_UPDATED, (data) => {
        if (
          data?.assigneeId == user?.id ||
          data.reporterId == user?.id ||
          user?.role != 'AGENT'
        ) {
          dispatch(api.util.invalidateTags(['Tasks']))
        }
      })
      socket.on(IOEvents.SETTINGS_UPDATED, () => {
        dispatch(api.util.invalidateTags(['Settings']))
      })
      socket.on(IOEvents.NEW_META_MESSAGE, async () => {
        const settingsActiveTabsRes = await getActiveTabs()
        const settingsActiveTabsData = settingsActiveTabsRes?.data
          ? settingsActiveTabsRes.data
          : []
        /* User should be fetched every time because group tabs might changed, and because of closure in javascript store the old user */
        const userRes = await getUser(user.id)
        const userData = userRes?.data ? userRes.data : {}
        if (accessibleTab('Social', userData, settingsActiveTabsData)) {
          playNotificationSound()
          dispatch(api.util.invalidateTags(['MetaConversations']))
        }
      })
      socket.on(IOEvents.NEW_WHATSAPP_MESSAGE, async () => {
        const settingsActiveTabsRes = await getActiveTabs()
        const settingsActiveTabsData = settingsActiveTabsRes?.data
          ? settingsActiveTabsRes.data
          : []
        const userRes = await getUser(user.id)
        const userData = userRes?.data ? userRes.data : {}
        if (accessibleTab('Social', userData, settingsActiveTabsData)) {
          playNotificationSound()
          dispatch(
            api.util.invalidateTags([
              'Conversations',
              'Contacts',
              { type: 'Contacts', id: 'LIST' },
            ])
          )
        }
      })
    }
  }, [socket])

  return (
    <SocketContext.Provider
      value={{
        socket,
        check,
        setCheck,
      }}
    >
      {children}
    </SocketContext.Provider>
  )
}

export default function useSocket() {
  return useContext(SocketContext)
}
