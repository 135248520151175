import { Cross1Icon } from '@radix-ui/react-icons'
import {
  useMediaFileMutation,
  useSendBulkCampaignMutation,
  useSendCampaignMutation,
} from 'app/features/whatsapp'
import { AlertDialog, AlertDialogContent } from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'
import { useEffect, useState } from 'react'
import { cn, prepareMediaFormData } from 'lib/utils'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import FillCampaignTemplate from './fill-campaign-template'
import { SelectTemplate } from '../select-template'
import { toast } from 'components/ui/use-toast'
import FilterLeads from './filter-leads'
import BulkUpload from './bulk-upload'
import { Label } from 'components/ui/label'

const initialData = {
  template: {},
  leads: [],
}

export default function CreateCampaign({ open, setOpen }: any) {
  const [data, setData] = useState<any>(initialData)
  const [step, setStep] = useState(1)
  const [mode, setMode] = useState<'List' | 'File'>('List')

  const [creatingError, setCreatingError] = useState('')
  const [isCreatingLoading, setIsCreatingLoading] = useState(false)

  const [sendCampaignFunction] = useSendCampaignMutation()
  const [sendBulkCampaignFunction] = useSendBulkCampaignMutation()
  const [mediaFileFunction] = useMediaFileMutation()

  useEffect(() => {
    setStep(1)
    setData(initialData)
    setCreatingError('')
    setIsCreatingLoading(false)
    setMode('List')
  }, [open])
  useEffect(() => {
    setData({ ...data, leads: [] })
  }, [mode])

  function addTemplate(template: any) {
    setData({ ...data, template: template })
  }

  function selectLeadsFromList(leadIds: string[]) {
    setData({ ...data, leads: leadIds })
  }

  function selectLeadsFromFile(leads: any[]) {
    setData({ ...data, leads })
  }

  const sendCampaignClick = async () => {
    if (!data.templateData || !data.leads || data.leads.length == 0)
      return setCreatingError('please select leads and fill template')

    setCreatingError('')
    setIsCreatingLoading(true)
    const {
      headerFormat,
      headerMediaSampleFile,
      name,
      language,
      category,
      headerTextSample,
      bodySample,
      components,
      carousel,
    } = data.templateData
    try {
      /* Header media upload */
      let uploadedHeaderMediaSampleFile = null

      if (
        headerFormat == 'VIDEO' ||
        headerFormat == 'IMAGE' ||
        headerFormat == 'DOCUMENT'
      ) {
        const formData = prepareMediaFormData({
          my_file: headerMediaSampleFile,
        })
        const uploadResult: any = await mediaFileFunction(formData)
        if (uploadResult?.error) {
          setIsCreatingLoading(false)
          return setCreatingError('Failed to upload media, please try again')
        }
        uploadedHeaderMediaSampleFile = uploadResult?.data?.id
      }

      /* Carousel */
      for (let i = 0; i < carousel?.length; i++) {
        /* Card Header media upload */
        const formData = prepareMediaFormData({
          my_file: carousel[i].headerMediaSampleFile,
        })

        const uploadResult: any = await mediaFileFunction(formData)
        if (uploadResult?.error) {
          setIsCreatingLoading(false)
          return setCreatingError('Failed to upload media, please try again')
        }
        const headerMediaSampleFile: any = uploadResult?.data?.id
        carousel[i].headerMediaSampleFile = headerMediaSampleFile!
      }

      /* Create Campaign */
      const body = {
        leads: data.leads,
        template: {
          name,
          category,
          language,
          headerFormat,
          headerMediaSampleFile: uploadedHeaderMediaSampleFile,
          headerTextSample,
          bodySample,
          carousel,
          components: components,
        },
      }
      const result: any =
        mode == 'List'
          ? await sendCampaignFunction(body)
          : await sendBulkCampaignFunction(body)

      if (result?.error) {
        setIsCreatingLoading(false)
        return setCreatingError('Failed to create campaign, please try again')
      }

      toast({
        title: 'Campaign created successfully',
        description: `${result.data?.successfulCampaigns} messages sent successfully, ${result.data?.failedCampaigns} messages failed `,
      })
      setData(initialData)
      setIsCreatingLoading(false)
      setCreatingError('')

      setOpen(false)
    } catch (error) {
      setIsCreatingLoading(false)
      return setCreatingError('Failed to create campaign, please try again')
    }
  }

  return (
    <>
      <AlertDialog open={open} onOpenChange={setOpen}>
        <AlertDialogContent className="sm:max-w-[425px] md:max-w-[745px] bg-white dark:bg-gray-900 pr-3">
          <div className="h-full w-full">
            <div className="max-h-[70vh] overflow-hidden overflow-y-scroll">
              <div className="flex w-full items-center justify-between">
                <h1 className="text-lg font-semibold capitalize">
                  New Campaign
                </h1>

                <Button
                  variant="outline"
                  size="icon"
                  onClick={() => setOpen(false)}
                >
                  <Cross1Icon className="h-3 w-3" />
                </Button>
              </div>
              <div className={`space-y-3 mt-5 ${step != 1 && 'hidden'}`}>
                <SelectTemplate
                  selectTemplate={addTemplate}
                  selectedTemplate={data?.template}
                />
                <div className="mt-5 ">
                  {data.template.id && (
                    <FillCampaignTemplate
                      template={data.template}
                      setCampaignData={setData}
                      setStep={setStep}
                    />
                  )}
                </div>
              </div>

              <div className={`space-y-3 mt-5 ${step != 2 && 'hidden'}`}>
                <div className=" flex gap-4 items-center space-y-2 mb-10">
                  <Label className=" whitespace-nowrap">Select Leads</Label>
                  <Select
                    value={mode}
                    onValueChange={(value: any) => setMode(value)}
                  >
                    <SelectTrigger>
                      <SelectValue>{mode}</SelectValue>
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectItem value={'List'}>List</SelectItem>
                        <SelectItem value={'File'}>File</SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
                {mode == 'List' && (
                  <FilterLeads
                    open={open}
                    setSelectedLeads={selectLeadsFromList}
                  />
                )}
                {mode == 'File' && (
                  <BulkUpload open={open} setLeads={selectLeadsFromFile} />
                )}
                {creatingError !== '' && (
                  <div className="text-rose-600 text-sm">{creatingError}</div>
                )}
                <div className="pt-5 flex items-center justify-between">
                  <Button
                    size="sm"
                    variant="outline"
                    onClick={() => setStep(1)}
                  >
                    Back
                  </Button>
                  <Button
                    size="sm"
                    onClick={sendCampaignClick}
                    disabled={isCreatingLoading || data.leads?.length == 0}
                  >
                    Send Campaign
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}
