import { Cross1Icon } from '@radix-ui/react-icons'
import {
  useCancelBookLeadMutation,
  useClaimLeadMutation,
  useLazyGetLeadQuery,
} from 'app/features/lead'
import Loader from 'components/shared/loader'
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'

import dayjs from 'dayjs'
import { cn, formatDate, isDatePastThan } from 'lib/utils'
import { useEffect, useState } from 'react'
import { LuCalendarCheck2 } from 'react-icons/lu'
import { FaLocationDot } from 'react-icons/fa6'
import { TbListDetails } from 'react-icons/tb'
import { useSelector } from 'react-redux'
import { MdOutlineModeComment, MdOutlineQuestionMark } from 'react-icons/md'

export default function CancelBook({ leadId, open, setOpen }: any) {
  const { user } = useSelector((state: any) => state.user)
  const [cancelBookLead, { isLoading }] = useCancelBookLeadMutation()
  const [booking, setBooking] = useState<any>({
    date: undefined,
    comment: '',
    branchId: '',
    departmentId: '',
    specialistId: '',
  })
  const [error, setError] = useState('')
  const [claimLead] = useClaimLeadMutation()

  const [isFetchingLead, setIsFetchingLead] = useState(false)
  const [getLead, { data: lead, isLoading: isLoadingLead }] =
    useLazyGetLeadQuery()

  useEffect(() => {
    const fetch = async () => {
      setIsFetchingLead(true)
      const res = await getLead(leadId)
      if (
        res.data &&
        (!res.data?.claimed || isDatePastThan(res.data?.updatedAt))
      ) {
        claimLead({ id: leadId, data: true })
      }

      // get recent booking data
      if (res.data) {
        const bookings = [...res.data.bookings]
        bookings?.sort((a: any, b: any) => {
          const dateA = new Date(a.createdAt)
          const dateB = new Date(b.createdAt)
          if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
            throw new Error('Invalid date format')
          }
          return dateB.getTime() - dateA.getTime() // Sort in descending order
        })
        const recentBook = bookings[0]
        const recentBookDate = dayjs(recentBook.date)
        setBooking({
          branch: recentBook.branch?.name,
          department: recentBook.department?.name,
          specialist: recentBook.specialist?.name,
          date: recentBookDate,
          comment: recentBook.comment,
        })
      }
      setIsFetchingLead(false)
    }
    fetch()
  }, [])

  const onOpenChange = async () => {
    if (
      open &&
      (!lead?.claimed ||
        (lead?.claimed && lead?.claimedBy?.id == user?.id) ||
        isDatePastThan(lead?.updatedAt))
    ) {
      claimLead({ id: leadId, data: false })
    }
    setOpen((prev: any) => !prev)
  }

  useEffect(() => {
    const handleBeforeUnload = async (event: any) => {
      event.preventDefault() // Cancel the default behavior of refreshing
      if (!lead?.claimed || (lead?.claimed && lead?.claimedBy?.id == user?.id))
        claimLead({ id: leadId, data: false })
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  const cancelHandler = async () => {
    try {
      await cancelBookLead({
        leadId: lead.id,
      })
      await claimLead({ id: leadId, data: false })
      setOpen(false)
    } catch (error) {
      setError('Failed to cancel book')
    }
  }

  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent>
        <div className="absolute right-3 top-3">
          <AlertDialogCancel className="p-0">
            <Button variant="ghost">
              <Cross1Icon className="h-3 w-3" />
            </Button>
          </AlertDialogCancel>
        </div>

        {error !== '' && <p className="text-rose-500">{error}</p>}

        {(isLoading || isLoadingLead || isFetchingLead) && (
          <div className="h-40 grid place-content-center">
            <Loader />
          </div>
        )}
        {lead &&
        lead?.claimed &&
        lead?.claimedBy?.id != user?.id &&
        !isDatePastThan(lead?.updatedAt) ? (
          <>
            <AlertDialogHeader>
              <div className="h-40 grid place-content-center">
                Lead is claimed by another agent: {lead.claimedBy?.firstName}{' '}
                {lead.claimedBy?.lastName}
              </div>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>OK</AlertDialogCancel>
            </AlertDialogFooter>
          </>
        ) : (
          <>
            <AlertDialogTitle>Cancel book</AlertDialogTitle>

            {lead && !isFetchingLead && (
              <div>
                <div className="flex gap-3 flex-col  ">
                  <div className="flex-col my-2 gap-2 ml-2 mb-10">
                    <div className="flex items-center gap-2">
                      <TbListDetails className="text-gray-500" />
                      {booking?.branch} {' - '} {booking?.department} {' - '}
                      {booking?.specialist}
                    </div>
                    <div className="flex items-center gap-2">
                      <LuCalendarCheck2 className="text-gray-500" />
                      {formatDate(booking?.date, false, true)}
                    </div>
                    <div className="flex items-center gap-2">
                      <MdOutlineModeComment className="text-gray-500" />

                      {booking?.comment}
                    </div>
                  </div>
                  <div className="flex items-center gap-1">
                    Are you sure
                    <MdOutlineQuestionMark />
                  </div>
                  <div className="flex-1" />
                  <div className=" flex items-center justify-end">
                    <Button onClick={cancelHandler}>Confirm</Button>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </AlertDialogContent>
    </AlertDialog>
  )
}
