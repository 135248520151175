import Error from 'components/shared/error'
import Loader from 'components/shared/loader'
import { useEffect, useState } from 'react'

import Pagination from 'components/table/pagination'

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'

import { UpdateTask } from './components/update-task'
import { useToast } from 'components/ui/use-toast'
import { Input } from 'components/ui/input'

import {
  useGetTasksQuery,
  useMarkTaskAsReadMutation,
  useUpdateTaskMutation,
} from 'app/features/task'
import CreateTask from './components/create-task'
import { useSelector } from 'react-redux'
import { Task } from './components/task'
import GenerateReport from './components/generate-report'
export default function Tasks() {
  const { toast } = useToast()
  const { user } = useSelector((state: any) => state.user)
  const [filterStatus, setFilterStatus] = useState('All')
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [keyword, setKeyword] = useState('')
  const [openUpdate, setOpenUpdate] = useState(false)
  const [updateStatus, setUpdateStatus] = useState('In_Progress')
  const [selectedTask, setSelectedTask] = useState()
  const [filters, setFilters] = useState<{
    skip: number
    take: number
    status?: string
    userKeyword?: string
  }>({
    skip: Math.abs((currentPage - 1) * rowsPerPage),
    take: rowsPerPage,
    status: filterStatus,
    userKeyword: '',
  })
  const { data, isLoading, isError, isFetching } = useGetTasksQuery(
    { ...filters },
    { refetchOnFocus: true }
  )

  const [update, { isLoading: isUpdateLoading }] = useUpdateTaskMutation()
  const [markAsRead] = useMarkTaskAsReadMutation()

  useEffect(() => {
    setFilters({
      ...filters,
      skip: Math.abs((currentPage - 1) * rowsPerPage),
      take: rowsPerPage,
      status: filterStatus,
      userKeyword: keyword,
    })
  }, [currentPage, filterStatus, keyword, rowsPerPage])

  const updateHandler = async () => {
    const payload = (await update({
      status: updateStatus,
      taskId: selectedTask!,
    })) as any
    if (payload?.data) {
      toast({
        title: 'task',
        description: 'task updated successfully ',
      })
    } else if (payload?.error) {
      toast({
        title: 'Error occured',
        description: payload?.error?.data,
      })
    }

    setOpenUpdate(false)
  }
  if (isLoading) {
    return (
      <div className="w-full h-full grid place-content-center">
        <Loader />
      </div>
    )
  }

  if (isError) {
    return (
      <div className="w-full h-full grid place-content-center">
        <Error message="Server error, contact administrator please" />
      </div>
    )
  }

  return (
    <>
      <div className="p-3 w-full space-y-3 h-screen max-w-7xl overflow-scroll ">
        <div className="flex justify-between items-center ">
          <div className="flex items-center  gap-2 justify-start mt-12">
            <div className="space-y-2 flex flex-col">
              <Input
                type="text"
                placeholder="Search"
                value={keyword}
                className="bg-white"
                onChange={(e: any) => setKeyword(e.target.value)}
              />
            </div>
            <div className="space-y-2 flex flex-col">
              <Select
                value={filterStatus}
                onValueChange={(value: any) => setFilterStatus(value)}
              >
                <SelectTrigger className="w-40 ">
                  <SelectValue>{filterStatus.replace('_', ' ')}</SelectValue>
                </SelectTrigger>
                <SelectContent className=" w-40  ">
                  <SelectGroup>
                    <SelectItem value={'Pending'}>Pending</SelectItem>
                    <SelectItem value={'In_Progress'}>In Progress</SelectItem>
                    <SelectItem value={'In_Review'}>In Review</SelectItem>
                    <SelectItem value={'Canceled'}>Canceled</SelectItem>
                    <SelectItem value={'Completed'}>Completed</SelectItem>
                    <SelectItem value="All">All</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          </div>
          <div className="flex justify-center items-start gap-2">
            {user?.role != 'AGENT' && <GenerateReport />}
            {(user?.role != 'AGENT' ||
              user?.group?.roles?.includes('Add_Tasks')) && <CreateTask />}
          </div>
        </div>

        <div className="border rounded-md bg-white overflow-hidden overflow-x-scroll ">
          {data?.tasks?.map((task: any) => {
            return (
              <Task
                task={task}
                user={user}
                setOpenUpdate={setOpenUpdate}
                setUpdateStatus={setUpdateStatus}
                setSelectedTask={setSelectedTask}
                isUpdateLoading={isUpdateLoading}
                markAsRead={markAsRead}
              />
            )
          })}
        </div>
        <div>
          {data?.count > 0 && (
            <Pagination
              count={data?.count}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
            />
          )}
        </div>
      </div>
      <UpdateTask
        open={openUpdate}
        setOpen={setOpenUpdate}
        handleUpdate={updateHandler}
        isLoading={isUpdateLoading}
        status={updateStatus}
      />
    </>
  )
}
