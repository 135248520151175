import {
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
} from 'recharts'
import { useGetCampaignsQuery } from 'app/features/whatsapp'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { toast } from 'components/ui/use-toast'
import { DateProps } from './date-filters'
export const CampaignStatistics = ({
  startDate,
  startHour,
  startAmpm,
  startMinute,
  endDate,
  endHour,
  endMinute,
  endAmpm,
}: DateProps) => {
  const [campaigns, setCampaigns] = useState<
    {
      createdAt: string
      succeeded: number
      failed: number
    }[]
  >([])

  const {
    refetch: refetchCampaigns,
    isLoading: isLoadingCampaigns,
    data: campaignsData,
    isError: isErrorCampaigns,
  } = useGetCampaignsQuery({
    from: dayjs(startDate)
      .hour(Number(startHour))
      .set('hour', Number(startHour) + (startAmpm === 'PM' ? 12 : 0))
      .minute(Number(startMinute))
      .toISOString(),
    to: dayjs(endDate)
      .hour(Number(endHour))
      .set('hour', Number(endHour) + (endAmpm === 'PM' ? 12 : 0))
      .minute(Number(endMinute))
      .toISOString(),
  })

  useEffect(() => {
    if (campaignsData) {
      const rawData = campaignsData?.campaigns

      const processedData = rawData.map((item: any) => ({
        name: new Date(item.createdAt).toISOString().split('T')[0], // Use date as the name
        succeeded: item.succeeded?.length || 0,
        failed: item.failed?.length || 0,
      }))

      const aggregatedData = processedData.reduce(
        (acc: any, { createdAt, succeeded, failed }: any) => {
          const existing = acc.find((item: any) => item.createdAt === createdAt)
          if (existing) {
            existing.succeeded += succeeded
            existing.failed += failed
          } else {
            acc.push({
              createdAt: dayjs(createdAt).format('YYYY-MM-DD'),
              succeeded,
              failed,
            })
          }
          return acc
        },
        []
      )

      setCampaigns(aggregatedData)
    }
  }, [campaignsData])

  useEffect(() => {
    if (isErrorCampaigns) {
      toast({
        title: 'Error occurred',
        description: 'Error occurred while fetching campaigns',
      })
    }
  }, [isErrorCampaigns])

  return (
    <>
      <h1 className="text-2xl font-bold mb-2">Campaigns</h1>
      <div className="text-sm mb-8">
        This chart visualizes the performance of our campaigns, including the
        number of successful and failed deliveries
      </div>

      <LineChart
        width={1000}
        height={200}
        data={campaigns}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="createdAt" />
        <YAxis interval={1} />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="succeeded" stroke="#82ca9d" />
        <Line type="monotone" dataKey="failed" stroke="#de3769" />
      </LineChart>
    </>
  )
}
