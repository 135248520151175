import { MdMessage } from 'react-icons/md'
import { IoPlayCircle } from 'react-icons/io5'
import dayjs from 'dayjs'

interface ReferralMessageProps {
  content?: string
  referral: any
  timestamp: any
}

export const ReferralMessage = ({
  referral,
  content,
  timestamp,
}: ReferralMessageProps) => {
  return (
    <div className="max-w-xs max-md:max-w-[16rem]">
      <div className="bg-gray-100 rounded-2xl py-2 px-4">
        <span className=" text-gray-500   mb-1 flex  items-center">
          <MdMessage size={12} className="translate-y-[2px]" />
          <span className="text-[12px] italic"> Message via ad</span>
        </span>
        <div className="rounded-lg border shadow-sm relative">
          {referral?.media_type === 'video' && (
            <a
              href={referral?.video_url}
              target="_blank"
              rel="noreferrer"
              className="relative "
            >
              <img
                className={`w-full max-h-40 mb-2 max-md:max-w-[14rem] rounded-t-lg`}
                src={referral?.thumbnail_url}
              />
              <div className="absolute  w-full h-full flex top-0 justify-center items-center">
                <IoPlayCircle className="text-white " size={40} />
              </div>
            </a>
          )}
          {referral?.media_type === 'image' && (
            <a
              href={referral?.image_url}
              className="relative "
              target="_blank"
              rel="noreferrer"
            >
              <img
                className={`w-full max-h-40 mb-2  max-md:max-w-[14rem] rounded-t-lg`}
                src={referral?.thumbnail_url}
              />
            </a>
          )}
          <a
            href={referral?.source_url}
            className="p-2 flex flex-col gap-1"
            target="_blank"
            rel="noreferrer"
          >
            <h1 className="font-bold">{referral.headline}</h1>
            <p className="text-xs text-gray-500 overflow-x-hidden whitespace-nowrap text-ellipsis">
              {referral?.body?.split(/\s+/)?.slice(0, 20)?.join(' ') + '...'}
            </p>
            <span className="text-xs text-gray-500 mb-1 overflow-x-hidden whitespace-nowrap text-ellipsis">
              {referral?.source_url}
            </span>
          </a>
        </div>
        <span className="block w-full my-2">{content}</span>

        <span className="block mt-1 w-full text-gray-500 text-[11px] text-right">
          {dayjs(parseInt(timestamp)).format('YYYY-MM-DD HH:mm A')}
        </span>
      </div>{' '}
    </div>
  )
}
