import { Input } from 'components/ui/input'
import { useState } from 'react'
import Messenger from './contacts-list/messenger'
import WhatsApp from './contacts-list/whats-app'
import { Actions } from './components/actions'

export default function Sidebar({
  activeTab,
  selectedConversation,
  setSelectedConversation,
  selectedLead,
  setSelectedLead,
  conversationLeads,
  setConversationLeads,
}: any) {
  const [keyword, setKeyword] = useState('')
  return (
    <div className="overflow-y-auto overflow-x-hidden md:min-w-fit flex flex-col gap-3 pl-1 md:pl-0">
      {/* leads header */}
      <div className="flex justify-between items-center gap-3 px-1">
        <Input
          type="text"
          placeholder="Search"
          value={keyword}
          className="bg-white shadow focus-visible:ring-1 w-full"
          onChange={(e) => setKeyword(e.target.value)}
        />
        <Actions />
      </div>
      {/* user1 */}
      {activeTab === 'WhatsApp' ? (
        <WhatsApp
          keyword={keyword}
          selectedConversation={selectedConversation}
          setSelectedConversation={setSelectedConversation}
          selectedLead={selectedLead}
          setSelectedLead={setSelectedLead}
          conversationLeads={conversationLeads}
          setConversationLeads={setConversationLeads}
        />
      ) : activeTab === 'Messenger' || activeTab === 'Instagram' ? (
        <Messenger
          selectedConversation={selectedConversation}
          setSelectedConversation={setSelectedConversation}
        />
      ) : (
        <div className="w-full h-full grid place-content-center p-4">
          No contacts
        </div>
      )}
    </div>
  )
}
