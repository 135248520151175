import { Button } from '../ui/button'
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover'

import { cn } from 'lib/utils'
import dayjs from 'dayjs'
import { TbCalendar } from 'react-icons/tb'
import { Calendar } from 'components/ui/calendar'

export default function BirthDate({ date, setDate, disabled }: any) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          disabled={disabled}
          variant={'outline'}
          className={cn(
            'w-full justify-start text-left font-normal gap-3 items-center',
            !date && 'text-muted-foreground'
          )}
        >
          <TbCalendar size={18} />
          {date ? (
            `${dayjs(date).format('DD/MM/YYYY')} `
          ) : (
            <span>Pick a date</span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="flex gap-3 w-auto p-0" align="start">
        <Calendar
          mode="single"
          selected={date}
          onSelect={setDate}
          initialFocus
          captionLayout="dropdown"
          fromYear={1940}
          toYear={2024}
          classNames={{
            vhidden: 'hidden',
            dropdown: 'flex',
            caption_dropdowns: 'flex w-full justify-between',
            dropdown_icon: 'hidden',
          }}
        />
      </PopoverContent>
    </Popover>
  )
}
