import { useState } from 'react'
import { TbChevronLeft } from 'react-icons/tb'
import { Button } from 'components/ui/button'
import { useNavigate } from 'react-router-dom'
import dayjs from '../../lib/dayjs'
import { ResponseReport } from './components/response-report'
import { ConversationsStatistics } from './components/conversations-statistics'
import { WhatsappAnalytics } from './components/whatsapp-analytics'
import { DateFilters } from './components/date-filters'
import { CampaignStatistics } from './components/campaign-statistics'

export default function WhatsappStats() {
  const navigate = useNavigate()

  /* Filters */
  const [startHour, setStartHour] = useState<string>('1')
  const [startMinute, setStartMinute] = useState<string>('0')
  const [startAmpm, setStartAmpm] = useState<string>('AM')

  const [endHour, setEndHour] = useState<string>('1')
  const [endMinute, setEndMinute] = useState<string>('0')
  const [endAmpm, setEndAmpm] = useState<string>('AM')

  const [startDate, setStartDate] = useState<Date>(
    dayjs().subtract(30, 'day').toDate()
  )
  const [endDate, setEndDate] = useState<Date>(dayjs().toDate())

  return (
    <>
      <div className="p-6">
        <div className="flex justify-between">
          <div>
            <Button variant={'outline'} onClick={() => navigate(-1)}>
              <TbChevronLeft />
            </Button>
          </div>
          <DateFilters
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            startHour={startHour}
            setStartHour={setStartHour}
            endHour={endHour}
            setEndHour={setEndHour}
            startAmpm={startAmpm}
            setStartAmpm={setStartAmpm}
            endAmpm={endAmpm}
            setEndAmpm={setEndAmpm}
            startMinute={startMinute}
            setStartMinute={setStartMinute}
            endMinute={endMinute}
            setEndMinute={setEndMinute}
          />
        </div>
        <ConversationsStatistics
          startDate={startDate}
          endDate={endDate}
          startHour={startHour}
          endHour={endHour}
          startAmpm={startAmpm}
          endAmpm={endAmpm}
          startMinute={startMinute}
          endMinute={endMinute}
        />

        <WhatsappAnalytics
          startDate={startDate}
          endDate={endDate}
          startHour={startHour}
          endHour={endHour}
          startAmpm={startAmpm}
          endAmpm={endAmpm}
          startMinute={startMinute}
          endMinute={endMinute}
        />

        <ResponseReport
          startDate={startDate}
          endDate={endDate}
          startHour={startHour}
          endHour={endHour}
          startAmpm={startAmpm}
          endAmpm={endAmpm}
          startMinute={startMinute}
          endMinute={endMinute}
        />

        <CampaignStatistics
          startDate={startDate}
          endDate={endDate}
          startHour={startHour}
          endHour={endHour}
          startAmpm={startAmpm}
          endAmpm={endAmpm}
          startMinute={startMinute}
          endMinute={endMinute}
        />
      </div>
    </>
  )
}
