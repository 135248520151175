import {
  useAddFormMutation,
  useDisableFormMutation,
  useGetFormTokensMutation,
  useGetFormsQuery,
  useUpdateFormMutation,
} from 'app/features/settings'
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogTrigger,
} from 'components/ui/alert-dialog'
import { CustomInput } from 'components/shared/custom-input'
import { CustomSelect } from 'components/shared/custom-select'
import Error from 'components/shared/error'
import Loader from 'components/shared/loader'
import { Button } from 'components/ui/button'
import useApp from 'hooks/useApp'
import { useEffect, useState } from 'react'
import { Cross1Icon } from '@radix-ui/react-icons'
import { Label } from 'components/ui/label'
import { cn } from 'lib/utils'
import { TbEdit } from 'react-icons/tb'

const RequiredFields = ['First_Name', 'Phone', 'Whatsapp_Number']

const OptionalFields = [
  'Last_Name',
  'Email',
  'Gender',
  'Nationality',
  'Interest',
]

export default function LeadForm() {
  const { sources } = useApp()
  const [error, setError] = useState('')
  const [openAdd, setOpenAdd] = useState(false)
  const [createLoading, setCreateLoading] = useState(false)
  const [createError, setCreateError] = useState('')

  const [selectedFormId, setSelectedFormId] = useState('')
  const [openUpdate, setOpenUpdate] = useState(false)
  const [updateLoading, setUpdateLoading] = useState(false)
  const [updateError, setUpdateError] = useState('')

  const [data, setData] = useState<{
    name: string
    sourceId: string
    fields: string[]
  }>({
    name: '',
    sourceId: '',
    fields: [...RequiredFields],
  })

  const handleChange = (e: { target: { id: any; value: any } }) => {
    setData({ ...data, [e.target.id]: e.target.value })
  }

  const { data: forms, isError, isLoading: dataLoading } = useGetFormsQuery()
  const initialCopyText = forms ? Array(forms.length).fill('Copy Code') : []

  const [copyText, setCopyText] = useState(initialCopyText)

  useEffect(() => {
    if (forms) {
      setCopyText(Array(forms.length).fill('Copy Code'))
    }
  }, [forms])

  useEffect(() => {
    if (copyText.includes('Copied')) {
      setTimeout(() => {
        setCopyText(Array(copyText.length).fill('Copy Code'))
      }, 2000)
    }
  }, [copyText])

  const [addForm] = useAddFormMutation()
  const [updateForm] = useUpdateFormMutation()
  const [disableForm] = useDisableFormMutation()
  const [getFormTokens] = useGetFormTokensMutation()

  const handleSelectField = (field: string) => {
    if (data?.fields?.includes(field)) {
      const newFields = data?.fields?.filter((item: any) => item !== field)
      setData({ ...data, fields: newFields })
    } else {
      setData({ ...data, fields: [...data.fields, field] })
    }
  }

  const isLoading = dataLoading

  const generateFormCode = (token: string) => {
    const formCode = getTemplate(
      token,
      data.sourceId,
      process.env.REACT_APP_FROM_ACCOUNT!,
      process.env.REACT_APP_BACKEND_URL!
    )
    return formCode
  }

  const handleCreateForm = async (e: any) => {
    e.preventDefault()
    setCreateError('')
    setCreateLoading(true)
    const response: any = await getFormTokens({ sourceId: data.sourceId })
    if (response.error) {
      setCreateLoading(false)
      return setCreateError('Failed to create form')
    }
    const form = generateFormCode(response.data.token)

    const addResponse: any = await addForm({ ...data, code: form.toString() })
    if (addResponse.error) {
      setCreateLoading(false)
      return setCreateError('Form with selected name already added')
    }
    setCreateLoading(false)
    setCreateError('')
    setData({ name: '', sourceId: '', fields: [...RequiredFields] })
    setOpenAdd(false)
  }

  const handleUpdateForm = async (e: any) => {
    e.preventDefault()
    setUpdateError('')
    setUpdateLoading(true)

    const updateResponse: any = await updateForm({
      id: selectedFormId,
      data: { ...data },
    })
    if (updateResponse.error) {
      setUpdateLoading(false)
      return setUpdateError('Failed to update form')
    }
    setUpdateLoading(false)
    setUpdateError('')
    setData({ name: '', sourceId: '', fields: [...RequiredFields] })
    setOpenUpdate(false)
  }

  const setCopyTextAtIndex = (index: number) => {
    setCopyText((prevCopyText) => {
      const updatedCopyText = [...prevCopyText] // Create a copy of the array
      updatedCopyText[index] = 'Copied' // Update the element at the specified index
      return updatedCopyText // Return the updated array
    })
  }

  if (isLoading)
    return (
      <div className="w-full h-full grid place-content-center pt-20">
        <Loader />
      </div>
    )
  if (isError)
    return (
      <div className="w-full h-full grid place-content-center pt-20">
        <Error message="Server error, contact administrator please" />
      </div>
    )

  return (
    <>
      <div className="flex flex-col p-6">
        <h1 className="text-lg font-medium">Forms</h1>
        <p className="text-xs text-gray-500">
          Generate forms code that can be embedded in different environment.
        </p>
        {/* Form */}
        <div className="flex items-center gap-3 mt-5 max-md:flex-col">
          <div className="flex-1" />
          <AddForm
            open={openAdd}
            setOpen={setOpenAdd}
            sources={sources}
            handleChange={handleChange}
            handleCreateForm={handleCreateForm}
            handleSelectField={handleSelectField}
            data={data}
            setData={setData}
            isLoading={createLoading}
            error={createError}
            setError={setCreateError}
          />
          <UpdateForm
            open={openUpdate}
            setOpen={setOpenUpdate}
            sources={sources}
            handleChange={handleChange}
            handleUpdateForm={handleUpdateForm}
            handleSelectField={handleSelectField}
            data={data}
            setData={setData}
            isLoading={updateLoading}
            error={updateError}
            setError={setUpdateError}
          />
        </div>
        {error !== '' && (
          <p className="my-3 text-xs font-medium text-rose-600">{error}</p>
        )}

        {/* List */}
        <div className="mt-5 flex flex-col gap-3">
          {forms?.map((form: any, index: number) => (
            <div className="flex flex-col py-2 px-3 rounded-md bg-gray-50 dark:bg-gray-800 w-full">
              <div key={form.id} className="flex items-center gap-2">
                <div className="flex items-center gap-3 w-full">
                  <div>
                    {form.name} - {form?.source?.name}
                  </div>

                  <div className="flex-1" />

                  <Button
                    size="sm"
                    variant="outline"
                    onClick={() => {
                      setCopyTextAtIndex(index)
                      navigator.clipboard.writeText(`<iframe
                        src="${form.code}"
                        frameborder="0"
                        width="100%"
                        height="300%"
                        ></iframe>
                    `)
                    }}
                  >
                    {copyText[index]}
                  </Button>
                  <div className="flex items-center gap-3">
                    <Button
                      size="sm"
                      variant="ghost"
                      onClick={() => {
                        setOpenUpdate(true)
                        setData({
                          name: form.name,
                          sourceId: form.sourceId,
                          fields: form.fields || [],
                        })
                        setSelectedFormId(form.id)
                      }}
                    >
                      <TbEdit size={18} />
                    </Button>
                  </div>
                </div>
              </div>
              <div className="ml-4 mt-2 flex flex-col gap-3">
                <ul className="flex gap-1 items-center justify-start   flex-wrap max-sm:flex-col max-sm:items-start max-sm:justify-start">
                  {form.fields?.map((field: any) => {
                    return (
                      <li className="px-2 py-[2px] rounded-md     border-2 text-xs bg-slate-200 flex items-center justify-center gap-2">
                        {field?.replace(/_/g, ' ')}{' '}
                      </li>
                    )
                  })}
                </ul>
              </div>
              <div className="mt-5 flex text-xs overflow-hidden overflow-x-scroll">
                {`<iframe
                        src="${form.code}"
                        frameborder="0"
                        width="100%"
                        height="300%"
                        ></iframe>
                    `}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

const AddForm = ({
  open,
  setOpen,
  error,
  setError,
  data,
  setData,
  handleSelectField,
  isLoading,
  handleCreateForm,
  handleChange,
  sources,
}: any) => {
  const [step, setStep] = useState(1)

  function validate1() {
    if (data.sourceId === '' || data.name === '')
      return setError('All fields are required')
    setError('')
    setStep(step + 1)
  }

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger asChild>
        <span>
          <Button size="sm">Add Form</Button>
        </span>
      </AlertDialogTrigger>

      <AlertDialogContent className="sm:max-w-[425px] md:max-w-[745px] bg-white dark:bg-gray-900 pr-3">
        <div className="h-full w-full">
          <>
            <div className="max-h-[70vh] overflow-hidden overflow-y-scroll">
              <div className="flex w-full items-center justify-between">
                <h1 className="text-lg font-semibold capitalize">Add Form</h1>

                <Button
                  variant="outline"
                  size="icon"
                  onClick={() => {
                    setError('')
                    setData({
                      name: '',
                      sourceId: '',
                      fields: [...RequiredFields],
                    })
                    setStep(1)
                    setOpen(false)
                  }}
                >
                  <Cross1Icon className="h-3 w-3" />
                </Button>
              </div>

              {step === 1 && (
                <>
                  <div className="w-full space-y-3 mt-5 px-1">
                    <div className="w-full flex items-center gap-3">
                      <CustomInput
                        name="name"
                        label="Name"
                        value={data.name}
                        setValue={handleChange}
                        type="text"
                        required
                      />
                      <CustomSelect
                        label="Source"
                        list={sources}
                        value={data.sourceId}
                        setValue={(value: any) =>
                          setData({ ...data, sourceId: value })
                        }
                        required
                      />
                    </div>
                  </div>
                  <div className="mt-5 flex items-center">
                    {error !== '' && (
                      <div className="text-rose-600 text-sm">{error}</div>
                    )}
                    <div className="flex-1" />
                    <Button size="sm" onClick={validate1}>
                      Next
                    </Button>
                  </div>
                </>
              )}

              {step === 2 && (
                <div className="space-y-3 my-5">
                  {error !== '' && (
                    <div className="text-rose-600 text-sm">{error}</div>
                  )}
                  <div className="w-full space-y-2">
                    <Label>Fields</Label>
                    <div className="flex gap-3 flex-wrap">
                      {RequiredFields?.map((field: any) => {
                        return (
                          <div
                            key={field}
                            className={cn(
                              RequiredFields?.includes(field)
                                ? 'border-indigo-600'
                                : 'border',
                              'px-3 py-1 rounded-md  border-2 whitespace-nowrap select-none flex items-center justify-center gap-2 opacity-50 hover:cursor-not-allowed'
                            )}
                          >
                            {field?.replace(/_/g, ' ')}
                          </div>
                        )
                      })}
                      {OptionalFields?.map((field: any) => {
                        return (
                          <div
                            key={field}
                            onClick={() => {
                              handleSelectField(field)
                            }}
                            className={cn(
                              data?.fields?.includes(field)
                                ? 'border-indigo-600'
                                : 'border',
                              'px-3 py-1 rounded-md cursor-pointer border-2 whitespace-nowrap select-none flex items-center justify-center gap-2'
                            )}
                          >
                            {field?.replace(/_/g, ' ')}
                          </div>
                        )
                      })}
                    </div>
                  </div>

                  <div className="flex items-center gap-3 justify-between pt-5">
                    <Button
                      size="sm"
                      variant="outline"
                      onClick={() => setStep(1)}
                    >
                      Back
                    </Button>
                    <Button
                      size="sm"
                      onClick={handleCreateForm}
                      disabled={isLoading}
                    >
                      Add Form
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  )
}

const UpdateForm = ({
  open,
  setOpen,
  error,
  setError,
  data,
  setData,
  handleChange,
  sources,
  handleUpdateForm,
  handleSelectField,
  isLoading,
}: any) => {
  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogContent className="sm:max-w-[425px] md:max-w-[745px] bg-white dark:bg-gray-900 pr-3">
        <div className="h-full w-full">
          <>
            <div className="max-h-[70vh] overflow-hidden overflow-y-scroll">
              <div className="flex w-full items-center justify-between">
                <h1 className="text-lg font-semibold capitalize">
                  Update Form
                </h1>

                <Button
                  variant="outline"
                  size="icon"
                  onClick={() => {
                    setError('')
                    setData({
                      name: '',
                      sourceId: '',
                      fields: [...RequiredFields],
                    })
                    setOpen(false)
                  }}
                >
                  <Cross1Icon className="h-3 w-3" />
                </Button>
              </div>

              <div className="space-y-3 my-5">
                <div className="w-full space-y-2">
                  {error !== '' && (
                    <div className="text-rose-600 text-sm">{error}</div>
                  )}

                  <div className="w-full flex items-center gap-3">
                    <CustomSelect
                      label="Source"
                      list={sources}
                      value={data.sourceId}
                      setValue={(value: any) =>
                        setData({ ...data, sourceId: value })
                      }
                      required
                    />
                  </div>

                  <div className="w-full space-y-2">
                    <Label>Fields</Label>
                    <div className="flex gap-3 flex-wrap">
                      {RequiredFields?.map((field: any) => {
                        return (
                          <div
                            key={field}
                            className={cn(
                              RequiredFields?.includes(field)
                                ? 'border-indigo-600'
                                : 'border',
                              'px-3 py-1 rounded-md  border-2 whitespace-nowrap select-none flex items-center justify-center gap-2 opacity-50 hover:cursor-not-allowed'
                            )}
                          >
                            {field?.replace(/_/g, ' ')}
                          </div>
                        )
                      })}
                      {OptionalFields?.map((field: any) => {
                        return (
                          <div
                            key={field}
                            onClick={() => {
                              handleSelectField(field)
                            }}
                            className={cn(
                              data?.fields?.includes(field)
                                ? 'border-indigo-600'
                                : 'border',
                              'px-3 py-1 rounded-md cursor-pointer border-2 whitespace-nowrap select-none flex items-center justify-center gap-2'
                            )}
                          >
                            {field?.replace(/_/g, ' ')}
                          </div>
                        )
                      })}
                    </div>
                  </div>

                  <div className="flex items-center gap-3 justify-between pt-5">
                    <div className="flex-1"></div>
                    <Button
                      size="sm"
                      onClick={(e) => {
                        if (data.sourceId === '' || data.name === '')
                          return setError('All fields are required')
                        setError('')
                        handleUpdateForm(e)
                      }}
                      disabled={isLoading}
                    >
                      Update Form
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  )
}

const getTemplate = (
  token: string,
  sourceId: string,
  userId: string,
  backendUrl: string
) => {
  return `
    <!DOCTYPE html>
<html lang="en">
	<head>
		<meta charset="UTF-8" />
		<meta name="viewport" content="width=device-width, initial-scale=1.0" />
		<script src="https://cdn.tailwindcss.com"></script>
		<script src="https://cdn.tailwindcss.com?plugins=forms,typography,aspect-ratio,line-clamp"></script>
		<script src="https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/20.3.0/js/intlTelInput.min.js"></script>
		<link
			rel="stylesheet"
			href="https://cdn.jsdelivr.net/npm/intl-tel-input@20.3.0/build/css/intlTelInput.css"
		/>
		<script src="https://cdn.jsdelivr.net/npm/intl-tel-input@20.3.0/build/js/intlTelInput.min.js"></script>
		<style>
			.iti {
				display: block !important;
			}
		</style>
	</head>
	<body>
		<div class="p-1">
			<div id="message" class="hidden">Thank you message</div>
			<form id="form" class="space-y-3" action="" method="POST">
				<input
					type="hidden"
					name="token"
					id="token"
					value=${token}
				/>
				<input
					type="hidden"
					name="sourceId"
					id="sourceId"
					value=${sourceId}
				/>
				<input type="hidden" name="userId" id="userId" value=${userId} />
				<div class="w-full">
					<label
						for="firstName"
						class="block text-sm font-medium leading-6 text-gray-900"
					>
						First Name <span class="text-rose-600">*</span>
					</label>
					<div class="mt-2">
						<input
							id="firstName"
							name="firstName"
							type="firstName"
							required
							class="block w-full rounded-md border-0 py-1.5 text-gray-900 px-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
						/>
					</div>
				</div>
				<div class="w-full">
					<label for="lastName" class="block text-sm font-medium leading-6 text-gray-900">
						Last Name
					</label>
					<div class="mt-2">
						<input
							id="lastName"
							name="lastName"
							type="lastName"
							class="block w-full rounded-md border-0 py-1.5 text-gray-900 px-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
						/>
					</div>
				</div>
				<div class="w-full">
					<label for="phone" class="block text-sm font-medium leading-6 text-gray-900">
						Phone <span class="text-rose-600">*</span>
					</label>
					<div class="mt-2">
						<input
							id="phone"
							name="phone"
							type="tel"
							required
							class="block w-full rounded-md border-0 py-1.5 text-gray-900 px-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
						/>
					</div>
				</div>
				<div class="w-full">
					<label for="email" class="block text-sm font-medium leading-6 text-gray-900">
						Email
					</label>
					<div class="mt-2">
						<input
							id="email"
							name="email"
							type="email"
							class="block w-full rounded-md border-0 py-1.5 text-gray-900 px-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
						/>
					</div>
				</div>
				<div class="w-full">
					<label for="Interest" class="block text-sm font-medium leading-6 text-gray-900">
						Interest
					</label>
					<div class="mt-2">
						<textarea
							id="Interest"
							name="Interest"
							rows="3"
							class="block w-full rounded-md border-0 py-1.5 text-gray-900 px-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
						></textarea>
					</div>
				</div>
				<div class="w-full flex justify-end">
					<button
						type="submit"
						class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
					>
						Send
					</button>
				</div>
			</form>
		</div>
		<script>
			const phoneNumber = window.intlTelInput(document.querySelector("#phone"), {
				initialCountry: "auto",
				geoIpLookup: callback => {
					fetch("https://ipapi.co/json")
						.then(res => res.json())
						.then(data => callback(data.country_code))
						.catch(() => callback("us"));
				},
				strictMode: true,
				utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@20.3.0/build/js/utils.js",
			});
			// const full_number = phoneNumber.getNumber(intlTelInputUtils.numberFormat.E164);

			let form = document.getElementById("form");
			let message = document.getElementById("message");
			form.addEventListener("submit", async e => {
				e.preventDefault();
				let firstName = document.getElementById("firstName").value;
				let lastName = document.getElementById("lastName").value;
				let phone = phoneNumber.getNumber();
				let email = document.getElementById("email").value;
				let Interest = document.getElementById("Interest").value;
				let token = document.getElementById("token").value;
				let sourceId = document.getElementById("sourceId").value;
				let userId = document.getElementById("userId").value;

				const response = await fetch("${backendUrl}", {
					method: "POST",
					body: JSON.stringify({
						firstName,
						lastName,
						phone: phone.slice(1),
						email,
						Interest,
						token,
						sourceId,
						userId,
					}),
					headers: {
						"Content-type": "application/json; charset=UTF-8",
					},
				});
				form.reset();
				form.classList.add("hidden");
				message.classList.remove("hidden");
			});
		</script>
	</body>
</html>
    `
}
