import { Button } from 'components/ui/button'
import { CaretSortIcon, CheckIcon } from '@radix-ui/react-icons'

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from 'components/ui/command'
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover'
import { ScrollArea } from 'components/ui/scroll-area'
import { useState } from 'react'
import { cn, convertToTitleCase, formatDate } from 'lib/utils'

export const SelectConversationLead = ({
  conversationLeads,
  selectedLead,
  setSelectedLead,
}: any) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <div>Select Lead</div>
      <Popover open={open} onOpenChange={setOpen} modal={true}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="justify-between border-gray-300 shadow-sm dark:border-gray-800 bg-white dark:bg-gray-900"
          >
            {selectedLead?.id
              ? formatDate(selectedLead?.createdAt, false, true)
              : 'Select Lead'}
            <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-full p-0">
          <Command className="w-[300px] overflow-y-auto ">
            <CommandInput placeholder={`Search Leads`} className="h-9" />
            <CommandEmpty>List is empty</CommandEmpty>
            <CommandGroup>
              <ScrollArea className="h-52 w-full">
                {conversationLeads?.map((item: any) => (
                  <>
                    <CommandItem
                      id={item.id}
                      key={`${item.id}`}
                      value={item}
                      onSelect={(currentValue) => {
                        setSelectedLead(item)
                        setOpen(false)
                      }}
                      className="capitalize hover:bg-white"
                    >
                      <div className="flex flex-col w-full ">
                        <span className="flex justify-between items-center w-full">
                          <span>{item.firstName}</span>
                          <span className="px-1 text-xs rounded-md bg-gray-200 ">
                            {item.isSentToInsurance &&
                            !item.isSendToInsuranceEnded
                              ? 'Insurance'
                              : convertToTitleCase(item.status)}
                          </span>
                        </span>
                        <span className="flex justify-between items-center w-full">
                          {formatDate(item.createdAt, false, true)}
                          <CheckIcon
                            className={cn(
                              'ml-auto h-4 w-4',
                              selectedLead?.id === item?.id
                                ? 'opacity-100'
                                : 'opacity-0'
                            )}
                          />
                        </span>
                      </div>
                    </CommandItem>
                  </>
                ))}
              </ScrollArea>
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>
    </>
  )
}
