import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarSeparator,
  MenubarShortcut,
  MenubarTrigger,
} from 'components/ui/menubar'
import { IoFileTray, IoShieldCheckmarkSharp } from 'react-icons/io5'
import {
  TbTextPlus,
  TbClipboardList,
  TbCircleDashedX,
  TbDotsVertical,
  TbBrandWhatsapp,
  TbCalendarCancel,
  TbShoppingBag,
} from 'react-icons/tb'
import { MdEdit } from 'react-icons/md'
import {
  shouldFollowUp,
  shouldBook,
  shouldClaimInsurance,
  shouldNotQualified,
  hasFollowUpAccess,
  hasBookAccess,
  hasNotQualifiedAccess,
  hasInsuranceAccess,
  hasWhatsappAccess,
} from 'lib/allowed'
import { HiOutlineDuplicate } from 'react-icons/hi'
import { Button } from 'components/ui/button'
import { useState } from 'react'
import { useToast } from 'components/ui/use-toast'
import axios from '../../../lib/axios'
import { VscFilePdf } from 'react-icons/vsc'
import dayjs from 'dayjs'
import { AlertDialog, AlertDialogContent } from 'components/ui/alert-dialog'
import SellOffer from 'pages/leads/components/sell-offer'

export const Actions = ({
  lead,
  isDetailsPage,
  isLoading,
  id,
  navigate,
  user,
  settings,
  duplicateLead,
  isDuplicateLoading,
  setOpenStatus,
  setOpenBook,
  numberOfFollowUps,
  setOpenFollowUp,
  timeGap,
  setOpenUpdateBook,
  setOpenCancelBook,
  setOpenClaimInsurance,
  setOpenNotQualified,
  setOpenSendToInsurance,
  setOpenEndInsurance,
  setOpenAttachFile,
  startMessagingViaWhatsApp,
}: any) => {
  const { toast } = useToast()

  const [loadingPDF, setLoadingPDF] = useState(false)
  const [saleDialog, setSaleDialog] = useState<{
    leadId?: string
    visible: boolean
  }>({
    visible: false,
    leadId: undefined,
  })

  /**
   * Generates a PDF report based on the selected filters and downloads it
   * @returns {Promise<void>}
   */
  const requestLeadJourneyPDF = async (selectedLead: any) => {
    if (loadingPDF) return

    setLoadingPDF(true)

    const { dismiss } = toast({
      title: 'Generating PDF',
      description:
        'Please wait, PDF is being generated. This may take a few seconds.',
      duration: 30 * 1000,
    })

    try {
      const res = await axios.get('/reports/lead-journey', {
        params: {
          lead: selectedLead.id,
        },
        responseType: 'blob',
      })

      downloadPDF(res.data)

      toast({
        title: 'Success',
        description: 'PDF generated successfully',
      })

      dismiss()
    } catch (error) {
      toast({
        title: 'Error occurred',
        description: 'An error occurred while generating dashboard report',
      })
    }

    setLoadingPDF(false)
  }

  /**
   * Downloads the given PDF data as a file
   * @param {any} data The PDF data to download
   */
  function downloadPDF(data: string) {
    const pdfBlob = new Blob([data], { type: 'application/pdf' })

    const link = document.createElement('a')
    const url = window.URL.createObjectURL(pdfBlob)
    link.href = url
    link.download = `Doctorna - Lead Journey Report ${dayjs().format('YYYYMMDD HH:mm')}.pdf`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <>
      <Menubar className="  border-none h-10 w-8  z-10   ">
        <MenubarMenu>
          <MenubarTrigger className="disabled:text-gray-300 hover:cursor-pointer p-0 disabled={!isDetailsPage} ">
            <Button
              variant="ghost"
              className="flex h-8 w-8   p-0 data-[state=open]:bg-muted relative"
            >
              <TbDotsVertical size={18} />
            </Button>
          </MenubarTrigger>
          {lead && isDetailsPage && !isLoading && (
            <MenubarContent className="bg-white border   w-32">
              <MenubarItem
                className="hover:cursor-pointer hover:bg-gray-100 font-medium"
                onClick={() => navigate(`/update-lead/${id}`)}
              >
                Edit
                <MenubarShortcut>
                  <MdEdit size={15} className="text-gray-700" />
                </MenubarShortcut>
              </MenubarItem>
              {(user.role == 'ADMIN' || user.role == 'SUPERADMIN') && (
                <MenubarItem
                  className="hover:cursor-pointer hover:bg-gray-100 font-medium"
                  onClick={() => requestLeadJourneyPDF(lead)}
                >
                  Lead journey
                  <MenubarShortcut>
                    <VscFilePdf size={15} className="text-gray-700" />
                  </MenubarShortcut>
                </MenubarItem>
              )}
              <MenubarItem
                className="hover:cursor-pointer hover:bg-gray-100 font-medium"
                onClick={duplicateLead}
                disabled={isDuplicateLoading}
              >
                Duplicate
                <MenubarShortcut>
                  <HiOutlineDuplicate size={15} className="text-gray-700" />
                </MenubarShortcut>
              </MenubarItem>
              {hasBookAccess(user) && (
                <>
                  {lead?.status != 'BOOKED' ? (
                    <>
                      <MenubarItem
                        className="hover:cursor-pointer hover:bg-gray-100 font-medium"
                        onClick={() => setOpenBook(true)}
                        disabled={!shouldBook(lead)}
                      >
                        Book
                        <MenubarShortcut>
                          <TbTextPlus className="text-gray-500" size={15} />
                        </MenubarShortcut>
                      </MenubarItem>
                    </>
                  ) : (
                    <>
                      <MenubarItem
                        className="hover:cursor-pointer hover:bg-gray-100 font-medium"
                        onClick={() => setOpenStatus(true)}
                      >
                        Update Status
                        <MenubarShortcut>
                          <TbTextPlus className="text-gray-500" size={15} />
                        </MenubarShortcut>
                      </MenubarItem>
                      <MenubarItem
                        className="hover:cursor-pointer hover:bg-gray-100 font-medium"
                        onClick={() => setOpenUpdateBook(true)}
                      >
                        Update Book
                        <MenubarShortcut>
                          <TbTextPlus className="text-gray-500" size={15} />
                        </MenubarShortcut>
                      </MenubarItem>
                      <MenubarItem
                        className="hover:cursor-pointer hover:bg-gray-100 font-medium"
                        onClick={() => setOpenCancelBook(true)}
                      >
                        Cancel Book
                        <MenubarShortcut>
                          <TbCalendarCancel
                            className="text-gray-500"
                            size={15}
                          />
                        </MenubarShortcut>
                      </MenubarItem>
                    </>
                  )}
                </>
              )}
              {hasFollowUpAccess(user) && (
                <MenubarItem
                  className="hover:cursor-pointer hover:bg-gray-100 font-medium"
                  onClick={() => setOpenFollowUp(true)}
                  disabled={
                    !shouldFollowUp(
                      lead,
                      lead?.followUps[lead.followUps.length - 1],
                      numberOfFollowUps,
                      timeGap
                    )
                  }
                >
                  Follow Up
                  <MenubarShortcut>
                    {' '}
                    <TbClipboardList className="text-gray-500" size={15} />
                  </MenubarShortcut>
                </MenubarItem>
              )}{' '}
              {hasNotQualifiedAccess(user) && (
                <MenubarItem
                  className="hover:cursor-pointer hover:bg-gray-100 font-medium"
                  onClick={() => setOpenNotQualified(true)}
                  disabled={!shouldNotQualified(lead)}
                >
                  Not qualified
                  <MenubarShortcut>
                    <TbCircleDashedX className="text-gray-500" size={15} />
                  </MenubarShortcut>
                </MenubarItem>
              )}
              {hasInsuranceAccess(user) && (
                <MenubarItem
                  className="hover:cursor-pointer hover:bg-gray-100 font-medium"
                  onClick={() => setOpenClaimInsurance(true)}
                  disabled={!shouldClaimInsurance(lead)}
                >
                  Insurance Approval
                  <MenubarShortcut>
                    <IoShieldCheckmarkSharp
                      className="text-gray-500"
                      size={15}
                    />
                  </MenubarShortcut>
                </MenubarItem>
              )}
              {/**TODO: refactor authorization and disabled */}
              {hasInsuranceAccess(user) && (
                <MenubarItem
                  className="hover:cursor-pointer hover:bg-gray-100 font-medium"
                  onClick={() => setOpenSendToInsurance(true)}
                  disabled={
                    lead?.insuranceType == 'Cash' ||
                    (lead?.isSentToInsurance && !lead?.isSendToInsuranceEnded)
                  }
                >
                  Send to insurance
                  <MenubarShortcut>
                    <IoShieldCheckmarkSharp
                      className="text-gray-500"
                      size={15}
                    />
                  </MenubarShortcut>
                </MenubarItem>
              )}
              {/**TODO: refactor authorization and disabled */}
              {hasInsuranceAccess(user) && (
                <MenubarItem
                  className="hover:cursor-pointer hover:bg-gray-100 font-medium"
                  onClick={() => setOpenEndInsurance(true)}
                  disabled={
                    lead?.insuranceType == 'Cash' ||
                    !lead?.isSentToInsurance ||
                    (lead?.isSentToInsurance && lead?.isSendToInsuranceEnded)
                  }
                >
                  End insurance
                  <MenubarShortcut>
                    <IoShieldCheckmarkSharp
                      className="text-gray-500"
                      size={15}
                    />
                  </MenubarShortcut>
                </MenubarItem>
              )}
              <MenubarItem
                className="hover:cursor-pointer hover:bg-gray-100 font-medium"
                onClick={() => setOpenAttachFile(true)}
              >
                Attach file
                <MenubarShortcut>
                  <IoFileTray className="text-gray-500" size={15} />
                </MenubarShortcut>
              </MenubarItem>
              {hasWhatsappAccess(user) && (
                <MenubarItem
                  className="hover:cursor-pointer hover:bg-gray-100 font-medium"
                  onClick={() => startMessagingViaWhatsApp()}
                >
                  Start messaging via WhatsApp
                  <MenubarShortcut>
                    <TbBrandWhatsapp className="text-gray-500" size={15} />
                  </MenubarShortcut>
                </MenubarItem>
              )}
              {/* <MenubarItem
                className="hover:cursor-pointer hover:bg-gray-100 font-medium"
                onClick={() =>
                  setSaleDialog({ visible: true, leadId: lead.id })
                }
              >
                <span>Sell Offer/Package</span>
                <MenubarShortcut>
                  <TbShoppingBag size={15} className=" text-gray-500" />
                </MenubarShortcut>
              </MenubarItem> */}
            </MenubarContent>
          )}
        </MenubarMenu>
      </Menubar>

      <AlertDialog
        open={saleDialog.visible}
        onOpenChange={() => setSaleDialog({ visible: false })}
      >
        <AlertDialogContent className="sm:max-w-[800px] md:max-w-[800px] bg-white dark:bg-gray-900">
          <SellOffer
            leadId={saleDialog.leadId}
            closeDialog={() => setSaleDialog({ visible: false })}
          />
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}
