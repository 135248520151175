import { Label } from '@radix-ui/react-label'
import { Button } from 'components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu'
import { Input } from 'components/ui/input'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import dayjs from 'dayjs'
import useApp from 'hooks/useApp'
import { getStartDateAndEndDate } from 'lib/utils'
import { FormEvent, useEffect, useState } from 'react'
import { TbCloudDownload, TbDotsVertical } from 'react-icons/tb'
import DateAndTime from './date-and-time'
import MySelect from './select/MySelect'
import axios from '../../lib/axios'
import { useToast } from 'components/ui/use-toast'

export default function FiltersForm({ withKeyword, exportRevenueReport }: any) {
  const { toast } = useToast()

  const [loadingDashboardReport, setLoadingDashboardReport] = useState(false)
  const [startDate, setStartDate] = useState<Date | undefined>(undefined)
  const [startHour, setStartHour] = useState<string>('1')
  const [startMinute, setStartMinute] = useState<string>('0')
  const [startAmpm, setStartAmpm] = useState<string>('AM')

  const [endDate, setEndDate] = useState<Date | undefined>(undefined)
  const [endHour, setEndHour] = useState<string>('1')
  const [endMinute, setEndMinute] = useState<string>('0')
  const [endAmpm, setEndAmpm] = useState<string>('AM')

  const [selectedDate, setSelectedDate] = useState<string>('All')

  const [isFreeBranches, setIsFreeBranches] = useState(false)
  const [selectedBranches, setSelectedBranches] = useState<any>([])
  const [isFreeDepartments, setIsFreeDepartments] = useState(false)
  const [selectedDepartments, setSelectedDepartments] = useState<any>([])
  const [isFreeSpecialists, setIsFreeSpecialists] = useState(false)
  const [selectedSpecialties, setSelectedSpecialties] = useState<any>([])

  const { setFilters, filters, branches, departments, specialists } = useApp()

  const [keyword, setKeyword] = useState('')

  // TODO: to be deleted
  // const [departmentsData, setDepartmentsData] = useState<any>(departments)
  // const [specialtiesData, setSpecialtiesData] = useState<any>(specialists)

  useEffect(() => {
    // TODO: to be deleted

    // setDepartmentsData([])
    // setSpecialtiesData([])

    // selectedBranches.forEach((branch: { departments: any[] }) => {
    //   setDepartmentsData((prevDepartmentsData: any) => [
    //     ...prevDepartmentsData,
    //     ...branch.departments,
    //   ])

    //   branch.departments?.forEach((department: { specialists: any }) => {
    //     setSpecialtiesData((prevSpecialistsData: any) => [
    //       ...prevSpecialistsData,
    //       ...department.specialists,
    //     ])
    //   })
    // })
    // if (selectedBranches.length === 0) {
    //   setDepartmentsData(departments)
    //   setSpecialtiesData(specialists)
    //   setSelectedDepartments([])
    //   setSelectedSpecialties([])
    // }

    const filteredDepartments = selectedDepartments.filter((department: any) =>
      selectedBranches.some((branch: any) =>
        branch.departments.some(
          (branchDepartment: any) => department.id === branchDepartment.id
        )
      )
    )

    const filteredSpecialists = selectedSpecialties.filter(
      (specialist: { id: any }) =>
        selectedBranches.some((branch: { departments: any[] }) =>
          branch.departments.some(
            (departmentSpecialist: { specialists: any[] }) =>
              departmentSpecialist.specialists.some(
                (item: any) => specialist.id === item.id
              )
          )
        )
    )

    setSelectedDepartments(filteredDepartments)
    setSelectedSpecialties(filteredSpecialists)
  }, [selectedBranches])

  useEffect(() => {
    // TODO: to be deleted
    // setSpecialtiesData([])
    // selectedDepartments.forEach((department: { specialists: any[] }) => {
    //   setSpecialtiesData((prevSpecialistsData: any) => [
    //     ...prevSpecialistsData,
    //     ...department.specialists,
    //   ])
    // })
    // if (selectedDepartments.length === 0) {
    //   setSpecialtiesData(specialists)
    //   setSelectedSpecialties([])
    // }

    const filteredSpecialists = selectedSpecialties.filter((specialist: any) =>
      selectedDepartments.some((department: any) =>
        department.specialists.some(
          (departmentSpecialist: any) =>
            specialist.id === departmentSpecialist.id
        )
      )
    )
    setSelectedSpecialties(filteredSpecialists)
  }, [selectedDepartments])

  useEffect(() => {
    const { startDate: startDateValue, endDate: endDateValue } =
      getStartDateAndEndDate(selectedDate) || { startDate, endDate }
    setFilters({
      startDate: startDate
        ? dayjs(startDate)
            .hour(parseInt(startHour) + (startAmpm === 'PM' ? 12 : 0))
            .minute(parseInt(startMinute))
            .toDate()
        : startDateValue,
      endDate: endDate
        ? dayjs(endDate)
            .hour(parseInt(endHour) + (endAmpm === 'PM' ? 12 : 0))
            .minute(parseInt(endMinute))
            .toDate()
        : endDateValue,
      branchIDs: selectedBranches.map((branch: { id: any }) => branch.id),
      departmentIDs: selectedDepartments.map(
        (department: { id: any }) => department.id
      ),
      specialistIDs: selectedSpecialties.map(
        (specialist: { id: any }) => specialist.id
      ),
    })
  }, [])

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    const { startDate: startDateValue, endDate: endDateValue } =
      getStartDateAndEndDate(selectedDate) || { startDate, endDate }
    setFilters({
      keyword: keyword,
      ...(selectedDate != 'All' && {
        startDate: startDate
          ? dayjs(startDate)
              .hour(parseInt(startHour) + (startAmpm === 'PM' ? 12 : 0))
              .minute(parseInt(startMinute))
              .toDate()
          : startDateValue,
        endDate: endDate
          ? dayjs(endDate)
              .hour(parseInt(endHour) + (endAmpm === 'PM' ? 12 : 0))
              .minute(parseInt(endMinute))
              .toDate()
          : endDateValue,
      }),
      isFreeBranches,
      branchIDs: selectedBranches.map((branch: { id: any }) => branch.id),
      isFreeDepartments,
      departmentIDs: selectedDepartments.map(
        (department: { id: any }) => department.id
      ),
      isFreeSpecialists,
      specialistIDs: selectedSpecialties.map(
        (specialist: { id: any }) => specialist.id
      ),
    })
  }

  const handleClearFilters = () => {
    setKeyword('')
    setIsFreeBranches(false)
    setSelectedBranches([])
    setIsFreeDepartments(false)
    setSelectedDepartments([])
    setIsFreeSpecialists(false)
    setSelectedSpecialties([])
    setStartDate(undefined)
    setStartHour('1')
    setStartMinute('0')
    setStartAmpm('AM')
    setEndDate(undefined)
    setEndHour('1')
    setEndMinute('0')
    setEndAmpm('AM')
    setFilters(null)
    setSelectedDate('All')
  }

  /**
   * Generates a PDF report based on the selected filters and downloads it
   * @returns {Promise<void>}
   */
  const requestDashboardReportPDF = async () => {
    if (loadingDashboardReport) return

    setLoadingDashboardReport(true)

    const { dismiss } = toast({
      title: 'Generating PDF',
      description:
        'Please wait, PDF is being generated. This may take a few seconds.',
      duration: 30 * 1000,
    })

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    try {
      const res = await axios.get('/reports/dashboard', {
        params: {
          ...(filters.startDate &&
            filters.endDate && {
              from: dayjs(filters.startDate).toISOString(),
              to: dayjs(filters.endDate).toISOString(),
              ...(timezone && { timezone }),
            }),
          isFreeBranches,
          branches: selectedBranches?.map((el: any) => el.id),
          isFreeDepartments,
          departments: selectedDepartments?.map((el: any) => el.id),
          isFreeSpecialists,
          specialists: selectedSpecialties?.map((el: any) => el.id),
        },
        responseType: 'blob',
      })

      downloadPDF(res.data)

      toast({
        title: 'Success',
        description: 'PDF generated successfully',
      })

      dismiss()
    } catch (error) {
      toast({
        title: 'Error occurred',
        description: 'An error occurred while generating dashboard report',
      })
    }

    setLoadingDashboardReport(false)
  }

  /**
   * Downloads the given PDF data as a file
   * @param {any} data The PDF data to download
   */
  function downloadPDF(data: string) {
    const pdfBlob = new Blob([data], { type: 'application/pdf' })

    const link = document.createElement('a')
    const url = window.URL.createObjectURL(pdfBlob)
    link.href = url
    link.download = `Doctorna - Dashboard Report ${dayjs().format('YYYYMMDD HH:mm')}.pdf`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <>
      <div className="w-full mb-3 pb-3 border-b border-dashed border-gray-300">
        <form
          onSubmit={handleSubmit}
          className="flex flex-wrap flex-row items-end gap-3"
        >
          {withKeyword && (
            <div className="space-y-2 flex flex-col">
              <Label className="text-xs font-semibold opacity-0">Keyword</Label>
              <Input
                type="text"
                placeholder="Search"
                value={keyword}
                className="bg-white"
                onChange={(e) => setKeyword(e.target.value)}
              />
            </div>
          )}

          <div className="space-y-2 flex flex-col">
            <MySelect
              selected={selectedBranches}
              setSelected={setSelectedBranches}
              data={branches}
              selectMeta="branch"
              hasFree={true}
              isFree={isFreeBranches}
              setIsFree={setIsFreeBranches}
            />
          </div>
          <div className="space-y-2 flex flex-col">
            <MySelect
              selected={selectedDepartments}
              setSelected={setSelectedDepartments}
              // data={departmentsData}
              data={departments.filter((department: any) =>
                selectedBranches
                  .map((branch: any) => branch.id)
                  .includes(department.branchId)
              )}
              selectMeta="department"
              hasFree={true}
              isFree={isFreeDepartments}
              setIsFree={setIsFreeDepartments}
            />
          </div>
          <div className="space-y-2 flex flex-col">
            <MySelect
              selected={selectedSpecialties}
              setSelected={setSelectedSpecialties}
              //data={specialtiesData}
              data={specialists.filter((specialist: any) => {
                return selectedDepartments.length
                  ? selectedDepartments
                      .map((department: any) => department.id)
                      .includes(specialist.departmentId) && !specialist.disabled
                  : selectedBranches
                      .map((branch: any) => branch.id)
                      .includes(specialist.branchId) && !specialist.disabled
              })}
              selectMeta="specialist"
              hasFree={true}
              isFree={isFreeSpecialists}
              setIsFree={setIsFreeSpecialists}
            />
          </div>

          <div className="space-y-2 flex flex-col">
            <Select
              value={selectedDate}
              onValueChange={(value: any) => setSelectedDate(value)}
            >
              <SelectTrigger className="w-40 bg-white">
                <SelectValue>{selectedDate}</SelectValue>
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectItem value="Last 30 days">Last 30 days</SelectItem>
                  <SelectItem value="Last month">Last month</SelectItem>
                  <SelectItem value="This week">This week</SelectItem>
                  <SelectItem value="Last week">Last week</SelectItem>
                  <SelectItem value="Today">Today</SelectItem>
                  <SelectItem value="Yesterday">Yesterday</SelectItem>
                  <SelectItem value="Custom date">Custom Date</SelectItem>
                  <SelectItem value="All">All</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>

          {selectedDate === 'Custom date' ? (
            <>
              <div className="space-y-2 flex flex-col">
                <Label className="text-xs text-gray-500">Start Date:</Label>
                <DateAndTime
                  date={startDate}
                  setDate={setStartDate}
                  hour={startHour}
                  setHour={setStartHour}
                  minute={startMinute}
                  setMinute={setStartMinute}
                  ampm={startAmpm}
                  setAmpm={setStartAmpm}
                />
              </div>
              <div className="space-y-2 flex flex-col">
                <Label className="text-xs text-gray-500">End Date:</Label>
                <DateAndTime
                  date={endDate}
                  setDate={setEndDate}
                  hour={endHour}
                  setHour={setEndHour}
                  minute={endMinute}
                  setMinute={setEndMinute}
                  ampm={endAmpm}
                  setAmpm={setEndAmpm}
                  beforeSelectedDate={startDate}
                />
              </div>
            </>
          ) : null}

          <div className="space-x-2 h-full items-center pb-0.5">
            <Button size="sm">Apply Filters</Button>
          </div>

          <div className="space-x-2 h-full items-center pb-0.5">
            <Button
              size="sm"
              variant="outline"
              className="bg-white"
              onClick={handleClearFilters}
            >
              Clear Filters
            </Button>
          </div>

          <div className="space-x-2 h-full items-center pb-0.5">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant="ghost"
                  className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
                >
                  <TbDotsVertical size={18} />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="w-[180px]">
                <DropdownMenuItem
                  className="hover:cursor-pointer text-left"
                  onClick={exportRevenueReport}
                >
                  <div className="flex gap-3 items-center justify-start w-full">
                    <TbCloudDownload size={15} className=" text-gray-500" />
                    <span>Income Report</span>
                  </div>
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => requestDashboardReportPDF()}>
                  <div className="flex gap-3 items-center justify-start w-full">
                    <TbCloudDownload size={15} className=" text-gray-500" />
                    Generate Report
                  </div>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </form>
      </div>
    </>
  )
}
