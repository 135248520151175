import { useAddFormLeadMutation } from 'app/features/lead'
import { CustomInput } from 'components/shared/custom-input'
import { CustomTextArea } from 'components/shared/custom-text-area'
import { Button } from 'components/ui/button'
import { useState } from 'react'
import { isEmail, isPhone } from 'lib/validation'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import { Label } from 'components/ui/label'
import PhoneInput from 'components/shared/phone-input'
import CustomNationalityInput from 'components/shared/nationality-input'
import { useSearchParams } from 'react-router-dom'
import { Checkbox } from 'components/ui/checkbox'
import { useGetFormQuery } from 'app/features/settings'
import Error from 'components/shared/error'
import Loader from 'components/shared/loader'

const initialLead = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  interest: '',
  nationality: '',
  gender: null,
  whatsappNumber: '',
}

export default function Form() {
  const [queryParameters] = useSearchParams()
  const [loading, setLoading] = useState(false)
  const [addFormLead, { isError, isLoading }] = useAddFormLeadMutation()
  const [data, setData] = useState<any>(initialLead)
  const [error, setError] = useState<string>('')
  const [screen, setScreen] = useState<string>('')
  const [checkedWhatsappAsPhone, setCheckedWhatsappAsPhone] = useState(false)

  const {
    data: form,
    isLoading: isLoadingForm,
    isError: isErrorForm,
  } = useGetFormQuery(queryParameters.get('name')!)

  const handleChange = (e: { target: { id: any; value: any } }) => {
    setData({ ...data, [e.target.id]: e.target.value })
  }

  async function createLead(e: any) {
    e.preventDefault()
    setError('')

    if (data.firstName.length < 1) {
      return setError('First Name is required')
    }

    if (data.email && !isEmail(data.email)) {
      return setError('Valid email is required')
    }

    if (!isPhone(data.phone)) {
      return setError('Valid phone is required')
    }

    if (!isPhone(data.whatsappNumber)) {
      return setError('Valid WhatsApp phone number is required')
    }

    setLoading(true)

    const result = await addFormLead({
      ...data,
      status: 'NEW',
      formName: queryParameters.get('name'),
      token: queryParameters.get('token'),
    })

    if (isError) {
      setError('Error happened while sending data, please try again')
    } else {
      setScreen('done')
    }
  }

  if (isLoadingForm)
    return (
      <div className="w-full h-full grid place-content-center">
        <Loader />
      </div>
    )
  if (isErrorForm)
    return (
      <div className="w-full h-full grid place-content-center">
        <Error message="Server error, contact administrator please" />
      </div>
    )

  const activeField = (key: string, Component: any) => {
    if (form?.fields?.includes(key)) return Component
  }
  return (
    <>
      {screen === 'done' && (
        <div className="w-full text-center p-6">Thank you</div>
      )}
      {screen !== 'done' && (
        <div className="w-full p-6 bg-white">
          <form onSubmit={createLead} className="space-y-3">
            <div className="w-full flex flex-col md:flex-row items-center gap-3">
              {activeField(
                'First_Name',
                <CustomInput
                  name="firstName"
                  label="First Name"
                  value={data.firstName}
                  setValue={handleChange}
                  type="text"
                  required
                  disabled={loading}
                />
              )}

              {activeField(
                'Last_Name',
                <CustomInput
                  name="lastName"
                  label="Last Name"
                  value={data.lastName}
                  setValue={handleChange}
                  type="text"
                  disabled={loading}
                />
              )}
            </div>
            <div className="w-full flex flex-col md:flex-row items-center gap-3">
              {activeField(
                'Email',
                <CustomInput
                  name="email"
                  label="Email"
                  value={data.email}
                  setValue={handleChange}
                  type="email"
                  disabled={loading}
                />
              )}
              {activeField(
                'Phone',
                <PhoneInput
                  label="Phone Number"
                  id="phone"
                  handleChange={(value: any) =>
                    setData({
                      ...data,
                      phone: value,
                      ...(checkedWhatsappAsPhone && {
                        whatsappNumber: value,
                      }),
                    })
                  }
                  value={data.phone}
                  required
                  disabled={loading}
                />
              )}
            </div>
            {activeField(
              'Whatsapp_Number',
              <div className="w-full flex flex-col md:flex-row items-center gap-3">
                <PhoneInput
                  label="Whatsapp Number"
                  id="whatsappNumber"
                  className="w-full"
                  handleChange={(value: any) =>
                    setData({
                      ...data,
                      whatsappNumber: value,
                    })
                  }
                  value={data.whatsappNumber}
                  required
                  disabled={checkedWhatsappAsPhone || loading}
                />
                <div className="flex w-full pt-6 items-center space-x-2 ">
                  <Checkbox
                    id="whatsapp"
                    className=" disabled:bg-gray-500"
                    disabled={loading}
                    checked={checkedWhatsappAsPhone}
                    onCheckedChange={(checked) => {
                      if (checked) {
                        setData({
                          ...data,
                          whatsappNumber: data.phone,
                        })
                      } else {
                        setData({
                          ...data,
                          whatsappNumber: '',
                        })
                      }
                      setCheckedWhatsappAsPhone((prev) => !prev)
                    }}
                  />
                  <label
                    htmlFor="whatsapp"
                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                  >
                    Use phone number
                  </label>
                </div>
              </div>
            )}
            <div className="w-full flex flex-col md:flex-row items-center gap-3">
              {activeField(
                'Gender',
                <div className="w-full space-y-2">
                  <Label>Gender</Label>
                  <Select
                    value={data.gender}
                    onValueChange={(value: any) =>
                      setData({
                        ...data,
                        gender: value,
                      })
                    }
                    disabled={loading}
                  >
                    <SelectTrigger>
                      <SelectValue>{data.gender}</SelectValue>
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectItem value="male">Male</SelectItem>
                        <SelectItem value="female">Female</SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
              )}

              {activeField(
                'Nationality',
                <CustomNationalityInput
                  label="Nationality"
                  id="nationality"
                  handleChange={(value: any) =>
                    setData({
                      ...data,
                      nationality: value,
                    })
                  }
                  value={data.nationality}
                  required
                  disabled={loading}
                />
              )}
            </div>
            {activeField(
              'Interest',
              <div className="w-full flex items-center gap-3">
                <CustomTextArea
                  label="Interest"
                  name="interest"
                  value={data.interest}
                  setValue={handleChange}
                  type="text"
                  disabled={loading}
                />
              </div>
            )}

            <div className="flex gap-5 items-center">
              <Button type="submit">Send</Button>
              <p className="text-rose-500">{error}</p>
            </div>
          </form>
        </div>
      )}
    </>
  )
}
