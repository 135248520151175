import Branches from './components/branches'
import InsuranceProviders from './components/insurance-provider'
import InsuranceStatuses from './components/insurance-status'
import Departments from './components/departments'
import Specialists from './components/specialists'

export const Hierarchy = () => {
  return (
    <div className="flex flex-col gap-10">
      <Branches />
      <Departments />
      <Specialists />
    </div>
  )
}
