import axios from 'axios'

const baseURL = process.env.REACT_APP_DEV_API_URL

const api = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
})

api.interceptors.request.use(
  (config) => {
    if (!config.headers['Authorization']) {
      const persistUser = localStorage.getItem('persist:user')

      if (persistUser) {
        const token = JSON.parse(JSON.parse(persistUser)?.token)

        if (token) config.headers['Authorization'] = `Bearer ${token}`
      }
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default api
