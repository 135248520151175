import { Button } from 'components/ui/button'
import useApp from 'hooks/useApp'
import { useEffect, useState } from 'react'
import Book from '../../leads/components/book'
import FollowUp from '../../leads/components/follow-up'
import SendWhatsApp from '../../leads/components/send-whatsapp'
import LeadPopUp from './lead-pop-up'
import {
  shouldFollowUp,
  shouldBook,
  hasFollowUpAccess,
  hasWhatsappAccess,
} from 'lib/allowed'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { TbBrandWhatsapp } from 'react-icons/tb'

export default function LeadsFollowUp({ leads }: any) {
  const { user } = useSelector((state: any) => state.user)
  const { settings } = useApp()
  const navigate = useNavigate()
  const [numberOfFollowUps, setNumberOfFollowUps] = useState(0)
  const [timeGap, setTimeGap] = useState(0)
  const [selectedFollowUp, setSelectedFollowUp] = useState<any>(null)
  const [open, setOpen] = useState(false)
  const [needsWhatsApp, setNeedsWhatsApp] = useState<any>()
  const [openFollowUp, setOpenFollowUp] = useState(false)
  const [openBook, setOpenBook] = useState(false)
  const [mode, setMode] = useState<any>('followup')
  const [selectedLead, setSelectedLead] = useState<any>(null)

  useEffect(() => {
    if (settings?.length > 0) {
      setNumberOfFollowUps(settings[0]?.numberOfFollowUps)
      setTimeGap(settings[0]?.followUpTimeGap)
    }
  }, [settings])

  useEffect(() => {
    setNeedsWhatsApp(
      leads?.filter(
        (lead: any) =>
          lead.followUps.length >= numberOfFollowUps && !lead.whatsApp
      )
    )
  }, [leads, numberOfFollowUps])
  return (
    <>
      <div className="text-sm">
        <h1 className="font-semibold">Need Follow up</h1>

        <div className="flex flex-col gap-5 h-full pt-5 text-sm">
          {Array(parseInt(settings[0].numberOfFollowUps))
            .fill(0)
            .map((_, i) => (
              <div key={`${i}dashboard`}>
                <div className="p-3 rounded-md border border-gray-300 border-dashed relative">
                  <div className="absolute text-xs bg-gray-100 -top-3 p-1.5 rounded-md font-semibold">
                    {
                      leads?.filter((lead: any) => lead.followUps.length === i)
                        .length
                    }{' '}
                    leads needs follow up {i + 1}
                  </div>
                  {leads?.filter((lead: any) => lead.followUps.length === i)
                    ?.length < 1 && (
                    <div className="text-center text-gray-500 text-xs">
                      No leads
                    </div>
                  )}
                  <div className="flex flex-col gap-1 mt-3">
                    {leads
                      ?.filter((lead: any) => lead.followUps.length === i)
                      ?.slice(0, 5)
                      .map((lead: any) => (
                        <div
                          key={lead?.id}
                          className="grid grid-cols-4 max-md:grid-cols-5 items-center w-full border-b border-gray-100 last:border-b-0 py-1 gap-4"
                        >
                          <p className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                            {lead?.firstName} {lead?.lastName}
                          </p>
                          <p className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                            {lead?.source?.name}
                          </p>
                          <p className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                            {lead?.phone}
                          </p>
                          <div className="max-md:hidden flex gap-3 overflow-x-hidden whitespace-nowrap text-ellipsis">
                            <Button
                              size="sm"
                              variant="outline"
                              disabled={
                                lead?.followUps?.length >= numberOfFollowUps ||
                                !shouldFollowUp(
                                  lead,
                                  lead?.followUps[lead.followUps.length - 1],
                                  numberOfFollowUps,
                                  timeGap
                                ) ||
                                !hasFollowUpAccess(user)
                              }
                              onClick={async () => {
                                setSelectedLead(lead)
                                setOpenFollowUp(true)
                              }}
                            >
                              Follow Up
                            </Button>
                            <Button
                              size="sm"
                              variant="outline"
                              disabled={!shouldBook(lead)}
                              onClick={() => {
                                setSelectedLead(lead)
                                setOpenBook(true)
                              }}
                            >
                              Book
                            </Button>
                          </div>
                          <div className="md:hidden flex gap-3 overflow-x-hidden whitespace-nowrap text-ellipsis">
                            <Button
                              size="sm"
                              variant="outline"
                              disabled={
                                lead?.followUps?.length >= numberOfFollowUps ||
                                !shouldFollowUp(
                                  lead,
                                  lead?.followUps[lead.followUps.length - 1],
                                  numberOfFollowUps,
                                  timeGap
                                ) ||
                                !hasFollowUpAccess(user)
                              }
                              onClick={async () => {
                                setSelectedLead(lead)
                                setOpenFollowUp(true)
                              }}
                            >
                              Follow Up
                            </Button>
                          </div>
                          <div className="md:hidden flex gap-3 overflow-x-hidden whitespace-nowrap text-ellipsis">
                            <Button
                              size="sm"
                              variant="outline"
                              disabled={!shouldBook(lead)}
                              onClick={() => {
                                setSelectedLead(lead)
                                setOpenBook(true)
                              }}
                            >
                              Book
                            </Button>
                          </div>
                        </div>
                      ))}
                  </div>
                  {leads?.filter((lead: any) => lead.followUps.length === i)
                    ?.length > 5 && (
                    <div className="mt-5">
                      <span
                        onClick={() => {
                          setSelectedFollowUp(
                            leads.filter(
                              (lead: any) => lead.followUps.length === i
                            )
                          )
                          setMode('followup')
                          setOpen(true)
                        }}
                        className="font-bold text-xs cursor-pointer text-indigo-600 duration-300"
                      >
                        +
                        {leads.filter(
                          (lead: any) => lead.followUps.length === i
                        )?.length - 5}{' '}
                        more leads
                      </span>
                    </div>
                  )}
                </div>
              </div>
            ))}

          {/* needs whatsapp */}
          {
            <div className="p-3 rounded-md border border-gray-300 border-dashed relative">
              <div className="absolute text-gray-500 text-xs bg-white -top-2 px-1">
                {needsWhatsApp?.length} leads needs WhatsApp
              </div>
              {needsWhatsApp?.slice(0, 5).length < 1 && (
                <div className="text-center text-gray-500 text-xs">
                  No leads
                </div>
              )}
              {needsWhatsApp?.map((lead: any) => (
                <div
                  key={lead.id}
                  className="grid grid-cols-4 w-full border-b items-center border-gray-100 last:border-b-0 py-1 gap-4"
                >
                  <p className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                    {lead?.firstName} {lead?.lastName}
                  </p>

                  <p className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                    {lead?.source.name}
                  </p>
                  <p className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                    {lead?.whatsappNumber}
                  </p>
                  {hasWhatsappAccess(user) && (
                    <Button
                      variant="outline"
                      className="flex items-center gap-2 text-xs overflow-hidden text-ellipsis whitespace-normal"
                      onClick={() => {
                        navigate(`/social`, {
                          state: {
                            leadId: lead?.id,
                            leadFirstName: lead?.firstName,
                            leadWhatsappNumber: lead?.whatsappNumber,
                          },
                        })
                      }}
                    >
                      Send Whatsapp
                      <TbBrandWhatsapp className="text-gray-500" size={15} />
                    </Button>
                  )}
                </div>
              ))}
              {needsWhatsApp?.length > 5 && (
                <div className="mt-5">
                  <span
                    onClick={() => {
                      setSelectedFollowUp(needsWhatsApp)
                      setMode('whatsapp')
                      setOpen(true)
                    }}
                    className="text-gray-500 text-xs cursor-pointer text-indigo-600 duration-300"
                  >
                    +{needsWhatsApp?.length - 5} more leads
                  </span>
                </div>
              )}
            </div>
          }
        </div>
      </div>
      <LeadPopUp
        leads={selectedFollowUp}
        open={open}
        setOpen={setOpen}
        canFollowUp={true}
        canBook={true}
        setOpenFollowUp={setOpenFollowUp}
        setOpenBook={setOpenBook}
        setSelectedLead={setSelectedLead}
        mode={mode}
      />
      {selectedLead && openFollowUp && (
        <FollowUp
          leadId={selectedLead.id}
          open={openFollowUp}
          setOpen={setOpenFollowUp}
          openBook={setOpenBook}
        />
      )}
      {selectedLead && openBook && (
        <Book leadId={selectedLead?.id} open={openBook} setOpen={setOpenBook} />
      )}
    </>
  )
}
