import { useUpdateBrandMutation } from 'app/features/settings'
import Error from 'components/shared/error'
import Loader from 'components/shared/loader'
import { Button } from 'components/ui/button'

import { useEffect, useRef, useState } from 'react'
import useApp from 'hooks/useApp'
import { Label } from 'components/ui/label'
import { Textarea } from 'components/ui/textarea'
import { TbEdit } from 'react-icons/tb'
import { useUpdateLogoMutation } from 'app/features/settings'
import { useToast } from 'components/ui/use-toast'

export default function Brand() {
  const { toast } = useToast()
  const { settings } = useApp()

  const [error, setError] = useState('')
  const [updateBrand, { isLoading, isError }] = useUpdateBrandMutation()
  const [
    updateLogo,
    {
      isLoading: uploadingLogo,
      isError: uploadLogoError,
      isSuccess: isLogoUpdated,
    },
  ] = useUpdateLogoMutation()

  const [description, setDescription] = useState<any>('')
  const [remoteLogo, setRemoteLogo] = useState()

  useEffect(() => {
    setDescription(settings ? settings[0]?.description : '')
    setRemoteLogo(settings ? settings[0]?.brandLogo : '')
  }, [settings])

  /**
   * Show error toast if uploadLogoError is true
   */
  useEffect(() => {
    if (uploadLogoError)
      toast({
        title: 'Error occurred',
        description: 'Unable to upload logo. Try again or contact admin.',
      })
  }, [uploadLogoError])

  /**
   * Show success toast if isLogoUpdated is true
   */
  useEffect(() => {
    if (isLogoUpdated)
      toast({
        title: 'Success',
        description: 'Logo updated successfully',
      })
  }, [isLogoUpdated])

  async function update() {
    setError('')
    if (!description) {
      return setError('description is required')
    }
    const data = {
      id: settings[0].id,
      description,
    }

    const result: any = await updateBrand(data)
    if (result?.error) {
      return setError('Failed to update brand')
    }
  }

  if (isLoading || !settings[0]) {
    return (
      <div className="w-full h-full grid place-content-center pt-20">
        <Loader />
      </div>
    )
  }

  if (isError) {
    return (
      <div className="w-full h-full grid place-content-center pt-20">
        <Error message="Server error, contact administrator please" />
      </div>
    )
  }

  /**
   * Uploads a logo file to the server and updates the remote logo.
   * @param {any} e - The event object from the file input change.
   */
  const uploadLogo = async (e: any) => {
    const file = e.target.files[0]
    console.log(file)

    if (file) {
      const formData = new FormData()
      formData.append('image', file)

      const result: any = await updateLogo({
        id: settings[0].id,
        formData: formData,
      })

      if (result?.data?.updated?.brandLogo) {
        setRemoteLogo(result?.data?.updated?.brandLogo)
      }
    }
  }

  return (
    <>
      <div className="flex flex-col p-6 gap-3 ">
        <div className="py-3 border-b border-dashed  items-center gap-3 ">
          <div className="flex flex-col gap-1 w-full">
            <h1 className="text-lg font-medium">Logo</h1>
            <div className="flex justify-center items-end p-2  ">
              <img src={remoteLogo} width={200} height={200} />
              <Button
                variant="link"
                className="ml-2"
                onClick={() => {
                  document.getElementById('logo-input')?.click()
                }}
                disabled={uploadingLogo}
              >
                <TbEdit size={20} />
              </Button>
              <input
                id="logo-input"
                type="file"
                accept="image/png, image/jpeg, image/jpg"
                style={{ display: 'none' }}
                onChange={(e: any) => {
                  uploadLogo(e)
                }}
              />
            </div>
          </div>
        </div>
        <div className="w-full flex items-center gap-3 max-md:flex-col">
          <div className="w-full space-y-2">
            <Label>Description</Label>
            <Textarea
              name="description"
              className="min-h-[16rem]"
              id="description"
              value={description}
              onChange={(e: any) => setDescription(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="mt-5 flex justify-between items-center">
        {error !== '' && (
          <p className="px-3 py-2 bg-rose-100 rounded-md text-xs font-medium text-rose-600">
            {error}
          </p>
        )}
        <div className="flex-1" />
        <Button onClick={update} size="sm">
          Update
        </Button>
      </div>
    </>
  )
}
