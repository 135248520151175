import { useState, useEffect } from 'react'
import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useLoginMutation, useLogoutMutation } from 'app/features/auth'
import { setCredentials, setUser } from 'app/slices/user'
import PasswordInput from 'components/auth/password-input'
import { navigateAdmins, navigateAgents } from 'lib/allowed'
import { EmailInput } from 'components/auth/email-input'
import useApp from 'hooks/useApp'
import { useLazyGetActiveTabsQuery } from 'app/features/settings'
export default function Login() {
  const [getActiveTabs, { isFetching: isFetchingSettingsActiveTabs }] =
    useLazyGetActiveTabsQuery({})
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [error, setError] = useState<string | undefined>('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { user } = useSelector((state: any) => state.user)

  const [login, { isLoading: loginLoading }] = useLoginMutation()

  const [logout] = useLogoutMutation()

  useEffect(() => {
    const redirectUser = async () => {
      const settingsActiveTabsRes = await getActiveTabs()
      const settingsActiveTabsData = settingsActiveTabsRes?.data
        ? settingsActiveTabsRes.data
        : []
      if (
        user &&
        user.verified &&
        (user.role === 'ADMIN' || user.role === 'SUPERADMIN')
      ) {
        navigate(navigateAdmins(settingsActiveTabsData))
      } else if (user && user.verified && user.role === 'AGENT') {
        navigate(navigateAgents(user, settingsActiveTabsData))
      } else if (!user) {
        logout(undefined)
      }
    }
    redirectUser()
  }, [user])

  const handleLogin = async (e: any) => {
    e.preventDefault()
    setError('')
    try {
      const { user, token, socketServerToken } = await login({
        email: email?.trim()?.toLowerCase(),
        password,
      }).unwrap()

      if (user.disabled)
        return setError(
          'Your account is disabled, please contact your administrator'
        )

      if (!user.verified) {
        dispatch(setUser(user))
        return navigate('/verify')
      }
      dispatch(setCredentials({ user, token, socketServerToken }))
    } catch (error: any) {
      if (error.status === 409) {
        return navigate('/temporarily-failure', {
          state: {
            message: error.data?.message,
            reason: error.data?.reason,
          },
        })
      }
      return setError('Wrong email or password')
    }
  }

  return (
    <div className="grid h-screen w-screen place-content-center bg-customBlue">
      <div className="absolute text-center w-full flex mt-10 left-20">
        <h1 className="text-white font-bold text-4xl">DOCTORNA</h1>
      </div>

      {/* Login */}
      <div className="rounded-2xl border p-8 bg-white dark:bg-gray-900 shadow-sm mt-3">
        <h1 className="text-xl text-center mb-8 font-bold">Sign In</h1>

        <form className="w-96 space-y-5 " onSubmit={handleLogin}>
          {error !== '' && (
            <div className="my-1 font-medium text-sm text-rose-600">
              {error}
            </div>
          )}

          <EmailInput
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e: any) => setEmail(e.target.value)}
            required
            disabled={loginLoading}
          />
          <PasswordInput
            label="Password"
            password={password}
            setPassword={setPassword}
            disabled={loginLoading}
            custom={true}
          />

          <div className="pt-3 text-center">
            <Button
              disabled={loginLoading || isFetchingSettingsActiveTabs}
              className=" bg-customBlue rounded-xl  w-1/2 hover:bg-cyan-700"
            >
              Login
            </Button>
          </div>
        </form>
        <div className="flex justify-end mt-5">
          {/* <Link
						to="/forgot-password"
						className="text-sm text-gray-600 hover:text-indigo-600"
					>
						Forgot password?
					</Link> */}
        </div>
      </div>
    </div>
  )
}
