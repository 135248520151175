import { api } from '../api'

type Branch = {
  id: string
  name: string
  location: string
  departments: any
}

type BranchesResponse = Branch[]

export const branchApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getBranches: builder.query<BranchesResponse, void>({
      query: () => '/branches',
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'Branches' as const,
                id,
              })),
              { type: 'Branches', id: 'LIST' },
              'Branches',
            ]
          : [{ type: 'Branches', id: 'LIST' }, 'Branches'],
    }),
    getBranch: builder.query<Branch, string | undefined>({
      query: (id) => `/branches/${id}`,
      providesTags: ['Branches'],
    }),
    addBranch: builder.mutation({
      query: (branch) => ({
        url: '/branches',
        method: 'POST',
        body: {
          ...branch,
        },
      }),
      invalidatesTags: [
        { type: 'Branches', id: 'LIST' },
        'Departments',
        'Specialists',
      ],
    }),
    updateBranch: builder.mutation({
      query: (branch) => ({
        url: `/branches/${branch.id}`,
        method: 'PUT',
        body: {
          ...branch.data,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Branches', id: arg.id },
        'Departments',
        'Specialists',
      ],
    }),
    removeBranch: builder.mutation({
      query: (branch) => ({
        url: `/branches/${branch.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        'Branches',
        'Departments',
        'Specialists',
      ],
    }),
    getBranchesCount: builder.query<any, DashboardFilters | null>({
      query: (filters: DashboardFilters) =>
        `/branches/count?filters=${JSON.stringify(filters)}`,
      providesTags: [{ type: 'Branches', id: 'LIST' }],
    }),
  }),
})

export const {
  useGetBranchesQuery,
  useGetBranchQuery,
  useAddBranchMutation,
  useUpdateBranchMutation,
  useRemoveBranchMutation,
  useGetBranchesCountQuery,
} = branchApi
