import {
  useGetContactsQuery,
  useLazyGetContactsQuery,
} from 'app/features/whatsapp'
import List from './list'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import InitConversation from '../components/init-conversation'
import Loader from 'components/shared/loader'

export default function WhatsApp({
  keyword,
  selectedConversation,
  setSelectedConversation,
  setSelectedLead,
  setConversationLeads,
}: any) {
  const { data, isLoading, isFetching } = useGetContactsQuery(keyword)

  /* Start messaging from lead page related functionality */
  const location = useLocation()
  const navigate = useNavigate()
  const locationState = location?.state
  const [openInitConversation, setOpenInitConversation] = useState(false)
  const [initLead, setInitLead] = useState<any>({
    id: locationState?.leadId,
    firstName: locationState?.leadFirstName,
    whatsappNumber: locationState?.leadWhatsappNumber,
  })
  const [fetchContacts, { isFetching: isFetchingLazy }] =
    useLazyGetContactsQuery()

  useEffect(() => {
    const checkIfNavigatedFromLead = async () => {
      if (locationState) {
        const contacts = await fetchContacts('')
        const leadContact = contacts?.data?.find(
          (item: any) => item?.phoneNumber == locationState?.leadWhatsappNumber
        )
        if (leadContact) {
          setSelectedConversation(leadContact)
          setSelectedLead(
            leadContact?.leads?.find(
              (item: any) => item.id == locationState?.leadId
            ) || {}
          )
          setConversationLeads(leadContact?.leads)
        } else {
          setOpenInitConversation(true)
        }
        location.state = null
        navigate('/social', { replace: true })
      }
    }
    checkIfNavigatedFromLead()
  }, [])

  if (isFetchingLazy)
    return (
      <div className="w-full h-[350px] grid place-content-center">
        <Loader />
      </div>
    )

  return (
    <>
      <List
        contacts={data}
        selectedConversation={selectedConversation}
        setSelectedConversation={setSelectedConversation}
        loading={isLoading || isFetching}
      />
      <InitConversation
        open={openInitConversation}
        setOpen={setOpenInitConversation}
        startMessagingFirstName={initLead.firstName}
        startMessagingWhatsappNumber={initLead.whatsappNumber}
        startMessagingLeadId={initLead.id}
      />
    </>
  )
}
