import { Cross1Icon } from '@radix-ui/react-icons'
import { useAddUserMutation } from 'app/features/users'
import { CustomInput } from 'components/shared/custom-input'
import { CustomSelect } from 'components/shared/custom-select'
import Loader from 'components/shared/loader'
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogTrigger,
} from 'components/ui/alert-dialog'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import { Button } from 'components/ui/button'
import { Label } from 'components/ui/label'
import useApp from 'hooks/useApp'
import { cn } from 'lib/utils'
import { isEmail } from 'lib/validation'
import { useEffect, useState } from 'react'
import { TbX } from 'react-icons/tb'
import { Link } from 'react-router-dom'
import * as Tooltip from '@radix-ui/react-tooltip'
import { Checkbox } from 'components/ui/checkbox'

const initialData = {
  firstName: '',
  lastName: '',
  email: '',
  role: 'AGENT',
  branchesIds: [],
  departmentsIds: [],
  specialistsIds: [],
  groupId: '',
  accessPeriodId: null,
  accessType: null,
}

export default function CreateUser() {
  const { groups, branches, departments, specialists, accessPeriod } = useApp()
  const [addUser, { isLoading }] = useAddUserMutation()
  const [error, setError] = useState('')
  const [open, setOpen] = useState(false)
  const [step, setStep] = useState(1)
  const [data, setData] = useState<any>(initialData)
  const [noInterestLead, setIsNoInterestLead] = useState(false)
  const [limitedAccess, setLimitedAccess] = useState(false)

  useEffect(() => {
    setError('')
    setStep(1)
    setData(initialData)
    setIsNoInterestLead(false)
    setLimitedAccess(false)
  }, [open])

  useEffect(() => {
    setError('')
  }, [step])

  useEffect(() => {
    const accessType =
      noInterestLead && limitedAccess
        ? 'Limited_Access_And_No_Interest_Lead'
        : noInterestLead && !limitedAccess
          ? 'No_Interest_Lead'
          : !noInterestLead && limitedAccess
            ? 'Limited_Access'
            : null
    setData({
      ...data,
      accessType,
    })
  }, [noInterestLead, limitedAccess])

  const handleChange = (e: { target: { id: any; value: any } }) => {
    setData({ ...data, [e.target.id]: e.target.value })
  }

  function addBranch(id: any) {
    if (data.branchesIds.includes(id)) {
      const newBranches = data.branchesIds.filter(
        (branch: any) => branch !== id
      )
      setData({ ...data, branchesIds: newBranches })
    } else {
      setData({ ...data, branchesIds: [...data.branchesIds, id] })
    }
  }

  function addDepartment(id: any) {
    if (data.departmentsIds.includes(id)) {
      const newDepartments = data.departmentsIds.filter(
        (department: any) => department !== id
      )
      setData({ ...data, departmentsIds: newDepartments })
    } else {
      setData({ ...data, departmentsIds: [...data.departmentsIds, id] })
    }
  }

  function addSpecialist(id: any) {
    if (data.specialistsIds.includes(id)) {
      const newSpecialists = data.specialistsIds.filter(
        (specialist: any) => specialist !== id
      )
      setData({ ...data, specialistsIds: newSpecialists })
    } else {
      setData({ ...data, specialistsIds: [...data.specialistsIds, id] })
    }
  }

  function validate1() {
    if (data.firstName.length < 1) {
      return setError('First Name is required')
    }

    if (!isEmail(data.email?.trim()?.toLowerCase())) {
      return setError('Valid email is required')
    }

    if (data.groupId === '') {
      return setError('Group is required')
    }

    if (!data.accessType) {
      return setError('Access type is required')
    }

    if (limitedAccess) setStep(2)
    else setStep(5)
  }

  async function createUser() {
    setError('')
    try {
      const result: any = await addUser({
        ...data,
        email: data.email?.trim()?.toLowerCase(),
      })
      if (result?.error?.status === 409) return setError(result?.error?.data)
      if (result?.error)
        return setError('Failed to create user, please try again')
      setData(initialData)
      setIsNoInterestLead(false)
      setLimitedAccess(false)
      setOpen(false)
    } catch (error) {
      return setError('Failed to create user, please try again')
    }
  }
  useEffect(() => {
    const filteredDepartments = data.departmentsIds.filter(
      (departmentID: any) =>
        data.branchesIds.some((branchID: any) =>
          branches
            .find((branch: any) => branch.id == branchID)
            ?.departments?.some(
              (branchDepartment: { id: string }) =>
                departmentID === branchDepartment.id
            )
        )
    )

    const filteredSpecialists = data.specialistsIds.filter(
      (specialistID: any) =>
        data.branchesIds.some((branchID: any) =>
          branches
            .find((branch: any) => branch.id == branchID)
            ?.departments?.some(
              (departmentSpecialist: { specialists: any[] }) =>
                departmentSpecialist.specialists.some(
                  (item: any) => specialistID === item.id
                )
            )
        )
    )

    if (data?.email)
      setData((prev: any) => {
        return {
          ...prev,
          departmentsIds: [...filteredDepartments],
          specialistsIds: [...filteredSpecialists],
        }
      })
  }, [data.branchesIds])

  useEffect(() => {
    const filteredSpecialists = data.specialistsIds.filter(
      (specialistID: any) =>
        data.departmentsIds.some((departmentID: any) =>
          departments
            .find((department: any) => department.id == departmentID)
            ?.specialists?.some(
              (departmentSpecialist: { id: string }) =>
                specialistID === departmentSpecialist.id
            )
        )
    )

    if (data?.email)
      setData((prev: any) => {
        return {
          ...prev,
          specialistsIds: [...filteredSpecialists],
        }
      })
  }, [data.departmentsIds])

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger asChild>
        <span>
          <Button size="sm">New User</Button>
        </span>
      </AlertDialogTrigger>

      <AlertDialogContent className="sm:max-w-[425px] md:max-w-[745px] bg-white dark:bg-gray-900 pr-3">
        <div className="h-full w-full">
          {/**TODO: refactor, condition for checking available licenses */}
          {false ? (
            <>
              {isLoading && (
                <div className="h-40 grid place-content-center">
                  <Loader />
                </div>
              )}
              {!isLoading && (
                <div>
                  <div className="flex bg-white border border-indigo-400 text-indigo-800 px-4 py-3 rounded-lg relative mr-10">
                    <div>
                      <svg
                        className="w-6 h-6 fill-current text-indigo-800"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path d="M0 0h24v24H0V0z" fill="none" />
                        <path d="M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z" />
                      </svg>
                    </div>

                    <div>
                      <strong className="font-bold">Warning! </strong>

                      <span className="block text-md p-1 ">
                        You have <i>Insufficient Plan, </i>
                        Please upgrade your subscription, OR Disable some agent
                      </span>
                    </div>
                  </div>
                  <div className="mt-10 flex justify-between px-2">
                    <Link
                      className="bg-indigo-600 text-white py-1 px-5  font-medium rounded-md items-center"
                      to="/settings"
                      state={{
                        screen: 'change-subscription-plan',
                      }}
                    >
                      upgrade
                    </Link>
                    <Button variant={'outline'} onClick={() => setOpen(false)}>
                      Cancel
                    </Button>
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              {isLoading && (
                <div className="h-40 grid place-content-center">
                  <Loader />
                </div>
              )}

              {groups < 1 && !isLoading && (
                <div className="p-3">
                  <div className="flex w-full justify-end">
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => setOpen(false)}
                    >
                      <TbX size={18} />
                    </Button>
                  </div>
                  <div className="grid h-96 w-full place-content-center">
                    <div className="text-center">
                      <h1 className="text-lg font-semibold">
                        Please configure groups first to customize user access
                      </h1>
                      <div className="mt-10">
                        <Link
                          className="bg-indigo-600 text-white py-2 px-5 rounded-md"
                          to="/settings"
                        >
                          Go to settings
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {groups.length > 0 && !isLoading && (
                <div className="max-h-[70vh] overflow-hidden overflow-y-scroll">
                  <div className="flex w-full items-center justify-between">
                    <h1 className="text-lg font-semibold capitalize">
                      New User
                    </h1>

                    <Button
                      variant="outline"
                      size="icon"
                      onClick={() => setOpen(false)}
                    >
                      <Cross1Icon className="h-3 w-3" />
                    </Button>
                  </div>

                  {step === 1 && (
                    <>
                      <div className="w-full space-y-3 mt-5 px-1">
                        <div className="w-full flex items-center gap-3">
                          <CustomInput
                            name="firstName"
                            label="First Name"
                            value={data.firstName}
                            setValue={handleChange}
                            type="text"
                            required
                          />
                          <CustomInput
                            name="lastName"
                            label="Last Name"
                            value={data.lastName}
                            setValue={handleChange}
                            type="text"
                          />
                        </div>
                        <div className="w-full flex items-center gap-3">
                          <CustomInput
                            name="email"
                            label="Email"
                            value={data.email}
                            setValue={handleChange}
                            type="email"
                            required
                          />
                          <CustomSelect
                            label="Group"
                            list={groups}
                            value={data.groupId}
                            setValue={(value: any) =>
                              setData({
                                ...data,
                                groupId: value,
                              })
                            }
                            required
                          />
                        </div>

                        <div className="w-1/2 max-md:w-full space-y-2">
                          <Label>
                            Access Type{' '}
                            <span className="ml-1 text-rose-500">*</span>
                          </Label>

                          <div className="w-full flex items-center gap-3">
                            <div className="flex w-full items-center space-x-2 ">
                              <Checkbox
                                id="noInterestLead"
                                className=" disabled:bg-gray-500"
                                checked={noInterestLead}
                                onCheckedChange={(checked: boolean) => {
                                  setIsNoInterestLead(checked)
                                }}
                              />
                              <label
                                htmlFor="noInterestLead"
                                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                              >
                                No interest lead
                              </label>
                            </div>
                            <div className="flex w-full items-center space-x-2 ">
                              <Checkbox
                                id="limitedAccess"
                                className=" disabled:bg-gray-500"
                                checked={limitedAccess}
                                onCheckedChange={(checked: boolean) => {
                                  setLimitedAccess(checked)
                                }}
                              />
                              <label
                                htmlFor="limitedAccess"
                                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                              >
                                Limited access
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mt-5 flex items-center">
                        {error !== '' && (
                          <div className="text-rose-600 text-sm">{error}</div>
                        )}
                        <div className="flex-1" />
                        <Button size="sm" onClick={validate1}>
                          Next
                        </Button>
                      </div>
                    </>
                  )}

                  {step === 2 && (
                    <div className="space-y-3 mt-5">
                      <div>Select Branches</div>
                      {branches.length < 1 && (
                        <p className="mt-3 text-gray-500">No branches</p>
                      )}
                      {branches.length > 0 && (
                        <div className="flex flex-wrap gap-3">
                          {branches.map((branch: any) => (
                            <div
                              key={branch.id}
                              onClick={() => addBranch(branch.id)}
                              className={cn(
                                data.branchesIds.includes(branch.id)
                                  ? 'border-indigo-600'
                                  : 'border',
                                'px-3 py-1 rounded-md cursor-pointer border-2'
                              )}
                            >
                              {branch.name} - {branch.location}
                            </div>
                          ))}
                        </div>
                      )}
                      <div className="pt-5 flex items-center justify-between">
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => setStep(1)}
                        >
                          Back
                        </Button>
                        {data.branchesIds.length > 0 ? (
                          <Button
                            size="sm"
                            onClick={() =>
                              data.branchesIds.length > 0
                                ? setStep(3)
                                : setStep(5)
                            }
                          >
                            Next
                          </Button>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  )}

                  {step === 3 && (
                    <div className="space-y-3 mt-5">
                      <div>Select Departments</div>
                      {departments.filter((department: any) =>
                        data.branchesIds.includes(department.branchId)
                      ).length < 1 && (
                        <p className="mt-3 text-gray-500 text-sm">
                          No department
                        </p>
                      )}
                      {departments.length > 0 && (
                        <div className="flex flex-wrap gap-3">
                          {departments
                            .filter((department: any) =>
                              data.branchesIds.includes(department.branchId)
                            )
                            .map((department: any) => (
                              <div
                                key={department.id}
                                onClick={() => addDepartment(department.id)}
                                className={cn(
                                  data.departmentsIds.includes(department.id)
                                    ? 'border-indigo-600'
                                    : 'border',
                                  'px-3 py-1 rounded-md cursor-pointer border-2'
                                )}
                              >
                                {department.name} - {department.branch.name}
                              </div>
                            ))}
                        </div>
                      )}
                      <div className="pt-5 flex items-center justify-between">
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => setStep(2)}
                        >
                          Back
                        </Button>
                        <Button size="sm" onClick={() => setStep(4)}>
                          Next
                        </Button>
                      </div>
                    </div>
                  )}

                  {step === 4 && (
                    <div className="space-y-3 mt-5">
                      <div>Select Specialist</div>
                      <div className="flex flex-wrap gap-3">
                        {specialists.filter(
                          (specialist: any) =>
                            data.departmentsIds.includes(
                              specialist.departmentId
                            ) && !specialist.disabled
                        ).length < 1 && (
                          <p className="mt-3 text-sm text-gray-500">
                            No specialist
                          </p>
                        )}
                        {specialists
                          .filter(
                            (specialist: any) =>
                              data.departmentsIds.includes(
                                specialist.departmentId
                              ) && !specialist.disabled
                          )
                          .map((specialist: any) => (
                            <div
                              key={specialist.id}
                              onClick={() => addSpecialist(specialist.id)}
                              className={cn(
                                data.specialistsIds.includes(specialist.id)
                                  ? 'border-indigo-600'
                                  : 'border',
                                'px-3 py-1 rounded-md cursor-pointer border-2'
                              )}
                            >
                              {specialist.name} - {specialist.department.name}
                            </div>
                          ))}
                      </div>
                      <div className="pt-5 flex items-center justify-between">
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => setStep(3)}
                        >
                          Back
                        </Button>
                        <Button size="sm" onClick={() => setStep(5)}>
                          Next
                        </Button>
                      </div>
                    </div>
                  )}

                  {step === 5 && (
                    <div className="space-y-3 mt-5">
                      <div className="w-full space-y-2">
                        {error !== '' && (
                          <div className="text-rose-600 text-sm">{error}</div>
                        )}
                        <Label>Limit data accessed by time</Label>
                        <div className="flex gap-3 flex-wrap">
                          {accessPeriod?.map((period: any) => (
                            <div
                              key={period.id}
                              onClick={() => {
                                setData({
                                  ...data,
                                  accessPeriodId: period.id,
                                })
                              }}
                              className={cn(
                                data.accessPeriodId === period.id
                                  ? 'border-indigo-600'
                                  : 'border',
                                'px-3 py-1 rounded-md cursor-pointer border-2 whitespace-nowrap select-none'
                              )}
                            >
                              {period.duration}
                            </div>
                          ))}
                          <div
                            onClick={() => {
                              setData({
                                ...data,
                                accessPeriodId: null,
                              })
                            }}
                            className={cn(
                              !data.accessPeriodId
                                ? 'border-indigo-600'
                                : 'border',
                              'px-3 py-1 rounded-md cursor-pointer border-2 whitespace-nowrap select-none'
                            )}
                          >
                            Unlimited
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center gap-3 justify-between">
                        <Button
                          size="sm"
                          variant="outline"
                          onClick={() => {
                            if (limitedAccess) setStep(4)
                            else setStep(1)
                          }}
                        >
                          Back
                        </Button>
                        <Button size="sm" onClick={createUser}>
                          Create User
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </AlertDialogContent>
    </AlertDialog>
  )
}
