import { Input } from 'components/ui/input'
import { useCreateSaleMutation } from 'app/features/sales'
import { useGetOffersQuery } from 'app/features/offers'
import { Button } from 'components/ui/button'
import { Label } from 'components/ui/label'
import { useForm, Controller } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { TbX } from 'react-icons/tb'
import { useEffect, useState } from 'react'
import { toast } from 'components/ui/use-toast'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import dayjs from 'lib/dayjs'
import { Textarea } from 'components/ui/textarea'
import { useGetSalesQuery } from 'app/features/sales'
import { cn } from 'lib/utils'

const schema = z.object({
  offer: z.any(),
  note: z.string().max(1000).optional().default(''),
})
type FormValues = z.infer<typeof schema>

/**
 * A helper function to display an error message for a input field
 * @param data - an object containing the error message
 * @returns a JSX element displaying the error message
 */
const InputError = (data: { text?: string }) => {
  return (
    <>
      <div style={{ height: '20px' }}>
        <p
          className={`text-red-500 text-xs flex items-center ${
            data.text ? 'animate-slide-in' : 'hidden'
          }`}
        >
          {data.text}
        </p>
      </div>
    </>
  )
}

/**
 * A component to create sale
 * @param props.leadId - the lead id
 * @param props.closeDialog - a function to close the dialog
 */
export default function Sale({
  leadId,
  closeDialog,
}: {
  leadId?: string
  closeDialog: any
}) {
  const {
    data: sales,
    isError: isErrorSales,
    isLoading: isLoadingSales,
  } = useGetSalesQuery({ leadId: leadId })

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
  })

  const [
    createSale,
    {
      isSuccess: isSaleCreated,
      isError: isSaleError,
      error: saleCreateError,
      isLoading: isSaving,
    },
  ] = useCreateSaleMutation()
  const { data: offers } = useGetOffersQuery({ disabled: false })

  /**
   * Closes the dialog if the offer is created or updated
   */
  useEffect(() => {
    if (isSaleCreated) {
      toast({
        title: 'Success',
        description: 'Sale created successfully',
      })
      closeDialog()
    }
  }, [isSaleCreated])

  useEffect(() => {
    if (saleCreateError) {
      console.log(saleCreateError)

      toast({
        title: 'Error. Cannot create sale.',
        description: (saleCreateError as any).data,
        variant: 'destructive',
      })
    }
  }, [saleCreateError])

  /**
   * Handles form submission by creating a new sale
   * @param data - the form data
   */
  const onSubmit = async (data: FormValues) => {
    await createSale({
      note: data.note,
      offer: data.offer,
      lead: leadId!,
      date: dayjs().toISOString(),
    })
  }

  return (
    <div style={{}}>
      <div className="flex justify-between">
        <div>
          <h1 className="text-lg font-medium mb-1">Sell Offer/Package</h1>
          <p className="text-xs text-gray-500 mb-4">Create sale for the lead</p>
        </div>
        <div>
          <Button
            variant="outline"
            onClick={() => closeDialog()}
            disabled={isSaving}
          >
            <TbX />
          </Button>
        </div>
      </div>

      {/* row with 2 cols */}
      <div className="grid grid-cols-3">
        <div className=" col-span-1 ">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">
              <Label htmlFor="offer">Offer *</Label>

              <div className="mt-2">
                <Select
                  value={getValues('offer')?.name}
                  onValueChange={(value) => {
                    setValue('offer', value)
                  }}
                >
                  <SelectTrigger className="w-full ">
                    <SelectValue
                      placeholder="Select offer"
                      className="text-left"
                    >
                      {getValues('offer')?.name}
                    </SelectValue>
                  </SelectTrigger>
                  <SelectContent className="w-56">
                    <SelectGroup>
                      {offers?.items?.length > 0 &&
                        offers?.items?.map((offer: any) => (
                          <SelectItem key={offer.id} value={offer.id}>
                            <div className="mb-2">
                              {offer.name} - ({offer.price} AED)
                            </div>
                            <div className="w-56 text-xs text-gray-500">
                              {offer.description}
                            </div>
                          </SelectItem>
                        ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>

              {offers?.items !== undefined && offers?.items?.length === 0 && (
                <div>
                  <p className="text-xs text-gray-500 my-2">
                    No offers available (or enabled). Please create an offer
                    first in
                    <a href="/settings?screen=offers">Settings</a>.
                  </p>
                </div>
              )}

              <InputError text={errors?.offer?.message as string | undefined} />
            </div>

            <div className="flex flex-row">
              <div className="w-full">
                <Label htmlFor="note">Note</Label>

                <Controller
                  name="note"
                  control={control}
                  render={({ field }) => (
                    <Textarea {...field} className="mt-2" />
                  )}
                />
                <InputError text={errors?.note?.message} />
              </div>
            </div>

            <Button type="submit" disabled={isSaving}>
              Create
            </Button>
          </form>
        </div>
        <div className=" col-span-2 pl-10">
          <div className="text-lg font-medium mb-1">Sales history</div>
          <table
            className={cn(
              'text-sm w-full border-collapse text-[13px] table-fixed'
            )}
          >
            <thead>
              <tr className="text-center border-b w-full ">
                <th className="lg:w-20 ">Offer Name</th>
                <th className="lg:w-20 ">Value</th>
                <th className="lg:w-20 ">Date</th>
                <th className="lg:w-20 ">Note</th>
              </tr>
            </thead>
            <tbody>
              {sales?.map((sale: any) => (
                <tr key={sale.id} className="text-center border-b w-full">
                  <td style={{ whiteSpace: 'wrap' }} className="xl:w-20 ">
                    {sale.offer.name}
                  </td>
                  <td>
                    <div className="xl:w-20 ">{sale.offer.price} AED</div>
                  </td>
                  <td>{dayjs(sale.date).format('DD/MM/YYYY HH:mm')}</td>
                  <td style={{ whiteSpace: 'wrap' }}>{sale.note}</td>
                </tr>
              ))}
              {sales?.length === 0 && (
                <tr>
                  <td colSpan={3} className="text-center text-gray-500">
                    No sales found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
