import {
  useAddGroupMutation,
  useGetGroupsQuery,
  useUpdateGroupMutation,
} from 'app/features/group'
import Error from 'components/shared/error'
import Loader from 'components/shared/loader'
import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { useEffect, useState } from 'react'
import {
  TbBrandMeta,
  TbBrandWhatsapp,
  TbCircleDashedX,
  TbEdit,
  TbFile,
  TbLayoutGrid,
  TbListDetails,
  TbProgressCheck,
  TbSocial,
} from 'react-icons/tb'
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogTrigger,
} from 'components/ui/alert-dialog'
import { Cross1Icon } from '@radix-ui/react-icons'
import { CustomInput } from 'components/shared/custom-input'
import { Label } from 'components/ui/label'
import { cn } from 'lib/utils'
import { FaEye } from 'react-icons/fa'
import { IoShieldCheckmarkSharp } from 'react-icons/io5'
import { BiTask } from 'react-icons/bi'
import useApp from 'hooks/useApp'

export const agentTabs = [
  'Dashboard',
  'Leads',
  'All_Leads',
  'Booked',
  'Show',
  'Not_Qualified',
  'Insurance_Portal',
  'Social',
]

const roles = ['Add_Tasks', 'Create_template', 'Create_campaign']

const Icons: any = {
  Dashboard: <TbLayoutGrid size={18} className="group-hover:text-indigo-600" />,
  Leads: <TbListDetails size={18} className="group-hover:text-indigo-600" />,
  All_Leads: (
    <TbListDetails size={18} className="group-hover:text-indigo-600" />
  ),

  Booked: <TbProgressCheck size={18} className="group-hover:text-indigo-600" />,
  Show: <FaEye size={18} className="group-hover:text-indigo-600" />,
  Not_Qualified: (
    <TbCircleDashedX size={18} className="group-hover:text-indigo-600" />
  ),

  Insurance_Portal: (
    <IoShieldCheckmarkSharp size={18} className="group-hover:text-indigo-600" />
  ),

  Add_Tasks: <BiTask size={18} className="group-hover:text-indigo-600" />,
  Social: <TbSocial size={18} className="group-hover:text-indigo-600" />,
  Create_template: <TbEdit size={18} className="group-hover:text-indigo-600" />,
  Create_campaign: <TbFile size={18} className="group-hover:text-indigo-600" />,
}

export default function Groups() {
  const { activeTabs: settingsActiveTabs } = useApp()
  const activeTabs =
    settingsActiveTabs?.length > 0 ? settingsActiveTabs[0].activeTabs : []

  const [error, setError] = useState('')
  const [data, setData] = useState<{
    name: string
    tabs: string[]
    roles: string[]
  }>({
    name: '',
    tabs: [],
    roles: [],
  })

  const [selectedGroup, setSelectedGroup] = useState('')
  const [mode, setMode] = useState('add')
  const [openAdd, setOpenAdd] = useState(false)
  const [openUpdate, setOpenUpdate] = useState(false)

  const { data: groups, isError, isLoading: dataLoading } = useGetGroupsQuery()
  const [addGroup, { isLoading: createLoading, isError: createError }] =
    useAddGroupMutation()
  const [updateGroup, { isLoading: updateLoading, isError: updateError }] =
    useUpdateGroupMutation()

  const isLoading = dataLoading || createLoading || updateLoading

  useEffect(() => {
    if (createError || updateError) setError('Failed to add group')
  }, [createError, updateError])

  useEffect(() => {
    setMode('add')
  }, [isLoading])

  useEffect(() => {
    if (mode === 'add') {
      setData({ name: '', tabs: [], roles: [] })
      setSelectedGroup('')
      setError('')
      setMode('add')
    }
  }, [mode])

  const handleSelectTab = (tab: string) => {
    if (data?.tabs?.includes(tab)) {
      const newTabs = data?.tabs?.filter((item: any) => item !== tab)
      setData({ ...data, tabs: newTabs })
    } else {
      setData({ ...data, tabs: [...data.tabs, tab] })
    }
  }
  const handleSelectRole = (role: string) => {
    if (data?.roles?.includes(role)) {
      const newRoles = data?.roles?.filter((item: any) => item !== role)
      setData({ ...data, roles: newRoles })
    } else {
      setData({ ...data, roles: [...data.roles, role] })
    }
  }
  const handleGroup = async (e: any) => {
    e.preventDefault()
    setError('')

    if (mode === 'add')
      await addGroup({ name: data.name, tabs: data.tabs, roles: data.roles })
    else
      await updateGroup({
        id: selectedGroup,
        data: { name: data.name, tabs: data.tabs, roles: data.roles },
      })
    setData({ name: '', tabs: [], roles: [] })
    setMode('add')
    setOpenAdd(false)
    setOpenUpdate(false)
  }

  if (isLoading)
    return (
      <div className="w-full h-full grid place-content-center pt-20">
        <Loader />
      </div>
    )
  if (isError)
    return (
      <div className="w-full h-full grid place-content-center pt-20">
        <Error message="Server error, contact administrator please" />
      </div>
    )

  return (
    <div className="flex flex-col p-6">
      <h1 className="text-lg font-medium">Groups</h1>
      <p className="text-xs text-gray-500">
        Create groups that will be used to categorize users
      </p>
      {/* Form */}
      <div className="flex items-center gap-3 mt-5 max-md:flex-col">
        <div className="flex-1" />

        <AddGroup
          open={openAdd}
          setOpen={setOpenAdd}
          error={error}
          setError={setError}
          handleGroup={handleGroup}
          handleSelectTab={handleSelectTab}
          handleSelectRole={handleSelectRole}
          activeTabs={activeTabs}
          isLoading={isLoading}
          data={data}
          setData={setData}
          mode={mode}
          setMode={setMode}
        />
        <UpdateGroup
          open={openUpdate}
          setOpen={setOpenUpdate}
          error={error}
          setError={setError}
          handleGroup={handleGroup}
          handleSelectTab={handleSelectTab}
          handleSelectRole={handleSelectRole}
          activeTabs={activeTabs}
          isLoading={isLoading}
          data={data}
          setData={setData}
          mode={mode}
          setMode={setMode}
        />
      </div>

      {error !== '' && (
        <p className="my-3 text-xs text-right font-medium text-rose-600">
          {error}
        </p>
      )}
      {/* List */}
      <div className="mt-5 flex flex-col gap-3">
        {groups?.map((group: any) => (
          <div
            key={group.id}
            className="flex items-start gap-2 py-2 px-3 rounded-md bg-gray-50 dark:bg-gray-800 max-sm:items-start"
          >
            <div className="w-28">{group.name}</div>
            <div className="ml-10 flex flex-col gap-3">
              <ul className="flex gap-1 items-center justify-start   flex-wrap max-sm:flex-col max-sm:items-start max-sm:justify-start">
                {group.tabs?.map((tab: any) => {
                  if (activeTabs.includes(tab))
                    return (
                      <li className="px-3 py-1 rounded-md    cursor-pointer border-2 text-sm bg-slate-200 flex items-center justify-center gap-2">
                        {Icons[tab]}
                        {tab?.replace(/_/g, ' ')}{' '}
                      </li>
                    )
                })}
              </ul>
              <ul className="flex gap-1 items-center justify-start   flex-wrap max-sm:flex-col max-sm:items-start max-sm:justify-start">
                {group.roles?.map((role: any) => {
                  return (
                    <li className="px-3 py-1 rounded-md    cursor-pointer border-2 text-sm bg-slate-200 flex items-center justify-center gap-2">
                      {Icons[role]}
                      {role?.replace(/_/g, ' ')}{' '}
                    </li>
                  )
                })}
              </ul>
            </div>
            <div className="flex-1" />
            <div className="flex items-center gap-3">
              <Button
                size="sm"
                variant="ghost"
                onClick={() => {
                  setMode('update')
                  setOpenUpdate(true)
                  setData({
                    name: group.name,
                    tabs: group.tabs || [],
                    roles: group.roles || [],
                  })
                  setSelectedGroup(group.id)
                }}
              >
                <TbEdit size={18} />
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

const AddGroup = ({
  open,
  setOpen,
  error,
  setError,
  data,
  setData,
  handleSelectTab,
  handleSelectRole,
  activeTabs,
  isLoading,
  handleGroup,
  mode,
  setMode,
}: any) => {
  const [step, setStep] = useState(1)

  function validate1() {
    if (!data?.name || data.name?.length < 1) {
      return setError('Group Name is required')
    }
    setError('')
    setStep(step + 1)
  }

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger asChild>
        <span>
          <Button size="sm">Add Group</Button>
        </span>
      </AlertDialogTrigger>

      <AlertDialogContent className="sm:max-w-[425px] md:max-w-[745px] bg-white dark:bg-gray-900 pr-3">
        <div className="h-full w-full">
          <>
            <div className="max-h-[70vh] overflow-hidden overflow-y-scroll">
              <div className="flex w-full items-center justify-between">
                <h1 className="text-lg font-semibold capitalize">
                  {mode === 'add' ? 'Add Group' : 'Update Group'}
                </h1>

                <Button
                  variant="outline"
                  size="icon"
                  onClick={() => {
                    setOpen(false)
                    setMode('add')
                    setError('')
                    setData({ name: '', tabs: [], roles: [] })
                    setStep(1)
                  }}
                >
                  <Cross1Icon className="h-3 w-3" />
                </Button>
              </div>

              {step === 1 && (
                <>
                  <div className="w-full space-y-3 mt-5 px-1">
                    <div className="w-full flex items-center gap-3">
                      <CustomInput
                        name="name"
                        label="Group Name"
                        value={data.name}
                        setValue={(e: any) =>
                          setData((prev: any) => {
                            return { ...prev, name: e.target.value }
                          })
                        }
                        type="text"
                        required
                        disabled={isLoading}
                      />
                    </div>
                  </div>
                  <div className="mt-5 flex items-center">
                    {error !== '' && (
                      <div className="text-rose-600 text-sm">{error}</div>
                    )}
                    <div className="flex-1" />
                    <Button size="sm" onClick={validate1}>
                      Next
                    </Button>
                  </div>
                </>
              )}

              {step === 2 && (
                <div className="space-y-3 my-5">
                  {error !== '' && (
                    <div className="text-rose-600 text-sm">{error}</div>
                  )}
                  <div className="w-full space-y-2">
                    <Label>Accessible Tabs</Label>
                    <div className="flex gap-3 flex-wrap">
                      {agentTabs?.map((tab: any) => {
                        if (activeTabs?.includes(tab))
                          return (
                            <div
                              key={tab}
                              onClick={() => {
                                handleSelectTab(tab)
                              }}
                              className={cn(
                                data?.tabs?.includes(tab)
                                  ? 'border-indigo-600'
                                  : 'border',
                                'px-3 py-1 rounded-md cursor-pointer border-2 whitespace-nowrap select-none flex items-center justify-center gap-2'
                              )}
                            >
                              {Icons[tab]}
                              {tab?.replace(/_/g, ' ')}
                            </div>
                          )
                      })}
                    </div>
                  </div>
                  <div className="w-full space-y-2">
                    <Label className="pt-6">Accessible Roles</Label>
                    <div className="flex gap-3 flex-wrap">
                      {roles?.map((role: any) => (
                        <div
                          key={role}
                          onClick={() => {
                            handleSelectRole(role)
                          }}
                          className={cn(
                            data?.roles?.includes(role)
                              ? 'border-indigo-600'
                              : 'border',
                            'px-3 py-1 rounded-md cursor-pointer border-2 whitespace-nowrap select-none flex items-center justify-center gap-2'
                          )}
                        >
                          {Icons[role]}
                          {role?.replace(/_/g, ' ')}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="flex items-center gap-3 justify-between pt-5">
                    <Button
                      size="sm"
                      variant="outline"
                      onClick={() => setStep(1)}
                    >
                      Back
                    </Button>
                    <Button
                      size="sm"
                      onClick={handleGroup}
                      disabled={isLoading}
                    >
                      {mode === 'add' ? 'Add Group' : 'Update Group'}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  )
}

const UpdateGroup = ({
  open,
  setOpen,
  error,
  setError,
  data,
  setData,
  handleSelectTab,
  handleSelectRole,
  activeTabs,
  isLoading,
  handleGroup,
  mode,
  setMode,
}: any) => {
  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogContent className="sm:max-w-[425px] md:max-w-[745px] bg-white dark:bg-gray-900 pr-3">
        <div className="h-full w-full">
          <>
            <div className="max-h-[70vh] overflow-hidden overflow-y-scroll">
              <div className="flex w-full items-center justify-between">
                <h1 className="text-lg font-semibold capitalize">
                  {mode === 'add' ? 'Add Group' : 'Update Group'}
                </h1>

                <Button
                  variant="outline"
                  size="icon"
                  onClick={() => {
                    setOpen(false)
                    setMode('add')
                    setError('')
                  }}
                >
                  <Cross1Icon className="h-3 w-3" />
                </Button>
              </div>

              <div className="space-y-3 my-5">
                <div className="w-full space-y-2">
                  {error !== '' && (
                    <div className="text-rose-600 text-sm">{error}</div>
                  )}

                  <div className="w-full flex items-center gap-3 pb-4">
                    <CustomInput
                      name="name"
                      label="Group Name"
                      value={data.name}
                      setValue={(e: any) =>
                        setData((prev: any) => {
                          return { ...prev, name: e.target.value }
                        })
                      }
                      type="text"
                      required
                      disabled={isLoading}
                    />
                  </div>
                  <Label>Accessible Tabs</Label>
                  <div className="flex gap-3 flex-wrap pb-3">
                    {agentTabs?.map((tab: any) => {
                      if (activeTabs?.includes(tab))
                        return (
                          <div
                            key={tab}
                            onClick={() => {
                              handleSelectTab(tab)
                            }}
                            className={cn(
                              data?.tabs?.includes(tab)
                                ? 'border-indigo-600'
                                : 'border',
                              'px-3 py-1  rounded-md cursor-pointer border-2 whitespace-nowrap select-none flex items-center justify-center gap-2'
                            )}
                          >
                            {Icons[tab]}
                            {tab?.replace(/_/g, ' ')}
                          </div>
                        )
                    })}
                  </div>
                  <Label>Accessible Roles</Label>
                  <div className="flex gap-3 flex-wrap">
                    {roles?.map((role: any) => (
                      <div
                        key={role}
                        onClick={() => {
                          handleSelectRole(role)
                        }}
                        className={cn(
                          data?.roles?.includes(role)
                            ? 'border-indigo-600'
                            : 'border',
                          'px-3 py-1 rounded-md cursor-pointer border-2 whitespace-nowrap select-none flex items-center justify-center gap-2'
                        )}
                      >
                        {Icons[role]}
                        {role?.replace(/_/g, ' ')}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex items-center gap-3 justify-between pt-5">
                  <div className="flex-1"></div>
                  <Button
                    size="sm"
                    onClick={(e) => {
                      if (!data?.name || data?.name?.length < 1)
                        return setError('Group Name is required')
                      setError('')
                      handleGroup(e)
                    }}
                    disabled={isLoading}
                  >
                    {mode === 'add' ? 'Add Group' : 'Update Group'}
                  </Button>
                </div>
              </div>
            </div>
          </>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  )
}
