import { api } from '../api'

type Specialist = {
  id: string
  name: string
  departmentsId: string
  disabled: boolean
}

type SpecialistsResponse = Specialist[]

export const specialistApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSpecialists: builder.query<SpecialistsResponse, void>({
      query: () => '/specialists',
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'Specialists' as const,
                id,
              })),
              { type: 'Specialists', id: 'LIST' },
              'Specialists',
            ]
          : [{ type: 'Specialists', id: 'LIST' }, 'Specialists'],
    }),
    getSpecialist: builder.query<Specialist, string | undefined>({
      query: (id) => `/specialists/${id}`,
      providesTags: ['Specialists'],
    }),
    addSpecialist: builder.mutation({
      query: (specialist) => ({
        url: '/specialists',
        method: 'POST',
        body: {
          ...specialist,
        },
      }),
      invalidatesTags: [
        { type: 'Specialists', id: 'LIST' },
        'Branches',
        'Departments',
      ],
    }),
    updateSpecialist: builder.mutation({
      query: (specialist) => ({
        url: `/specialists/${specialist.id}`,
        method: 'PUT',
        body: {
          ...specialist.data,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Specialists', id: arg.id },
        'Branches',
        'Departments',
      ],
    }),
    removeSpecialist: builder.mutation({
      query: (specialist) => ({
        url: `/specialists/${specialist.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        'Specialists',
        'Branches',
        'Departments',
      ],
    }),
    getSpecialistsCount: builder.query<any, DashboardFilters | null>({
      query: (filters: DashboardFilters) =>
        `/specialists/count?filters=${JSON.stringify(filters)}`,
      providesTags: [{ type: 'Specialists', id: 'LIST' }],
    }),
  }),
})

export const {
  useGetSpecialistsQuery,
  useGetSpecialistQuery,
  useAddSpecialistMutation,
  useUpdateSpecialistMutation,
  useRemoveSpecialistMutation,
  useGetSpecialistsCountQuery,
} = specialistApi
