import {
  useAddConversationTagMutation,
  useGetConversationTagsQuery,
  useUpdateConversationTagMutation,
} from 'app/features/whatsapp'
import Error from 'components/shared/error'
import Loader from 'components/shared/loader'
import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { useEffect, useState } from 'react'
import { TbEdit } from 'react-icons/tb'

export default function WhatsappTags() {
  const [error, setError] = useState('')
  const [name, setName] = useState('')
  const [selectedTag, setSelectedTag] = useState('')
  const [mode, setMode] = useState('add')

  const {
    data: conversationTags,
    isError,
    isLoading: dataLoading,
  } = useGetConversationTagsQuery()
  const [
    addConversationTag,
    { isLoading: createLoading, isError: createError },
  ] = useAddConversationTagMutation()
  const [
    updateConversationTag,
    { isLoading: updateLoading, isError: updateError },
  ] = useUpdateConversationTagMutation()

  const isLoading = dataLoading || createLoading || updateLoading

  useEffect(() => {
    if (createError || updateError) setError('Tag is already exist')
  }, [createError, updateError])

  useEffect(() => {
    setMode('add')
  }, [isLoading])

  useEffect(() => {
    if (mode === 'add') {
      setName('')
      setSelectedTag('')
      setError('')
      setMode('add')
    }
  }, [mode])

  const handleWhatsappTag = async (e: any) => {
    e.preventDefault()
    setError('')
    if (!name || name.trim() == '') return setError('Name is required')
    if (mode === 'add') await addConversationTag({ name })
    else
      await updateConversationTag({
        id: selectedTag,
        data: { name },
      })
    setName('')
    setMode('add')
  }

  if (isLoading)
    return (
      <div className="w-full h-full grid place-content-center pt-20">
        <Loader />
      </div>
    )
  if (isError)
    return (
      <div className="w-full h-full grid place-content-center pt-20">
        <Error message="Server error, contact administrator please" />
      </div>
    )

  return (
    <div className="flex flex-col p-6">
      {/* <h1 className="text-sm font-medium">Whatsapp Tags</h1>
      <p className="text-xs text-gray-500">Manage Whatsapp Tags</p> */}
      {/* Form */}
      <form
        className="flex items-center gap-3 mt-5 max-md:flex-col"
        onSubmit={handleWhatsappTag}
      >
        <Input
          value={name}
          onChange={(e: any) => setName(e.target.value)}
          type="text"
          placeholder="Whatsapp Tag"
          disabled={isLoading || createLoading}
        />
        <Button type="submit" disabled={isLoading || createLoading} size="sm">
          {mode === 'add' ? 'Add Tag' : 'Update Tag'}
        </Button>
        {mode === 'update' && (
          <Button size="sm" variant="secondary" onClick={() => setMode('add')}>
            Cancel update
          </Button>
        )}
      </form>
      {error !== '' && (
        <p className="my-3 text-xs font-medium text-rose-600">{error}</p>
      )}
      {/* List */}
      <div className="mt-5 flex flex-col gap-3  min-h-[100px] max-h-[250px] overflow-y-scroll border rounded-md p-2">
        {conversationTags?.length == 0 && (
          <div className="flex items-center justify-center p-10 w-full">
            No tags added
          </div>
        )}
        {conversationTags?.map((tag: any) => (
          <div
            key={tag.id}
            className="flex items-center gap-2 py-2 px-3 rounded-md bg-gray-50 dark:bg-gray-800"
          >
            <div>{tag.name}</div>
            <div className="flex-1" />
            <div className="flex items-center gap-3">
              <Button
                size="sm"
                variant="ghost"
                onClick={() => {
                  setMode('update')
                  setName(tag.name)
                  setSelectedTag(tag.id)
                }}
              >
                <TbEdit size={18} />
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
