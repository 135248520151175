import { api } from '../api'

type Filters = {
  take: number
  skip: number
  name?: string
  email?: string
  phone?: string
  source?: string
}

type Lead = {
  id: string
  firstName: string
  lastName: string
  email: string
  phone: string
  status: any

  source?: any
  followUps?: any
  bookings?: any
  branches?: any
  departments?: any
  specialists?: any
}

type LeadsResponse = {
  count: number
  leads: Lead[]
}

type LeadsWhatsappResponse = [
  { id: string; firstName: string; lastName: string; whatsappNumber: string },
]

export const leadApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getLeadsBySource: builder.query<any, DashboardFilters | null>({
      query: (filters: DashboardFilters) =>
        `leads/by-source?filters=${JSON.stringify(filters)}`,
      providesTags: ['Dashboard'],
    }),
    getLeadsByBooking: builder.query<any, DashboardFilters | null>({
      query: (filters: DashboardFilters) =>
        `leads/by-booking?filters=${JSON.stringify(filters)}`,

      providesTags: ['Leads', 'Dashboard'],
    }),
    getAllLeads: builder.query<any, DashboardFilters | null>({
      query: (filters: DashboardFilters) =>
        `leads/all?filters=${JSON.stringify(filters)}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }: { id: any }) => ({
                type: 'Leads' as const,
                id,
              })),
              { type: 'Leads', id: 'LIST' },
              'Dashboard',
            ]
          : [{ type: 'Leads', id: 'LIST' }, 'Dashboard'],
    }),

    getCanBeFollowedUpLeadsCount: builder.query<any, DashboardFilters | null>({
      query: (filters: DashboardFilters) =>
        `leads/can-be-followed-up?filters=${JSON.stringify(filters)}`,
      providesTags: (result) => ['Dashboard'],
    }),
    getCanBeClaimedInsuranceLeadsCount: builder.query<
      any,
      DashboardFilters | null
    >({
      query: (filters: DashboardFilters) =>
        `leads/can-be-claimed-insurance?filters=${JSON.stringify(filters)}`,
      providesTags: (result) => ['Dashboard'],
    }),

    getLeads: builder.query<LeadsResponse, Filters>({
      query: (filters: Filters) => `/leads?filters=${JSON.stringify(filters)}`,
      providesTags: (result) =>
        result
          ? [
              ...result.leads.map(({ id }: { id: any }) => ({
                type: 'Leads' as const,
                id,
              })),
              { type: 'Leads', id: 'LIST' },
              'Dashboard',
            ]
          : [{ type: 'Leads', id: 'LIST' }, 'Dashboard'],
    }),
    exportAsCsv: builder.query<LeadsResponse, Filters>({
      query: (filters: Filters) => ({
        url: `/leads/export-as-csv?filters=${JSON.stringify(filters)}`,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.leads.map(({ id }: { id: any }) => ({
                type: 'Leads' as const,
                id,
              })),
              { type: 'Leads', id: 'LIST' },
            ]
          : [{ type: 'Leads', id: 'LIST' }],
    }),
    exportAsCsvTemplate: builder.query<LeadsResponse, Filters>({
      query: () => ({
        url: `/leads/export-as-csv-template`,
      }),
    }),
    getLead: builder.query<any, string | undefined>({
      query: (id) => `/leads/${id}`,
      providesTags: (arg) => [{ type: 'Lead', id: arg?.id }, { type: 'Lead' }],
    }),
    addLead: builder.mutation({
      query: (lead) => ({
        url: '/leads',

        method: 'POST',
        body: {
          ...lead,
        },
      }),
      invalidatesTags: [
        { type: 'Leads', id: 'LIST' },
        'Leads',
        'Dashboard',
        'Branches',
        'Departments',
        'Specialists',
      ],
    }),
    addBulkLeads: builder.mutation({
      query: (leads) => ({
        url: '/leads/bulk',
        method: 'POST',
        body: {
          leads,
        },
      }),
      invalidatesTags: [
        { type: 'Leads', id: 'LIST' },
        'Leads',
        'Dashboard',
        'Branches',
        'Departments',
        'Specialists',
      ],
    }),
    updateLead: builder.mutation({
      query: (lead) => ({
        url: `/leads/${lead.id}`,
        method: 'PUT',
        body: {
          ...lead.data,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Lead', id: arg?.id },
        'Leads',
        'Lead',
        'Dashboard',
        { type: 'Conversations', id: result?.conversationId },
        // 'Contacts',
        'Branches',
        'Departments',
        'Specialists',
      ],
    }),
    bookLead: builder.mutation({
      query: (booking) => ({
        url: '/leads/book',
        method: 'POST',
        body: {
          ...booking,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Lead', id: arg?.id },
        'Leads',
        'Lead',
        'Dashboard',
        'Settings',
        { type: 'Conversations', id: result?.conversationId },
        'Branches',
        'Departments',
        'Specialists',
        'Sales',
      ],
    }),
    followUp: builder.mutation({
      query: (data) => ({
        url: '/leads/follow-up',
        method: 'POST',
        body: {
          ...data,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Leads', id: 'LIST' },
        { type: 'Lead', id: arg?.id },
        'Leads',
        'Lead',
        'Dashboard',
        { type: 'Conversations', id: result?.conversationId },
      ],
    }),
    sendToInsurance: builder.mutation({
      query: (data) => ({
        url: '/leads/send-to-insurance',
        method: 'POST',
        body: {
          ...data,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Leads', id: 'LIST' },
        { type: 'Lead', id: arg?.id },
        'Leads',
        'Lead',
        'Dashboard',
        { type: 'Conversations', id: result?.conversationId },
      ],
    }),
    attachFile: builder.mutation({
      query: (data) => ({
        url: '/leads/attach-file',
        method: 'POST',
        body: data,
        formData: true,
        Accept: '*/*',
      }),
      invalidatesTags: (arg) => [
        { type: 'Leads', id: 'LIST' },
        { type: 'Lead', id: arg?.id },
        'Leads',
        'Lead',
        'Dashboard',
      ],
    }),
    endInsurance: builder.mutation({
      query: (data) => ({
        url: '/leads/end-insurance',
        method: 'POST',
        body: {
          ...data,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Leads', id: 'LIST' },
        { type: 'Lead', id: arg?.id },
        'Leads',
        'Lead',
        'Dashboard',
        { type: 'Conversations', id: result?.conversationId },
      ],
    }),
    claimInsurance: builder.mutation({
      query: (data) => ({
        url: '/leads/claim-insurance',
        method: 'POST',
        body: {
          ...data,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Leads', id: 'LIST' },
        { type: 'Lead', id: arg?.id },
        'Leads',
        'Lead',
        'Dashboard',
        { type: 'Conversations', id: result?.conversationId },
      ],
    }),
    updateStatus: builder.mutation({
      query: (lead) => ({
        url: `/leads/status/${lead?.id}`,
        method: 'PUT',
        body: {
          ...lead,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Lead', id: arg?.id },
        'Leads',
        'Dashboard',
        'Lead',
        { type: 'Conversations', id: result?.conversationId },
        'Sales',
      ],
    }),
    updateBookLead: builder.mutation({
      query: (booking) => ({
        url: '/leads/update-book',
        method: 'PUT',
        body: {
          ...booking,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Lead', id: arg?.id },
        'Leads',
        'Lead',
        'Dashboard',
        'Settings',
        { type: 'Conversations', id: result?.conversationId },
        'Branches',
        'Departments',
        'Specialists',
        'Sales',
      ],
    }),
    cancelBookLead: builder.mutation({
      query: (booking) => ({
        url: '/leads/cancel-book',
        method: 'POST',
        body: {
          ...booking,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Lead', id: arg?.id },
        'Leads',
        'Lead',
        'Dashboard',
        'Settings',
        { type: 'Conversations', id: result?.conversationId },
      ],
    }),
    getRecentBook: builder.query<any, string | undefined>({
      query: (id) => `/recent-book/${id}`,
      providesTags: (arg) => [{ type: 'Lead', id: arg?.id }, { type: 'Lead' }],
    }),
    sendWhatsapp: builder.mutation({
      query: (data) => ({
        url: '/leads/send-whatsapp',
        method: 'POST',
        body: {
          ...data,
        },
      }),
      invalidatesTags: [
        { type: 'Leads', id: 'LIST' },
        'Leads',
        'Dashboard',
        'Lead',
      ],
    }),
    getLeadsCount: builder.query<any, DashboardFilters | null>({
      query: (filters: DashboardFilters) =>
        `/leads/count?filters=${JSON.stringify(filters)}`,
      providesTags: [{ type: 'Leads', id: 'LIST' }],
    }),
    claimLead: builder.mutation({
      query: (lead) => ({
        url: `/leads/claim/${lead.id}`,
        method: 'PUT',
        body: {
          ...lead,
        },
      }),
      //invalidatesTags: ['Lead'],
    }),
    addFormLead: builder.mutation({
      query: (lead) => ({
        url: '/leads/form-lead',

        method: 'POST',
        body: {
          ...lead,
        },
      }),
    }),
    getLeadsWhatsapp: builder.query<any, DashboardFilters | null>({
      query: (filters: DashboardFilters) =>
        `/leads/all-whatsapp?filters=${JSON.stringify(filters)}`,
    }),
  }),
})

export const {
  useGetAllLeadsQuery,
  useGetCanBeFollowedUpLeadsCountQuery,
  useGetLeadsQuery,
  useGetLeadQuery,
  useLazyGetLeadQuery,
  useAddLeadMutation,
  useAddBulkLeadsMutation,
  useUpdateLeadMutation,
  useUpdateStatusMutation,
  useUpdateBookLeadMutation,
  useGetRecentBookQuery,
  useBookLeadMutation,
  useCancelBookLeadMutation,
  useFollowUpMutation,
  useSendToInsuranceMutation,
  useEndInsuranceMutation,
  useClaimInsuranceMutation,
  useGetCanBeClaimedInsuranceLeadsCountQuery,
  useSendWhatsappMutation,
  useGetLeadsCountQuery,
  useGetLeadsByBookingQuery,
  useGetLeadsBySourceQuery,
  useClaimLeadMutation,
  useExportAsCsvQuery,
  useExportAsCsvTemplateQuery,
  useAddFormLeadMutation,
  useGetLeadsWhatsappQuery,
  useAttachFileMutation,
} = leadApi
