import { useGetOffersQuery, useUpdateOfferMutation } from 'app/features/offers'
import Pagination from 'components/table/pagination'
import { useToast } from 'components/ui/use-toast'
import { cn } from 'lib/utils'
import { useEffect, useState } from 'react'
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogTrigger,
} from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'
import EditOffer from './components/offer/edit-offer'
import ViewOffer from './components/offer/view-offer'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu'
import {
  TbDotsVertical,
  TbEdit,
  TbStatusChange,
  TbWindowMaximize,
} from 'react-icons/tb'

/**
 * A component to manage offers for clients. Each offer includes number of sessions and price.
 * User can create new offer, or edit existing one.
 * @returns {JSX.Element}
 */
export default function Offers() {
  const { toast } = useToast()

  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const { data, isLoading, isError } = useGetOffersQuery({
    skip: Math.abs((currentPage - 1) * rowsPerPage),
    take: rowsPerPage,
  })

  const [
    updateOffer,
    { isSuccess: isOfferUpdated, isError: isOfferUpdateError },
  ] = useUpdateOfferMutation()

  const [editDialog, setEditDialog] = useState<{
    visible: boolean
    offerId?: string
  }>({
    visible: false,
    offerId: undefined,
  })

  const [viewDialog, setViewDialog] = useState<{
    visible: boolean
    offerId?: string
  }>({
    visible: false,
    offerId: undefined,
  })

  /**
   * Show error toast if isError is true
   */
  useEffect(() => {
    if (isError) {
      toast({
        title: 'Error',
        description: 'Error fetching offers',
        variant: 'destructive',
      })
    }
  }, [isError, toast])

  /**
   * Close the dialog
   */
  const onCloseDialog = () => {
    setEditDialog({ visible: false })
    setViewDialog({ visible: false })
  }

  /**
   * Toggle the disabled status of an offer
   * @param offer the offer to toggle
   */
  const toggleDisabled = (offer: any) => {
    const newDisabledStatus = !offer.disabledAt
    updateOffer({ id: offer.id, disabled: newDisabledStatus })
  }

  /**
   * Show success toast if isOfferUpdated is true
   */
  useEffect(() => {
    if (isOfferUpdated) {
      toast({
        title: 'Success',
        description: 'Offer updated successfully',
      })
    }
  }, [isOfferUpdated, toast])

  /**
   * Show error toast if isOfferUpdateError is true
   */
  useEffect(() => {
    if (isOfferUpdateError) {
      toast({
        title: 'Error',
        description: 'Error updating offer',
        variant: 'destructive',
      })
    }
  }, [isOfferUpdateError, toast])

  return (
    <div className="flex flex-col p-6">
      <div className="flex justify-between mb-6">
        <div>
          <h1 className="text-lg font-medium">Offers & Packages</h1>
          <p className="text-xs text-gray-500">
            Manage available offers and packages. Offer is set of services
            grouped into a single package. Each offer includes number of
            sessions and price.
          </p>
        </div>
        <div className="flex">
          <Button onClick={() => setEditDialog({ visible: true })}>
            Create offer
          </Button>
        </div>
      </div>

      <div style={{ minHeight: '70vh' }}>
        <table
          className={cn(
            isLoading ? 'text-gray-400' : 'text-gray-800',
            'text-sm w-full border-collapse text-[13px] table-fixed'
          )}
        >
          <thead>
            <tr className="text-center border-b w-full ">
              <th className="lg:w-20 ">Name</th>
              <th className="lg:w-40 ">Description</th>
              <th className="lg:w-20 ">Price</th>
              <th className="lg:w-20 ">Sessions number</th>
              <th className="lg:w-20 ">Status</th>
              <th className="lg:w-10"></th>
            </tr>
          </thead>
          <tbody>
            {data?.items?.map((offer: any) => (
              <tr key={offer.id}>
                <td className="text-center" style={{ whiteSpace: 'wrap' }}>
                  {offer.name}
                </td>
                <td className="text-center overflow-x-hidden whitespace-nowrap text-ellipsis">
                  {offer.description}
                </td>
                <td className="text-center overflow-x-hidden whitespace-nowrap text-ellipsis">
                  {offer.price}
                </td>
                <td className="text-center overflow-x-hidden whitespace-nowrap text-ellipsis">
                  {offer.sessionsNumber}
                </td>
                <td className="text-center overflow-x-hidden whitespace-nowrap text-ellipsis">
                  {offer.disabledAt ? 'Disabled' : 'Active'}
                </td>
                <td>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button
                        variant="ghost"
                        className="flex h-8 w-8 p-0 data-[state=open]:bg-muted relative"
                      >
                        <TbDotsVertical size={18} />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end" className="w-[150px]">
                      <DropdownMenuItem
                        className="hover:cursor-pointer"
                        onClick={() =>
                          setViewDialog({ visible: true, offerId: offer.id })
                        }
                      >
                        <div className="flex gap-3 items-center ">
                          <TbWindowMaximize size={15} className=" " />
                          <span>View</span>
                        </div>
                      </DropdownMenuItem>

                      <DropdownMenuItem
                        className="hover:cursor-pointer"
                        onClick={() =>
                          setEditDialog({ visible: true, offerId: offer.id })
                        }
                      >
                        <div className="flex gap-3 items-center ">
                          <TbEdit size={15} className=" " />
                          <span>Edit</span>
                        </div>
                      </DropdownMenuItem>

                      <DropdownMenuItem
                        className="hover:cursor-pointer"
                        onClick={() => toggleDisabled(offer)}
                      >
                        <div className="flex gap-3 items-center ">
                          <TbStatusChange size={15} className=" " />
                          <span>{offer.disabledAt ? 'Enable' : 'Disable'}</span>
                        </div>
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div>
        {data && data?.count > 0 && (
          <Pagination
            count={data?.count}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        )}
      </div>

      <AlertDialog
        open={editDialog.visible}
        onOpenChange={() => setEditDialog({ visible: false })}
      >
        <AlertDialogContent className="sm:max-w-[425px] md:max-w-[425px] bg-white dark:bg-gray-900 pr-3">
          <EditOffer offerId={editDialog.offerId} closeDialog={onCloseDialog} />
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog
        open={viewDialog.visible}
        onOpenChange={() => setEditDialog({ visible: false })}
      >
        <AlertDialogContent className="sm:max-w-[425px] md:max-w-[425px] bg-white dark:bg-gray-900 pr-3">
          <ViewOffer
            offerId={viewDialog.offerId!}
            closeDialog={onCloseDialog}
          />
        </AlertDialogContent>
      </AlertDialog>
    </div>
  )
}
