import MetaLogin from './components/meta-login'
import WhatsappLogin from './components/whatsapp-login'
import WhatsappMessageSourceMatching from './components/whatsapp-message-source-matching'
import WhatsappTags from './components/whatsapp-tags'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs'

export const Social = () => {
  return (
    <div className="flex flex-col gap-4 items-center ">
      <div className="flex flex-col p-6  w-full  border-b items-center">
        <h1 className="text-lg font-medium w-full">Whatsapp</h1>
        <p className="text-xs text-gray-500 w-full">
          Manage Whatsapp profile and tags
        </p>

        <Tabs defaultValue="profile" className="w-full  my-4  ">
          <TabsList className=" w-full bg-white   px-1 h-10 border max-w-md ">
            <TabsTrigger
              value="profile"
              className="w-full font-normal  text-base data-[state=active]:bg-indigo-600 data-[state=active]:text-white"
            >
              Profile
            </TabsTrigger>
            <TabsTrigger
              value="tags"
              className="w-full font-normal text-base data-[state=active]:bg-indigo-600 data-[state=active]:text-white"
            >
              Tags
            </TabsTrigger>
            <TabsTrigger
              value="sourceMatchings"
              className="w-full font-normal text-base data-[state=active]:bg-indigo-600 data-[state=active]:text-white"
            >
              Source Matchings
            </TabsTrigger>
          </TabsList>
          <TabsContent value="profile" className="min-h-[370px]">
            <WhatsappLogin />
          </TabsContent>
          <TabsContent value="tags" className="min-h-[370px]">
            <WhatsappTags />
          </TabsContent>
          <TabsContent value="sourceMatchings" className="min-h-[370px]">
            <WhatsappMessageSourceMatching />
          </TabsContent>
        </Tabs>
      </div>

      <div className="flex flex-col p-6 w-full">
        <h1 className="text-lg font-medium">Meta </h1>
        <p className="text-xs text-gray-500">Manage Meta login</p>
        <MetaLogin />
      </div>
    </div>
  )
}
