import { cn } from 'lib/utils'
export const SettingsSideMenu = ({ screen, setScreen }: any) => {
  return (
    <div className="flex flex-col gap-3 p-3 w-48 max-md:hidden overflow-scroll">
      <div
        className={cn(
          screen === 'followUp' ? 'bg-gray-200' : 'bg-transparent',
          'cursor-pointer py-2 px-3 rounded-md'
        )}
        onClick={() => setScreen('followUp')}
      >
        Follow Up
      </div>
      <div
        className={cn(
          screen === 'brand' ? 'bg-gray-200' : 'bg-transparent',
          'cursor-pointer py-2 px-3 rounded-md'
        )}
        onClick={() => setScreen('brand')}
      >
        Brand
      </div>
      <div
        className={cn(
          screen === 'active-tabs' ? 'bg-gray-200' : 'bg-transparent',
          'cursor-pointer py-2 px-3 rounded-md'
        )}
        onClick={() => setScreen('active-tabs')}
      >
        Active Tabs
      </div>

      <div
        className={cn(
          screen === 'not-qualified-reasons' ? 'bg-gray-200' : 'bg-transparent',
          'cursor-pointer py-2 px-3 rounded-md'
        )}
        onClick={() => setScreen('not-qualified-reasons')}
      >
        Not Qualified
      </div>
      <div
        className={cn(
          screen === 'insurance' ? 'bg-gray-200' : 'bg-transparent',
          'cursor-pointer py-2 px-3 rounded-md'
        )}
        onClick={() => setScreen('insurance')}
      >
        Insurance
      </div>

      <div
        className={cn(
          screen === 'sources' ? 'bg-gray-200' : 'bg-transparent',
          'cursor-pointer py-2 px-3 rounded-md'
        )}
        onClick={() => setScreen('sources')}
      >
        Lead Sources
      </div>
      <div
        className={cn(
          screen === 'leadForm' ? 'bg-gray-200' : 'bg-transparent',
          'cursor-pointer py-2 px-3 rounded-md'
        )}
        onClick={() => setScreen('leadForm')}
      >
        Lead Form
      </div>

      <div
        className={cn(
          screen === 'groups' ? 'bg-gray-200' : 'bg-transparent',
          'cursor-pointer py-2 px-3 rounded-md'
        )}
        onClick={() => setScreen('groups')}
      >
        User Groups
      </div>
      <div
        className={cn(
          screen === 'hierarchy' ? 'bg-gray-200' : 'bg-transparent',
          'cursor-pointer py-2 px-3 rounded-md'
        )}
        onClick={() => setScreen('hierarchy')}
      >
        Hierarchy
      </div>

      <div
        className={cn(
          screen === 'social' ? 'bg-gray-200' : 'bg-transparent',
          'cursor-pointer py-2 px-3 rounded-md'
        )}
        onClick={() => setScreen('social')}
      >
        Social
      </div>

      {/* <div
        className={cn(
          screen === 'offers' ? 'bg-gray-200' : 'bg-transparent',
          'cursor-pointer py-2 px-3 rounded-md'
        )}
        onClick={() => setScreen('offers')}
      >
        Offers & Packages
      </div> */}
    </div>
  )
}
