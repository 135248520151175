import DateAndTime from 'components/shared/date-and-time'
import { Label } from 'components/ui/label'

export type DateProps = {
  startDate: Date
  startHour: string
  startAmpm: string
  startMinute: string
  endDate: Date
  endHour: string
  endMinute: string
  endAmpm: string
}

export type DateFiltersProps = {
  startDate: Date
  setStartDate: any
  startHour: string
  setStartHour: any
  startAmpm: string
  setStartAmpm: any
  startMinute: string
  setStartMinute: any
  endDate: Date
  setEndDate: any
  endHour: string
  setEndHour: any
  endMinute: string
  setEndMinute: any
  endAmpm: string
  setEndAmpm: any
}

export const DateFilters = ({
  startDate,
  setStartDate,
  startHour,
  setStartHour,
  startAmpm,
  setStartAmpm,
  startMinute,
  setStartMinute,
  endDate,
  setEndDate,
  endHour,
  setEndHour,
  endMinute,
  setEndMinute,
  endAmpm,
  setEndAmpm,
}: DateFiltersProps) => {
  return (
    <div className="flex flex-col-3 gap-4 justify-end mb-4">
      <div className="space-y-2">
        <Label className="text-sm font-semibold">Start Date:</Label>
        <DateAndTime
          date={startDate}
          setDate={setStartDate}
          hour={startHour}
          setHour={setStartHour}
          minute={startMinute}
          setMinute={setStartMinute}
          ampm={startAmpm}
          setAmpm={setStartAmpm}
        />
      </div>

      <div className="space-y-2">
        <Label className="text-sm font-semibold">End Date:</Label>
        <DateAndTime
          date={endDate}
          setDate={setEndDate}
          hour={endHour}
          setHour={setEndHour}
          minute={endMinute}
          setMinute={setEndMinute}
          ampm={endAmpm}
          setAmpm={setEndAmpm}
          beforeSelectedDate={startDate}
        />
      </div>
    </div>
  )
}
