import WhatsApp from './messages-list/whats-app'
import Messenger from './messages-list/messenger'

interface ChatProps {
  selectedConversationId: string
  conversationLeads: any
  setConversationLeads: any
  selectedLead: any
  setSelectedLead: any
  lastReply: any
  initialLead: any
  isDetailsPage: any
  metaConversationId: any
  activeTab: string
}

export default function Messages({
  selectedConversationId,
  setConversationLeads,
  selectedLead,
  setSelectedLead,
  lastReply,
  initialLead,
  isDetailsPage,
  metaConversationId,
  activeTab,
}: ChatProps) {
  return (
    <>
      {activeTab === 'WhatsApp' ? (
        <WhatsApp
          selectedConversationId={selectedConversationId}
          setConversationLeads={setConversationLeads}
          selectedLead={selectedLead}
          setSelectedLead={setSelectedLead}
          lastReply={lastReply}
          initialLead={initialLead}
          isDetailsPage={isDetailsPage}
          metaConversationId={metaConversationId}
        />
      ) : activeTab === 'Messenger' || activeTab === 'Instagram' ? (
        <Messenger
          selectedConversationId={selectedConversationId}
          initialLead={initialLead}
          isDetailsPage={isDetailsPage}
          metaConversationId={metaConversationId}
        />
      ) : (
        <></>
      )}
    </>
  )
}
