import { Cross1Icon } from '@radix-ui/react-icons'
import {
  useClaimLeadMutation,
  useLazyGetLeadQuery,
  useUpdateBookLeadMutation,
} from 'app/features/lead'
import { useGetSalesQuery } from 'app/features/sales'
import Loader from 'components/shared/loader'
import TimeSelect from 'components/shared/time-select'
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
} from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'
import { Calendar } from 'components/ui/calendar'
import { Textarea } from 'components/ui/textarea'
import dayjs from 'dayjs'
import useApp from 'hooks/useApp'
import { cn, isDatePastThan } from 'lib/utils'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'

export default function UpdateBook({ leadId, open, setOpen }: any) {
  const { branches, departments, specialists, sources } = useApp()
  const [step, setStep] = useState(1)
  const { user } = useSelector((state: any) => state.user)
  const [updateBookLead, { isLoading: isSaving }] = useUpdateBookLeadMutation()
  const [booking, setBooking] = useState<any>({
    date: undefined,
    comment: '',
    branchId: '',
    departmentId: '',
    specialistId: '',
    sale: undefined,
  })
  const [error, setError] = useState('')
  const [date, setDate] = useState<Date | undefined>(undefined)
  const [hour, setHour] = useState<string>('')
  const [minute, setMinute] = useState<string>('')
  const [ampm, setAmpm] = useState<string>('')

  const [claimLead] = useClaimLeadMutation()

  const [isFetchingLead, setIsFetchingLead] = useState(false)
  const [getLead, { data: lead, isLoading: isLoadingLead }] =
    useLazyGetLeadQuery()

  useEffect(() => {
    const fetch = async () => {
      setIsFetchingLead(true)
      const res = await getLead(leadId)
      if (
        res.data &&
        (!res.data?.claimed || isDatePastThan(res.data?.updatedAt))
      ) {
        claimLead({ id: leadId, data: true })
      }

      // get recent booking data
      if (res.data) {
        const bookings = [...res.data.bookings]
        bookings?.sort((a: any, b: any) => {
          const dateA = new Date(a.createdAt)
          const dateB = new Date(b.createdAt)
          if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
            throw new Error('Invalid date format')
          }
          return dateB.getTime() - dateA.getTime() // Sort in descending order
        })
        const recentBook = bookings[0]
        const recentBookDate = dayjs(recentBook.date)

        const datePart = recentBookDate.format('YYYY-MM-DD')
        const hourPart = recentBookDate.format('h')
        const minutePart = recentBookDate.format('m')
        const ampmPart = recentBookDate.format('A')
        setBooking({
          branchId: recentBook.branchId,
          departmentId: recentBook.departmentId,
          specialistId: recentBook.specialistId,
          date: recentBookDate,
          comment: recentBook.comment,
          sale: recentBook.saleId,
        })
        setDate(new Date(datePart))
        setHour(hourPart)
        setMinute(minutePart)
        setAmpm(ampmPart)
      }
      setIsFetchingLead(false)
    }
    fetch()
  }, [])

  const onOpenChange = async () => {
    if (
      open &&
      (!lead?.claimed ||
        (lead?.claimed && lead?.claimedBy?.id == user?.id) ||
        isDatePastThan(lead?.updatedAt))
    ) {
      claimLead({ id: leadId, data: false })
    }
    setOpen((prev: any) => !prev)
  }

  useEffect(() => {
    const handleBeforeUnload = async (event: any) => {
      event.preventDefault() // Cancel the default behavior of refreshing
      if (!lead?.claimed || (lead?.claimed && lead?.claimedBy?.id == user?.id))
        claimLead({ id: leadId, data: false })
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  useEffect(() => {
    setError('')
  }, [step])

  const validate1 = async () => {
    if (booking.branchId === '') {
      return setError('Select a branch')
    }

    setStep(2)
  }

  const validate2 = async () => {
    if (booking.departmentId === '') {
      return setError('Select a department')
    }

    setStep(3)
  }

  const validate3 = async () => {
    if (booking.specialistId === '') {
      return setError('Select a specialist')
    }
    setStep(4)
  }
  const validate4 = async () => {
    if (!date || hour === '' || minute === '' || ampm === '') {
      return setError('Invalid date and time')
    }
    setStep(5)
  }

  const validate5 = async () => {
    //
    setStep(6)
  }

  const update = async () => {
    setBooking({
      ...booking,
      date: dayjs(date)
        .hour(parseInt(hour) + (ampm === 'PM' ? 12 : 0))
        .minute(parseInt(minute))
        .toDate(),
    })
    try {
      await updateBookLead({
        leadId: lead.id,
        booking: {
          ...booking,
          date: dayjs(date)
            .hour(parseInt(hour) + (ampm === 'PM' ? 12 : 0))
            .minute(parseInt(minute))
            .toDate(),
          ...(booking.sale && {
            saleId: booking.sale,
          }),
        },
      })
      await claimLead({ id: leadId, data: false })
      setOpen(false)
    } catch (error) {
      setError('Failed to update book')
    }
  }

  const {
    data: sales,
    isError: isErrorSales,
    isLoading: isLoadingSales,
  } = useGetSalesQuery({ leadId: leadId })

  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent>
        <div className="absolute right-3 top-3">
          <AlertDialogCancel className="p-0">
            <Button variant="ghost">
              <Cross1Icon className="h-3 w-3" />
            </Button>
          </AlertDialogCancel>
        </div>

        {error !== '' && <p className="text-rose-500">{error}</p>}

        {(isSaving || isLoadingLead || isFetchingLead) && (
          <div className="h-40 grid place-content-center">
            <Loader />
          </div>
        )}
        {lead &&
        lead?.claimed &&
        lead?.claimedBy?.id != user?.id &&
        !isDatePastThan(lead?.updatedAt) ? (
          <>
            <AlertDialogHeader>
              <div className="h-40 grid place-content-center">
                Lead is claimed by another agent: {lead.claimedBy?.firstName}{' '}
                {lead.claimedBy?.lastName}
              </div>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>OK</AlertDialogCancel>
            </AlertDialogFooter>
          </>
        ) : (
          <>
            {lead && !isFetchingLead && (
              <div>
                {/* Branches */}
                {step === 1 && (
                  <div className="flex gap-3 flex-col mt-5 min-h-[300px]">
                    <h1 className="font-semibold text-md">Select Branches</h1>
                    {branches.length < 1 && (
                      <p className="mt-3 text-gray-500">No branches</p>
                    )}
                    {branches.length > 0 && (
                      <div className="flex flex-wrap gap-3">
                        {branches.map((branch: any) => (
                          <div
                            key={branch.id}
                            onClick={() =>
                              setBooking({
                                ...booking,
                                branchId: branch.id,
                              })
                            }
                            className={cn(
                              booking.branchId === branch.id
                                ? 'border-indigo-600'
                                : 'border',
                              'px-3 py-1 rounded-md cursor-pointer border-2'
                            )}
                          >
                            {branch.name} - {branch.location}
                          </div>
                        ))}
                      </div>
                    )}
                    <div className="flex-1" />
                    <div className="pt-5 flex items-center justify-between">
                      <div className="flex-1" />

                      <Button onClick={validate1}>Next</Button>
                    </div>
                  </div>
                )}

                {/* Department */}
                {step === 2 && (
                  <div className="flex gap-3 flex-col mt-5 min-h-[300px]">
                    <h1 className="font-semibold text-md">Select Department</h1>
                    {departments.filter(
                      (department: any) =>
                        department.branchId === booking.branchId
                    ).length < 1 && (
                      <p className="mt-3 text-gray-500">No department</p>
                    )}
                    {departments.filter(
                      (department: any) =>
                        department.branchId === booking.branchId
                    ).length > 0 && (
                      <div className="flex flex-wrap gap-3">
                        {departments
                          .filter(
                            (department: any) =>
                              department.branchId === booking.branchId
                          )
                          .map((department: any) => (
                            <div
                              key={department.id}
                              onClick={() =>
                                setBooking({
                                  ...booking,
                                  departmentId: department.id,
                                })
                              }
                              className={cn(
                                booking.departmentId === department.id
                                  ? 'border-indigo-600'
                                  : 'border',
                                'px-3 py-1 rounded-md cursor-pointer border-2'
                              )}
                            >
                              {department.name} - {department.branch.name}
                            </div>
                          ))}
                      </div>
                    )}
                    <div className="flex-1" />
                    <div className="pt-5 flex items-center justify-between">
                      <Button variant="outline" onClick={() => setStep(1)}>
                        Back
                      </Button>
                      <Button onClick={validate2}>Next</Button>
                    </div>
                  </div>
                )}

                {/* Specialist */}
                {step === 3 && (
                  <div className="flex gap-3 flex-col mt-5 min-h-[300px]">
                    <h1 className="font-semibold text-md">Select Specialist</h1>
                    {specialists.filter(
                      (specialist: any) =>
                        specialist.departmentId === booking.departmentId &&
                        !specialist.disabled
                    ).length < 1 && (
                      <p className="mt-3 text-gray-500">No specialist</p>
                    )}
                    {specialists.filter(
                      (specialist: any) =>
                        specialist.departmentId === booking.departmentId
                    ).length > 0 && (
                      <div className="flex flex-wrap gap-3">
                        {specialists
                          .filter(
                            (specialist: any) =>
                              specialist.departmentId ===
                                booking.departmentId && !specialist.disabled
                          )
                          .map((specialist: any) => (
                            <div
                              key={specialist.id}
                              onClick={() =>
                                setBooking({
                                  ...booking,
                                  specialistId: specialist.id,
                                })
                              }
                              className={cn(
                                booking.specialistId === specialist.id
                                  ? 'border-indigo-600'
                                  : 'border',
                                'px-3 py-1 rounded-md cursor-pointer border-2'
                              )}
                            >
                              {specialist.name} - {specialist.department.name}
                            </div>
                          ))}
                      </div>
                    )}
                    <div className="flex-1" />
                    <div className="pt-5 flex items-center justify-between">
                      <Button variant="outline" onClick={() => setStep(2)}>
                        Back
                      </Button>
                      <Button onClick={validate3}>Next</Button>
                    </div>
                  </div>
                )}

                {/* Date And Time */}
                {step === 4 && (
                  <div className="min-h-[300px]">
                    <p className="font-semibold">Select Time and Date</p>
                    <div className="w-full flex flex-col items-center">
                      <TimeSelect
                        hour={hour}
                        setHour={setHour}
                        minute={minute}
                        setMinute={setMinute}
                        ampm={ampm}
                        setAmpm={setAmpm}
                      />
                      <Calendar
                        mode="single"
                        selected={date}
                        onSelect={setDate}
                        initialFocus
                        className="border rounded-md"
                        disabled={true ? { before: new Date() } : undefined}
                      />
                    </div>
                    <div className="flex-1" />
                    <div className="mt-5 flex items-center justify-between">
                      <Button variant="outline" onClick={() => setStep(3)}>
                        Back
                      </Button>{' '}
                      <Button onClick={validate4}>Next</Button>
                    </div>
                  </div>
                )}
                {/* Comment */}
                {step === 5 && (
                  <div className="flex gap-3 flex-col mt-5 min-h-[300px]">
                    <p className="font-semibold">Add Comment</p>
                    <div className="w-full flex flex-col items-center">
                      <Textarea
                        className="mt-5"
                        value={booking.comment}
                        onChange={(e: any) =>
                          setBooking((prev: any) => {
                            return { ...prev, comment: e.target.value }
                          })
                        }
                        placeholder="Add comment"
                      />
                    </div>
                    <div className="flex-1" />
                    <div className="mt-5 flex items-center justify-between">
                      <Button variant="outline" onClick={() => setStep(4)}>
                        Back
                      </Button>
                      <Button onClick={update}>Update</Button>
                    </div>
                  </div>
                )}
                {/* Sale */}
                {step === 6 && (
                  <div className="flex gap-3 flex-col mt-5 min-h-[300px]">
                    <p className="font-semibold">Assign sale (optional)</p>

                    <div className="text-sm">
                      Select sale that you want to assign to this lead
                    </div>
                    {sales?.length > 0 && (
                      <div className="w-full flex gap-3 items-center">
                        <Select
                          key="select-component"
                          value={booking.sale}
                          onValueChange={(value) => {
                            setBooking((prev: any) => {
                              return { ...prev, sale: value }
                            })
                          }}
                        >
                          <SelectTrigger className="w-full ">
                            <SelectValue>{}</SelectValue>
                          </SelectTrigger>
                          <SelectContent className=" w-40  ">
                            <SelectGroup>
                              {sales &&
                                sales.length &&
                                sales?.map((sale: any) => (
                                  <SelectItem key={sale.id} value={sale.id}>
                                    <div>{sale.offer.name}</div>
                                    <div className="text-gray-500 text-xs">
                                      {dayjs(sale.date).format('DD-MM-YYYY')}
                                    </div>
                                  </SelectItem>
                                ))}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                        {/* clear button */}
                        {booking.sale && (
                          <Button
                            variant="outline"
                            className=""
                            onClick={() => {
                              setBooking((prev: any) => {
                                return { ...prev, sale: null, saleId: null }
                              })
                            }}
                          >
                            Clear
                          </Button>
                        )}
                      </div>
                    )}

                    {sales?.length === 0 && (
                      <div>
                        <p className="mt-3 text-gray-500 text-sm">
                          No sale(s) found
                        </p>
                      </div>
                    )}

                    <div className="flex-1" />
                    <div className="mt-5 flex items-center justify-between">
                      <Button
                        variant="outline"
                        onClick={() => setStep(5)}
                        disabled={isSaving}
                      >
                        Back
                      </Button>
                      <Button onClick={update}>Update</Button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </AlertDialogContent>
    </AlertDialog>
  )
}
