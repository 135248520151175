import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from 'components/ui/carousel'
import { ChatMedia } from './media'
import dayjs from 'dayjs'

interface TemplateMessageProps {
  senderName?: string
  templateHeaderFormat?: string
  templateHeaderText?: string
  templateHeaderMediaId?: string
  templateBodyText?: string
  templateFooterText?: string
  templateButtonsTexts?: string[]
  carousel?: any[]
  timestamp?: any
}
export const TemplateMessage = ({
  senderName,
  templateHeaderFormat,
  templateHeaderText,
  templateHeaderMediaId,
  templateBodyText,
  templateFooterText,
  templateButtonsTexts,
  carousel,
  timestamp,
}: TemplateMessageProps) => {
  return (
    <div className="max-w-xs max-md:max-w-[16rem]">
      <div className="bg-gray-100 rounded-2xl py-2 px-4">
        <span className="text-[11px] text-gray-500 font-bold">
          {senderName}
        </span>
        {templateHeaderFormat == 'TEXT' && (
          <span className="block mt-1 w-full font-bold">
            {templateHeaderText}
          </span>
        )}
        {templateHeaderFormat && templateHeaderFormat != 'TEXT' && (
          <ChatMedia
            type={templateHeaderFormat}
            mediaId={templateHeaderMediaId!}
            // TODO: filename to be saved
            filename={'document'}
            isTemplate={true}
          />
        )}
        <span className="block mt-1 w-full pb-3">{templateBodyText}</span>
        {templateFooterText && (
          <div className="border-t">
            <span className="block mt-1 w-full text-gray-500 text-xs">
              {templateFooterText}
            </span>
          </div>
        )}
        {templateButtonsTexts && templateButtonsTexts?.length > 0 && (
          <>
            {templateButtonsTexts.map((text: any) => {
              return (
                <div className="flex justify-center items-center p-2 rounded-lg my-2 border shadow bg-white text-indigo-600">
                  {text}
                </div>
              )
            })}
          </>
        )}
        {carousel && carousel.length > 0 && (
          <Carousel className="w-full max-w-xs bg-slate-100 rounded-md  ">
            <CarouselContent>
              {carousel?.map((card: any, i: any) => {
                return (
                  <CarouselItem key={i}>
                    <div className="p-1 pt-0 w-full">
                      <ChatMedia
                        type={card.headerFormat}
                        mediaId={card.headerMediaId!}
                        // TODO: filename to be saved
                        filename={'document'}
                        isTemplate={true}
                      />
                      <span className="block mt-1 w-full pb-3">
                        {card.bodyText}
                      </span>
                      {card.buttonsTexts.map((text: any) => {
                        return (
                          <div className="flex justify-center items-center p-2 rounded-lg my-2 border shadow bg-white text-indigo-600">
                            {text}
                          </div>
                        )
                      })}
                    </div>
                  </CarouselItem>
                )
              })}
            </CarouselContent>
            <CarouselPrevious className="translate-x-6 text-indigo-600 hover:text-indigo-900" />
            <CarouselNext className=" -translate-x-6 text-indigo-600 hover:text-indigo-900" />
          </Carousel>
        )}
        <span className="block mt-1 w-full text-gray-500 text-[11px] text-right">
          {dayjs(parseInt(timestamp)).format('YYYY-MM-DD HH:mm A')}
        </span>
      </div>
    </div>
  )
}
