import { forwardRef, useEffect, useRef, useState } from 'react'
import { Cross1Icon } from '@radix-ui/react-icons'
import { AlertDialog, AlertDialogContent } from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'
import LeadPopUp from './lead-pop-up'
import ItemPopUp from './item-pop-up'
import dayjs from 'dayjs'
import useApp from 'hooks/useApp'
import { UserSourceStatisticsChart, UserChart } from './users-section'
import { TbFileTypePdf } from 'react-icons/tb'
import { useToast } from 'components/ui/use-toast'
import axios from '../../../lib/axios'

export default forwardRef(({ user, open, setOpen }: any, ref: any) => {
  const { toast } = useToast()

  const [loadingPDFReport, setLoadingPDFReport] = useState(false)

  const { filters, sources, settings } = useApp()

  const [leadPopUpOpen, setLeadPopUpOpen] = useState(false)
  const [notQualifiedLeadsPopUpOpen, setNotQualifiedLeadsPopUpOpen] =
    useState(false)
  const [followUpPopUpOpen, setFollowUpPopUpOpen] = useState(false)
  const [bookingPopUpOpen, setBookingPopUpOpen] = useState(false)

  function divideArray(bookings: any) {
    return bookings.reduce(
      (result: any, booking: any) => {
        if (booking.lead.status === 'SHOW') {
          result[0].push(booking)
        } else if (booking.lead.status === 'NO_SHOW') {
          result[1].push(booking)
        } else {
          result[2].push(booking)
        }
        return result
      },
      [[], [], []]
    )
  }

  const [showBookings, noShowBooking, bookings] = divideArray(user.bookings)
  const [sourcesStatistics, setSourcesStatistics] = useState<any>()

  useEffect(() => {
    const result: any = {}
    sources.forEach((source: any) => {
      result[source.id] = {
        name: source.name,
        leads: 0,
        bookings: 0,
        noShowBooking: 0,
        showBookings: 0,
        followUps: 0,
        notQualified: 0,
      }
    })
    bookings.forEach((booking: any) => {
      result[booking?.lead?.sourceId].bookings++
    })
    showBookings.forEach((booking: any) => {
      result[booking?.lead?.sourceId].showBookings++
    })
    noShowBooking.forEach((booking: any) => {
      result[booking?.lead?.sourceId].noShowBooking++
    })
    user.followUps.forEach((followUp: any) => {
      result[followUp?.lead?.sourceId].followUps++
    })
    user.notQualifiedLeads.forEach((lead: any) => {
      result[lead.sourceId].notQualified++
    })
    user.leads.forEach((lead: any) => {
      result[lead.sourceId].leads++
    })

    setSourcesStatistics(result)
  }, [])

  const userPerformanceRef = useRef<any>()
  const sourceRef = useRef<any>()

  /**
   * Generates a PDF report based on the selected filters and downloads it
   * @returns {Promise<void>}
   */
  const requestUserReportPDF = async () => {
    if (loadingPDFReport) return

    setLoadingPDFReport(true)

    const { dismiss } = toast({
      title: 'Generating PDF',
      description:
        'Please wait, PDF is being generated. This may take a few seconds.',
      duration: 30 * 1000,
    })
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    try {
      const res = await axios.get('/reports/agent-performance', {
        params: {
          user: user.id,
          ...(filters.startDate &&
            filters.endDate && {
              from: dayjs(filters.startDate).toISOString(),
              to: dayjs(filters.endDate).toISOString(),
              ...(timezone && { timezone }),
            }),
          isFreeBranches: filters.isFreeBranches,
          branches: filters.branchIDs,
          isFreeDepartments: filters.isFreeDepartments,
          departments: filters.departmentIDs,
          isFreeSpecialists: filters.isFreeSpecialists,
          specialists: filters.specialistIDs,
        },
        responseType: 'blob',
      })

      downloadPDF(res.data)

      toast({
        title: 'Success',
        description: 'PDF generated successfully',
      })

      dismiss()
    } catch (error) {
      toast({
        title: 'Error occurred',
        description: 'An error occurred while generating dashboard report',
      })
    }

    setLoadingPDFReport(false)
  }

  /**
   * Downloads the given PDF data as a file
   * @param {any} data The PDF data to download
   */
  function downloadPDF(data: string) {
    const pdfBlob = new Blob([data], { type: 'application/pdf' })

    const link = document.createElement('a')
    const url = window.URL.createObjectURL(pdfBlob)
    link.href = url
    link.download = `Doctorna - Agent Performance Report ${dayjs().format('YYYYMMDD HH:mm')}.pdf`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <>
      <AlertDialog open={open} onOpenChange={setOpen}>
        <AlertDialogContent className="sm:max-w-[425px] md:max-w-[1100px] bg-white dark:bg-gray-900 overflow-y-auto max-h-[500px]">
          <div className="flex w-full items-center justify-between">
            <div className="flex items-center gap-4">
              <div className="flex flex-col">
                <h1 className="font-semibold">
                  {user.firstName} {user.lastName}
                </h1>
                <p className="text-gray-500 text-sm">{user.email}</p>
              </div>

              <div
                style={{
                  width: '50vw',
                  position: 'absolute',
                  top: '-10000000px',
                  left: '0px',
                }}
              >
                <div ref={userPerformanceRef}>
                  <UserChart
                    data={{
                      booked: user.bookingCount,
                      followed: user.followUpCount,
                      created: user.leadCount,
                      notQualified: user.notQualifiedLeadsCount,
                      show: showBookings.length,
                      noShow: noShowBooking.length,
                    }}
                  />
                </div>
              </div>
              {sourcesStatistics && (
                <div
                  style={{
                    width: '100vw',
                    position: 'absolute',
                    top: '-10000000000px',
                    left: '0px',
                  }}
                >
                  <div
                    ref={sourceRef}
                    className={`grid gap-3  ${Object.keys(sourcesStatistics).length <= 6 ? 'grid-cols-2 ' : Object.keys(sourcesStatistics).length <= 9 ? 'grid-cols-3' : Object.keys(sourcesStatistics).length <= 12 ? 'grid-cols-4' : 'grid-cols-5'} p-2`}
                  >
                    {Object.keys(sourcesStatistics).map((key: any) => {
                      const source = sourcesStatistics[key]
                      return (
                        <UserSourceStatisticsChart
                          data={{
                            name: source.name,
                            booked: source.bookings,
                            followed: source.followUps,
                            created: source.leads,
                            notQualified: source.notQualified,
                            show: source.showBookings,
                            noShow: source.noShowBooking,
                          }}
                        />
                      )
                    })}
                  </div>
                </div>
              )}

              <Button
                variant={'ghost'}
                className=""
                onClick={() => requestUserReportPDF()}
              >
                Download Report
                <TbFileTypePdf className="ml-4" size={22} />
              </Button>
            </div>
            <div className="flex flex-col"></div>
            <Button
              variant="outline"
              size="icon"
              onClick={() => setOpen(false)}
            >
              <Cross1Icon className="h-3 w-3" />
            </Button>
          </div>
          <>
            <div className="w-full h-full">
              <div className="flex flex-col gap-5 h-full pt-5 text-sm">
                <div className="p-3 rounded-md border border-slate-300 relative mt-2">
                  <div className="absolute  text-xs bg-white -top-2 px-1">
                    {user.leads?.length} leads created
                  </div>
                  {user.leads?.slice(0, 5).length < 1 && (
                    <div className="text-center text-gray-500 text-xs">
                      No leads
                    </div>
                  )}
                  {user.leads?.length > 0 && (
                    <div className="grid grid-cols-3 items-center w-full border-b border-gray-100 last:border-b-0 py-1 gap-4">
                      <div className="font-semibold">Name</div>
                      <div className="font-semibold">Source</div>
                      <div className="font-semibold">Phone</div>
                    </div>
                  )}
                  {user.leads?.slice(0, 5).map((lead: any) => (
                    <div
                      key={lead?.id}
                      className="grid grid-cols-3 items-center w-full border-b border-gray-100 last:border-b-0 py-1 gap-4"
                    >
                      <p className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                        {lead?.firstName} {lead?.lastName}
                      </p>
                      <p className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                        {lead?.source?.name}
                      </p>
                      <p className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                        {lead?.phone}
                      </p>
                    </div>
                  ))}
                  {user.leads?.length > 5 && (
                    <div className="mt-5">
                      <span
                        onClick={() => {
                          setLeadPopUpOpen(true)
                        }}
                        className=" font-bold text-xs cursor-pointer text-indigo-600 duration-300"
                      >
                        +{user.leads?.length - 5} more leads
                      </span>
                    </div>
                  )}
                </div>
                <div className="p-3 rounded-md border border-slate-300 relative mt-2">
                  <div className="absolute  text-xs bg-white -top-2 px-1">
                    {user.followUps?.length} follow ups created by{' '}
                    {user.firstName} {user.lastName}
                  </div>
                  {user.followUps?.slice(0, 5).length < 1 && (
                    <div className="text-center text-gray-500 text-xs">
                      No follow ups
                    </div>
                  )}
                  {user.followUps?.length > 0 && (
                    <div className="grid grid-cols-4 items-center w-full border-b border-gray-100 last:border-b-0 py-1 gap-4">
                      <div className="font-semibold">Name</div>
                      <div className="font-semibold">Comment</div>
                      <div className="font-semibold">Date</div>
                      <div className="font-semibold">Follow up</div>
                    </div>
                  )}
                  {user.followUps?.slice(0, 5).map((followUp: any) => (
                    <div
                      key={followUp?.id}
                      className="grid grid-cols-4 items-center w-full border-b border-gray-100 last:border-b-0 py-1 gap-4"
                    >
                      <p className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                        {followUp?.lead?.firstName} {followUp?.lead?.lastName}
                      </p>
                      <p className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                        {followUp?.comment}
                      </p>
                      <p className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                        {dayjs(followUp?.date).format('DD/MM/YYYY')}
                      </p>
                      <p className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                        {followUp?.index}
                      </p>
                    </div>
                  ))}
                  {user.followUps?.length > 5 && (
                    <div className="mt-5">
                      <span
                        onClick={() => {
                          setFollowUpPopUpOpen(true)
                        }}
                        className=" font-bold text-xs cursor-pointer text-indigo-600 duration-300"
                      >
                        +{user.followUps?.length - 5} more follow ups
                      </span>
                    </div>
                  )}
                </div>
                <div className="p-3 rounded-md border border-slate-300 relative mt-2">
                  <div className="absolute  text-xs bg-white -top-2 px-1">
                    {bookings?.length} bookings
                  </div>
                  {bookings?.slice(0, 5).length < 1 && (
                    <div className="text-center text-gray-500 text-xs">
                      No bookings
                    </div>
                  )}
                  {bookings?.length > 0 && (
                    <div className="grid grid-cols-5 items-center w-full border-b border-gray-100 last:border-b-0 py-1 gap-4">
                      <div className="font-semibold">Name</div>
                      <div className="font-semibold">Date</div>
                      <div className="font-semibold">Branch</div>
                      <div className="font-semibold">Department</div>
                      <div className="font-semibold">Specialist</div>
                    </div>
                  )}
                  {bookings?.slice(0, 5).map((booking: any) => (
                    <div
                      key={booking?.id}
                      className="grid grid-cols-5 items-center w-full border-b border-gray-100 last:border-b-0 py-1 gap-4"
                    >
                      <p className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                        {booking?.lead?.firstName} {booking?.lead?.lastName}
                      </p>
                      <p className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                        {dayjs(booking?.date).format('DD/MM/YYYY')}
                      </p>
                      <p className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                        {booking?.branch?.name}
                      </p>
                      <p className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                        {booking?.department?.name}
                      </p>
                      <p className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                        {booking?.specialist?.name}
                      </p>
                    </div>
                  ))}
                  {bookings?.length > 5 && (
                    <div className="mt-5">
                      <span
                        onClick={() => {
                          setBookingPopUpOpen(true)
                        }}
                        className=" font-bold text-xs cursor-pointer text-indigo-600 duration-300"
                      >
                        +{bookings?.length - 5} more bookings
                      </span>
                    </div>
                  )}
                </div>
                <div className="p-3 rounded-md border border-slate-300 relative mt-2">
                  <div className="absolute  text-xs bg-white -top-2 px-1">
                    {showBookings?.length} Show bookings
                  </div>
                  {showBookings?.slice(0, 5).length < 1 && (
                    <div className="text-center text-gray-500 text-xs">
                      No bookings
                    </div>
                  )}
                  {showBookings?.length > 0 && (
                    <div className="grid grid-cols-5 items-center w-full border-b border-gray-100 last:border-b-0 py-1 gap-4">
                      <div className="font-semibold">Name</div>
                      <div className="font-semibold">Date</div>
                      <div className="font-semibold">Branch</div>
                      <div className="font-semibold">Department</div>
                      <div className="font-semibold">Specialist</div>
                    </div>
                  )}
                  {showBookings?.slice(0, 5).map((booking: any) => (
                    <div
                      key={booking?.id}
                      className="grid grid-cols-5 items-center w-full border-b border-gray-100 last:border-b-0 py-1 gap-4"
                    >
                      <p className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                        {booking?.lead?.firstName} {booking?.lead?.lastName}
                      </p>
                      <p className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                        {dayjs(booking?.date).format('DD/MM/YYYY')}
                      </p>
                      <p className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                        {booking?.branch?.name}
                      </p>
                      <p className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                        {booking?.department?.name}
                      </p>
                      <p className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                        {booking?.specialist?.name}
                      </p>
                    </div>
                  ))}
                  {showBookings?.length > 5 && (
                    <div className="mt-5">
                      <span
                        onClick={() => {
                          setBookingPopUpOpen(true)
                        }}
                        className=" font-bold text-xs cursor-pointer text-indigo-600 duration-300"
                      >
                        +{showBookings?.length - 5} more bookings
                      </span>
                    </div>
                  )}
                </div>
                <div className="p-3 rounded-md border border-slate-300 relative mt-2">
                  <div className="absolute  text-xs bg-white -top-2 px-1">
                    {noShowBooking?.length} No show bookings
                  </div>
                  {noShowBooking?.slice(0, 5).length < 1 && (
                    <div className="text-center text-gray-500 text-xs">
                      No bookings
                    </div>
                  )}
                  {noShowBooking?.length > 0 && (
                    <div className="grid grid-cols-5 items-center w-full border-b border-gray-100 last:border-b-0 py-1 gap-4">
                      <div className="font-semibold">Name</div>
                      <div className="font-semibold">Date</div>
                      <div className="font-semibold">Branch</div>
                      <div className="font-semibold">Department</div>
                      <div className="font-semibold">Specialist</div>
                    </div>
                  )}
                  {noShowBooking?.slice(0, 5).map((booking: any) => (
                    <div
                      key={booking?.id}
                      className="grid grid-cols-5 items-center w-full border-b border-gray-100 last:border-b-0 py-1 gap-4"
                    >
                      <p className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                        {booking?.lead?.firstName} {booking?.lead?.lastName}
                      </p>
                      <p className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                        {dayjs(booking?.date).format('DD/MM/YYYY')}
                      </p>
                      <p className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                        {booking?.branch?.name}
                      </p>
                      <p className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                        {booking?.department?.name}
                      </p>
                      <p className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                        {booking?.specialist?.name}
                      </p>
                    </div>
                  ))}
                  {noShowBooking?.length > 5 && (
                    <div className="mt-5">
                      <span
                        onClick={() => {
                          setBookingPopUpOpen(true)
                        }}
                        className=" font-bold text-xs cursor-pointer text-indigo-600 duration-300"
                      >
                        +{noShowBooking?.length - 5} more bookings
                      </span>
                    </div>
                  )}
                </div>
                <div className="p-3 rounded-md border border-slate-300 relative mt-2">
                  <div className="absolute  text-xs bg-white -top-2 px-1">
                    {user.notQualifiedLeadsCount} leads not qualified
                  </div>
                  {user.notQualifiedLeads?.slice(0, 5).length < 1 && (
                    <div className="text-center text-gray-500 text-xs">
                      No Not qualified leads
                    </div>
                  )}
                  {user.notQualifiedLeads?.length > 0 && (
                    <div className="grid grid-cols-3 items-center w-full border-b border-gray-100 last:border-b-0 py-1 gap-4">
                      <div className="font-semibold">Name</div>
                      <div className="font-semibold">Source</div>
                      <div className="font-semibold">Phone</div>
                    </div>
                  )}
                  {user.notQualifiedLeads?.slice(0, 5).map((lead: any) => (
                    <div
                      key={lead?.id}
                      className="grid grid-cols-3 items-center w-full border-b border-gray-100 last:border-b-0 py-1 gap-4"
                    >
                      <p className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                        {lead?.firstName} {lead?.lastName}
                      </p>
                      <p className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                        {lead?.source?.name}
                      </p>
                      <p className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                        {lead?.phone}
                      </p>
                    </div>
                  ))}
                  {user.notQualifiedLeads?.length > 5 && (
                    <div className="mt-5">
                      <span
                        onClick={() => {
                          setNotQualifiedLeadsPopUpOpen(true)
                        }}
                        className=" font-bold text-xs cursor-pointer text-indigo-600 duration-300"
                      >
                        +{user.notQualifiedLeads?.length - 5} more not qualified
                        leads
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <LeadPopUp
              leads={user.leads}
              open={leadPopUpOpen}
              setOpen={setLeadPopUpOpen}
              canFollowUp={false}
              canBook={false}
              hasActions={false}
            />
            <LeadPopUp
              leads={user.notQualifiedLeads}
              open={notQualifiedLeadsPopUpOpen}
              setOpen={setNotQualifiedLeadsPopUpOpen}
              canFollowUp={false}
              canBook={false}
              hasActions={false}
            />
            <ItemPopUp
              items={user.followUps}
              open={followUpPopUpOpen}
              setOpen={setFollowUpPopUpOpen}
            />
            <ItemPopUp
              items={user.bookings}
              open={bookingPopUpOpen}
              setOpen={setBookingPopUpOpen}
            />
          </>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
})
