import {
  useGetProfileQuery,
  useInitAccountMutation,
} from 'app/features/whatsapp'
import Loader from 'components/shared/loader'
import WhatsappProfileSettings from './whatsapp-profile-settings'
import { toast } from 'components/ui/use-toast'
import { useEffect } from 'react'

export default function WhatsappLogin() {
  const [initAccount] = useInitAccountMutation()

  const { data: profile, isLoading, isError, error } = useGetProfileQuery()

  useEffect(() => {
    const handleIframeMessage = (event: any) => {
      // TODO: to be added later
      // const allowedOrigin = new RegExp(
      //   '^https?://([a-z0-9-]+\\.)?doctorna\\.com$'
      // )
      // if (!allowedOrigin.test(event.origin)) return
      if (event?.data?.type == 'success') {
        initAccount({ code: event.data.code })
      } else if (event?.data?.type == 'error') {
        toast({
          title: 'Error occurred',
          description: event?.data?.error,
        })
      }
    }

    window.addEventListener('message', handleIframeMessage)

    return () => {
      window.removeEventListener('message', handleIframeMessage)
    }
  }, [])

  if (isLoading)
    return (
      <div className="w-full h-[350px] grid place-content-center">
        <Loader />
      </div>
    )

  // TODO: to be added later
  // if (isError)
  //   return (
  //     <div className="w-full h-[350px] grid place-content-center">
  //       <Error message="Server error, contact administrator please" />
  //     </div>
  //   )

  return (
    <div className="flex flex-col space-y-4 justify-center items-center py-14">
      {profile ? (
        <WhatsappProfileSettings profile={profile} />
      ) : (
        <>
          <iframe
            src={process.env.REACT_APP_WHATSAPP_LOGIN_DOMAIN}
            style={{ width: '100%', height: '500px', border: 'none' }}
            title="Whatsapp Login"
          />
        </>
      )}
    </div>
  )
}
