import { useEffect, useState } from 'react'

import {
  useUpdateLeadMutation,
  useGetLeadQuery,
  useAddLeadMutation,
} from 'app/features/lead'
import { isEmail, isPhone } from 'lib/validation'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import useApp from 'hooks/useApp'
import FollowUp from 'pages/leads/components/follow-up'
import Book from 'pages/leads/components/book'
import NotQualified from 'pages/leads/components/not-qualified'
import Status from 'pages/leads/components/status'
import UpdateBook from 'pages/leads/components/update-book'
import ClaimInsurance from 'pages/leads/components/claim-insurance'
import { useSelector } from 'react-redux'
import { useToast } from 'components/ui/use-toast'
import { shouldFollowUp } from 'lib/allowed'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs'
import { LeadDetails } from './components/lead-details'
import { LeadHistory } from './components/lead-history'
import { Actions } from './components/actions'
import SendToInsurance from 'pages/leads/components/send-to-insurance'
import EndInsurance from 'pages/leads/components/end-insurance'
import AttachFile from 'pages/leads/components/attach-file'
import CancelBook from 'pages/leads/components/cancel-book'

const initialLead = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  alternativePhone: '',
  userId: '',
  sourceId: '',
  branchIDs: [],
  departmentIDs: [],
  specialistIDs: [],
  interest: '',
  nationality: '',
  gender: null,
  status: '',
  notQualifiedReason: '',
  emiratesId: '',
  birthDate: null,
  insuranceProviderId: '',
  insuranceType: 'Cash',
  mrnUrn: '',
  insuranceNumber: '',
}

export default function Lead() {
  const { user } = useSelector((state: any) => state.user)
  const navigate = useNavigate()
  const { toast } = useToast()
  const location = useLocation()
  const isDetailsPage = location.pathname.includes('details')
  const { settings, branches, departments, specialists } = useApp()
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>('')
  const [openFollowUp, setOpenFollowUp] = useState(false)
  const [openBook, setOpenBook] = useState(false)
  const [openStatus, setOpenStatus] = useState(false)
  const [openUpdateBook, setOpenUpdateBook] = useState(false)
  const [openCancelBook, setOpenCancelBook] = useState(false)

  const [openNotQualified, setOpenNotQualified] = useState(false)
  const [openClaimInsurance, setOpenClaimInsurance] = useState(false)
  const [openSendToInsurance, setOpenSendToInsurance] = useState(false)
  const [openEndInsurance, setOpenEndInsurance] = useState(false)
  const [openAttachFile, setOpenAttachFile] = useState(false)

  const [numberOfFollowUps, setNumberOfFollowUps] = useState(0)
  const [timeGap, setTimeGap] = useState(0)

  const [
    updateLead,
    { isLoading, isError: isUpdateError, error: updateError },
  ] = useUpdateLeadMutation()
  const {
    data: lead,
    isLoading: loadingLead,
    isError: isErrorLead,
    error: errorLead,
  } = useGetLeadQuery(id)
  const [data, setData] = useState<any>(initialLead)

  useEffect(() => {
    if (settings?.length > 0) {
      setNumberOfFollowUps(settings[0]?.numberOfFollowUps)
      setTimeGap(settings[0]?.followUpTimeGap)
    }
  }, [settings])

  useEffect(() => {
    if (lead) setData(lead)
  }, [lead])

  const handleChange = (e: { target: { id: any; value: any } }) => {
    setData({ ...data, [e.target.id]: e.target.value })
  }

  const startMessagingViaWhatsApp = () => {
    navigate(`/social`, {
      state: {
        leadId: lead?.id,
        leadFirstName: lead?.firstName,
        leadWhatsappNumber: lead?.whatsappNumber,
      },
    })
  }

  function addBranch(id: any) {
    if (data.branchIDs.includes(id)) {
      const newBranches = data.branchIDs.filter((branch: any) => branch !== id)
      setData({ ...data, branchIDs: newBranches })
    } else {
      setData({ ...data, branchIDs: [...data.branchIDs, id] })
    }
  }

  function addDepartment(id: any) {
    if (data.departmentIDs.includes(id)) {
      const newDepartments = data.departmentIDs.filter(
        (department: any) => department !== id
      )
      setData({ ...data, departmentIDs: newDepartments })
    } else {
      setData({ ...data, departmentIDs: [...data.departmentIDs, id] })
    }
  }

  function addSpecialist(id: any) {
    if (data.specialistIDs.includes(id)) {
      const newSpecialists = data.specialistIDs.filter(
        (specialist: any) => specialist !== id
      )
      setData({ ...data, specialistIDs: newSpecialists })
    } else {
      setData({ ...data, specialistIDs: [...data.specialistIDs, id] })
    }
  }

  async function editLead() {
    setError('')
    if (data.firstName.length < 1) {
      return setError('First Name is required')
    }

    if (data.email && !isEmail(data.email)) {
      return setError('Valid email is required')
    }

    if (!isPhone(data.phone)) {
      return setError('Valid phone is required')
    }
    if (data.alternativePhone && !isPhone(data.alternativePhone)) {
      return setError('Valid Alternative phone is required')
    }
    if (!isPhone(data.whatsappNumber)) {
      return setError('Valid whatsapp number is required')
    }
    if (data.sourceId === '') {
      return setError('Source is required')
    }
    if (data.insuranceType == 'Insurance' && !data.insuranceNumber) {
      return setError('Insurance number is required')
    }
    if (data.insuranceType == 'Insurance' && !data.insuranceProviderId) {
      return setError('Insurance provider is required')
    }

    setLoading(true)
    delete data.id
    const result: any = await updateLead({
      id: lead.id,
      data,
    })
    if (result?.error?.status === 409) {
      setLoading(false)
      return setError('Lead already exists')
    }
    if (result?.error) {
      setLoading(false)
      return setError('Server Error, Lead Not Updated')
    }

    setLoading(false)
    if (result?.data) navigate(`/lead-details/${lead.id}`)
  }

  const [
    addLead,
    { isLoading: isDuplicateLoading, isError: isDuplicateError },
  ] = useAddLeadMutation()

  async function duplicateLead() {
    const {
      firstName,
      lastName,
      email,
      phone,
      whatsappNumber,
      alternativePhone,
      nationality,
      gender,
      emiratesId,
      birthDate,
      sourceId,
      branchIDs,
      departmentIDs,
      specialistIDs,
    } = lead
    const result: any = await addLead({
      firstName,
      lastName,
      email,
      phone,
      whatsappNumber,
      alternativePhone,
      nationality,
      gender,
      emiratesId,
      birthDate,
      sourceId,
      branchIDs,
      departmentIDs,
      specialistIDs,
      status: 'NEW',
      userId: user.id,
    })
    if (result?.error) {
      toast({
        title: 'Error',
        description: 'Failed to duplicate lead',
      })
      return
    }

    const newLead = result?.data
    toast({
      title: 'Duplicated Successfully',
      description: 'Lead Duplicated successfully',
    })
    navigate(`/lead-details/${newLead.id}`)
  }

  return (
    <div className="flex-1 h-full bg-white border rounded-md overflow-hidden overflow-y-scroll max-md:pb-10">
      <div className="flex flex-col p-6  gap-3">
        <div className="py-3 items-center gap-3  ">
          <div className="flex flex-col gap-1 ">
            {isDetailsPage && (
              <div className=" flex  items-start justify-center pr-2">
                <Tabs defaultValue="info" className="w-full   ">
                  <TabsList className=" w-full bg-white  px-1 h-10 border ">
                    <TabsTrigger
                      value="info"
                      className="w-full font-semi  text-base data-[state=active]:bg-indigo-600 data-[state=active]:text-white"
                    >
                      Lead Info
                    </TabsTrigger>
                    <TabsTrigger
                      value="history"
                      className="w-full font-semi text-base data-[state=active]:bg-indigo-600 data-[state=active]:text-white"
                    >
                      Patient Journey
                    </TabsTrigger>
                  </TabsList>
                  <TabsContent value="info">
                    <LeadDetails
                      isLoading={isLoading}
                      loadingLead={loadingLead}
                      loading={loading}
                      isErrorLead={isErrorLead}
                      isUpdateError={isUpdateError}
                      errorLead={errorLead}
                      lead={lead}
                      isDetailsPage={isDetailsPage}
                      data={data}
                      setData={setData}
                      handleChange={handleChange}
                      addBranch={addBranch}
                      addDepartment={addDepartment}
                      addSpecialist={addSpecialist}
                      branches={branches}
                      departments={departments}
                      specialists={specialists}
                      error={error}
                      editLead={editLead}
                      navigate={navigate}
                    />
                  </TabsContent>
                  <TabsContent value="history">
                    <LeadHistory
                      isLoading={isLoading}
                      loadingLead={loadingLead}
                      loading={loading}
                      lead={lead}
                      numberOfFollowUps={numberOfFollowUps}
                    />
                  </TabsContent>
                </Tabs>
                <Actions
                  lead={lead}
                  isDetailsPage={isDetailsPage}
                  isLoading={isLoading}
                  id={id}
                  navigate={navigate}
                  user={user}
                  settings={settings}
                  duplicateLead={duplicateLead}
                  isDuplicateLoading={isDuplicateLoading}
                  setOpenStatus={setOpenStatus}
                  setOpenBook={setOpenBook}
                  setOpenCancelBook={setOpenCancelBook}
                  numberOfFollowUps={numberOfFollowUps}
                  setOpenFollowUp={setOpenFollowUp}
                  timeGap={timeGap}
                  setOpenUpdateBook={setOpenUpdateBook}
                  setOpenClaimInsurance={setOpenClaimInsurance}
                  setOpenNotQualified={setOpenNotQualified}
                  setOpenSendToInsurance={setOpenSendToInsurance}
                  setOpenEndInsurance={setOpenEndInsurance}
                  setOpenAttachFile={setOpenAttachFile}
                  startMessagingViaWhatsApp={startMessagingViaWhatsApp}
                />
              </div>
            )}

            {!isDetailsPage && (
              <LeadDetails
                isLoading={isLoading}
                loadingLead={loadingLead}
                loading={loading}
                isErrorLead={isErrorLead}
                isUpdateError={isUpdateError}
                errorLead={errorLead}
                lead={lead}
                isDetailsPage={isDetailsPage}
                data={data}
                setData={setData}
                handleChange={handleChange}
                addBranch={addBranch}
                addDepartment={addDepartment}
                addSpecialist={addSpecialist}
                branches={branches}
                departments={departments}
                specialists={specialists}
                error={error}
                editLead={editLead}
                navigate={navigate}
              />
            )}
          </div>
        </div>
      </div>
      {lead && openFollowUp && (
        <FollowUp
          status={shouldFollowUp(
            lead,
            lead?.followUps[lead.followUps.length - 1],
            numberOfFollowUps,
            timeGap
          )}
          leadId={lead.id}
          open={openFollowUp}
          setOpen={setOpenFollowUp}
          openBook={setOpenBook}
        />
      )}
      {lead && openBook && (
        <Book leadId={lead?.id} open={openBook} setOpen={setOpenBook} />
      )}
      {lead && openStatus && (
        <Status lead={lead} open={openStatus} setOpen={setOpenStatus} />
      )}
      {lead && openUpdateBook && (
        <UpdateBook
          leadId={lead?.id}
          open={openUpdateBook}
          setOpen={setOpenUpdateBook}
        />
      )}

      {lead && openCancelBook && (
        <CancelBook
          leadId={lead?.id}
          open={openCancelBook}
          setOpen={setOpenCancelBook}
        />
      )}
      {lead && openNotQualified && (
        <NotQualified
          lead={lead}
          open={openNotQualified}
          setOpen={setOpenNotQualified}
        />
      )}
      {lead && openClaimInsurance && (
        <ClaimInsurance
          leadId={lead.id}
          open={openClaimInsurance}
          setOpen={setOpenClaimInsurance}
        />
      )}
      {lead && openSendToInsurance && (
        <SendToInsurance
          leadId={lead.id}
          open={openSendToInsurance}
          setOpen={setOpenSendToInsurance}
        />
      )}
      {lead && openEndInsurance && (
        <EndInsurance
          leadId={lead.id}
          open={openEndInsurance}
          setOpen={setOpenEndInsurance}
        />
      )}
      {lead && openAttachFile && (
        <AttachFile
          lead={lead}
          open={openAttachFile}
          setOpen={setOpenAttachFile}
        />
      )}
    </div>
  )
}
