import { useGetMetaContactsQuery } from 'app/features/meta'
import List from './list'

export default function Messenger({
  selectedConversation,
  setSelectedConversation,
}: any) {
  const { data, isLoading, isFetching } = useGetMetaContactsQuery()

  return (
    <List
      contacts={data}
      selectedConversation={selectedConversation}
      setSelectedConversation={setSelectedConversation}
      loading={isLoading || isFetching}
    />
  )
}
