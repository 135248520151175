import { cn, isTimestampPastThan } from 'lib/utils'
import React, { useRef } from 'react'
import { Avatar, AvatarFallback, AvatarImage } from 'components/ui/avatar'
import Bubble from '../components/bubble'
import { Link } from 'react-router-dom'
import ChatBottomBar from '../components/chat-bottom-bar'
import ContactSheet from '../contact/contact-sheet'
import ContactActions from '../contact/contact-actions'
import { WhatsappTags } from '../components/whatsapp-conversation/whatsapp-conversation-tags'
import WhatsappConversationActions from '../components/whatsapp-conversation/whatsapp-conversation-actions'

interface ChatListProps {
  messages?: any[]
  phoneNumber?: string
  selectedLeadId: string
  selectedLead: any
  setSelectedLead: (newMessage: any) => void
  selectedConversationId?: string
  conversationLeads: any
  tags?: any[]
  selectedConversationName?: string
  lastReply: any
  sendMessage: (newMessage: any) => void
  sendMedia: (newMessage: any) => void
  sendTemplate: (template: any) => void
  isFetching?: boolean
  isLoading?: boolean
  initialLead: any
  isDetailsPage: any
  metaConversationId: any
}

export default function WhatsappChatList({
  messages,
  phoneNumber,
  selectedLeadId,
  selectedLead,
  setSelectedLead,
  conversationLeads,
  tags,
  selectedConversationName,
  lastReply,
  selectedConversationId,
  sendMessage,
  sendMedia,
  sendTemplate,
  isFetching,
  isLoading,
  initialLead,
  isDetailsPage,
  metaConversationId,
}: ChatListProps) {
  const messagesContainerRef = useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight
    }
  }, [messages])

  return (
    <div className="relative overflow-y-hidden w-full border rounded-xl overflow-x-auto   ">
      {selectedConversationName && (
        <div className="flex items-center justify-between border-b p-3 ">
          <Link
            className="flex items-center gap-2"
            to={`/lead-details/${selectedLead?.id}`}
          >
            <Avatar className="flex justify-center items-center">
              <AvatarFallback className="bg-indigo-600 text-white capitalize w-10 h-10">
                {selectedLead?.firstName?.[0]}
              </AvatarFallback>
            </Avatar>

            <div className="flex flex-col w-full">
              <span className="text-[1.1rem] truncate">
                {selectedLead?.firstName +
                  ' ' +
                  (selectedLead?.lastName ? selectedLead?.lastName : '')}{' '}
              </span>
            </div>
          </Link>
          {/* <div className="flex items-center gap-2">
            <Avatar className="flex justify-center items-center">
              <AvatarFallback className="bg-indigo-600 text-white capitalize w-10 h-10">
                {selectedConversationName?.[0]}
              </AvatarFallback>
            </Avatar>

            <div className="flex flex-col w-full">
              <span className="text-[1.1rem] truncate">
                {selectedConversationName}
              </span>
            </div>
          </div> */}
          {/* 
          <WhatsappTags conversationId={selectedConversationId} tags={tags} /> */}
          <WhatsappConversationActions
            conversationId={selectedConversationId}
            tags={tags}
            name={selectedConversationName}
          />

          <div className="flex-1 xl:hidden" />
          <div className="xl:hidden flex items-center rounded-md  border ">
            <ContactSheet
              selectedLead={selectedLead}
              conversationLeads={conversationLeads}
              setSelectedLead={setSelectedLead}
              initialLead={initialLead}
              isDetailsPage={isDetailsPage}
              metaConversationId={metaConversationId}
            />

            {selectedLead?.id && <ContactActions leadId={selectedLead?.id} />}
          </div>
        </div>
      )}

      {(isLoading || isFetching) && (
        <div className="grid text-center pt-52 w-full h-full">
          Loading messages ...
        </div>
      )}

      {messages && messages?.length < 1 && (
        <div className="grid text-center pt-52 w-full h-full">No messages</div>
      )}

      <div
        ref={messagesContainerRef}
        className="w-full overflow-y-auto overflow-x-hidden h-[75%] flex flex-col animate-smooth-appear"
      >
        {messages?.map((message, index) => (
          <div
            key={index}
            className={cn(
              'flex flex-col gap-2 p-4 px-0 whitespace-pre-wrap ',
              message.senderId !== selectedConversationId
                ? 'items-end'
                : 'items-start'
            )}
          >
            <div className="flex gap-1 items-center ">
              {message.senderId === selectedConversationId && (
                <Avatar className="flex justify-center items-center">
                  <AvatarImage
                    src="https://i.pinimg.com/564x/0d/64/98/0d64989794b1a4c9d89bff571d3d5842.jpg"
                    alt="@shadcn"
                    width={6}
                    height={6}
                  />
                </Avatar>
              )}
              <Bubble
                content={message.content}
                mediaId={message.mediaId}
                type={message.type}
                filename={message.filename}
                senderName={message.sender?.firstName}
                templateHeaderFormat={message.templateHeaderFormat}
                templateHeaderText={message.templateHeaderText}
                templateHeaderMediaId={message.templateHeaderMediaId}
                templateBodyText={message.templateBodyText}
                templateFooterText={message.templateFooterText}
                templateButtonsTexts={message.templateButtonsTexts}
                referral={message.referral}
                carousel={message.carousel}
                timestamp={message.timestamp}
              />
              {message.senderId !== selectedConversationId && (
                <Avatar className="flex justify-center items-center">
                  <AvatarImage
                    src="https://i.pinimg.com/564x/0d/64/98/0d64989794b1a4c9d89bff571d3d5842.jpg"
                    alt="@shadcn"
                    width={6}
                    height={6}
                  />
                </Avatar>
              )}
            </div>
          </div>
        ))}
      </div>

      {selectedConversationName && (
        <ChatBottomBar
          phoneNumber={phoneNumber}
          lead={selectedLead}
          sendMessage={sendMessage}
          sendMedia={sendMedia}
          sendTemplate={sendTemplate}
          isNewMessageEnabled={
            lastReply && !isTimestampPastThan(lastReply, 24 * 60)
          }
        />
      )}
    </div>
  )
}
