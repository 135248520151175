import dayjs from 'dayjs'

interface TextMessageProps {
  content?: string
  senderName?: string
  timestamp?: any
  created_time?: string
}
export const TextMessage = ({
  senderName,
  content,
  timestamp,
  created_time,
}: TextMessageProps) => {
  return (
    <div className="max-w-xs max-md:max-w-[16rem]">
      <div className="py-2 px-3 bg-gray-100 rounded-2xl">
        <span className="font-bold text-[11px] text-gray-500">
          {senderName}
        </span>
        <span className="block w-full">{content}</span>
      </div>
      <span className="block mt-1 w-full text-gray-500 text-[11px] text-right">
        {created_time
          ? created_time
          : dayjs(parseInt(timestamp)).format('YYYY-MM-DD HH:mm A')}
      </span>
    </div>
  )
}
