import { Cross1Icon } from '@radix-ui/react-icons'
import { AlertDialog, AlertDialogContent } from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'
import useApp from 'hooks/useApp'
import {
  shouldFollowUp,
  shouldBook,
  shouldClaimInsurance,
  hasFollowUpAccess,
  hasBookAccess,
} from 'lib/allowed'
import SendWhatsApp from 'pages/leads/components/send-whatsapp'

import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

export default function LeadPopUp({
  open,
  setOpen,
  leads,
  canFollowUp,
  canBook,
  canClaimInsurance,
  setOpenClaimInsurance,
  setOpenFollowUp,
  setOpenBook,
  setSelectedLead,
  hasActions = true,
  mode = 'followUp',
}: any) {
  const { user } = useSelector((state: any) => state.user)
  const { settings } = useApp()
  const [numberOfFollowUps, setNumberOfFollowUps] = useState(0)
  const [timeGap, setTimeGap] = useState(0)
  useEffect(() => {
    if (settings?.length > 0) {
      setNumberOfFollowUps(settings[0]?.numberOfFollowUps)
      setTimeGap(settings[0]?.followUpTimeGap)
    }
  }, [settings])

  return (
    <>
      <AlertDialog open={open} onOpenChange={setOpen}>
        <AlertDialogContent className="sm:max-w-[425px] md:max-w-[1100px] bg-white dark:bg-gray-900 overflow-y-auto max-h-[500px]">
          <div className="flex w-full items-center justify-between">
            <div className="flex flex-col"></div>
            <Button
              variant="outline"
              size="icon"
              onClick={() => setOpen(false)}
            >
              <Cross1Icon className="h-3 w-3" />
            </Button>
          </div>
          <div className="overflow-hidden overflow-y-scroll">
            <div className="w-full overflow-hidden overflow-x-scroll">
              <table className="w-full table-fixed">
                <thead>
                  <tr className="text-left border-b w-full ">
                    <th className="xl:w-56">Name</th>
                    <th className="xl:w-60 ">Email</th>

                    <th className="xl:w-40">Phone</th>
                    <th className="xl:w-40">Source</th>

                    {hasActions && <th className="">Actions</th>}
                  </tr>
                </thead>
                <tbody>
                  {leads?.map((lead: any) => (
                    <tr
                      key={lead.id}
                      className="w-full border-b border-gray-100 last:border-b-0 py-1"
                    >
                      <td className="overflow-x-hidden whitespace-nowrap text-ellipsis ">
                        {lead?.firstName} {lead?.lastName}
                      </td>
                      <td className="overflow-x-hidden whitespace-nowrap text-ellipsis ">
                        {lead.email}
                      </td>
                      <td className="overflow-x-hidden whitespace-nowrap text-ellipsis ">
                        {mode == 'whatsapp' ? (
                          <>{lead?.whatsappNumber}</>
                        ) : (
                          <>{lead?.phone}</>
                        )}
                      </td>
                      <td className="overflow-x-hidden whitespace-nowrap text-ellipsis ">
                        {lead.source.name}
                      </td>
                      {hasActions && mode != 'whatsapp' && (
                        <>
                          {canFollowUp && (
                            <td className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                              <Button
                                size="sm"
                                variant="outline"
                                onClick={() => {
                                  setSelectedLead(lead)
                                  setOpenFollowUp(true)
                                }}
                                disabled={
                                  lead?.followUps?.length >=
                                    numberOfFollowUps ||
                                  !shouldFollowUp(
                                    lead,
                                    lead?.followUps[lead.followUps.length - 1],
                                    numberOfFollowUps,
                                    timeGap
                                  ) ||
                                  !hasFollowUpAccess(user)
                                }
                              >
                                Follow Up
                              </Button>
                            </td>
                          )}
                          {canBook && (
                            <td className="overflow-x-hidden whitespace-nowrap text-ellipsis ">
                              <Button
                                size="sm"
                                variant="outline"
                                onClick={() => {
                                  setSelectedLead(lead)
                                  setOpenBook(true)
                                }}
                                disabled={
                                  !shouldBook(lead) || !hasBookAccess(user)
                                }
                              >
                                Book
                              </Button>
                            </td>
                          )}
                          {canClaimInsurance && (
                            <td className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                              <Button
                                size="sm"
                                variant="outline"
                                onClick={() => {
                                  setSelectedLead(lead)
                                  setOpenClaimInsurance(true)
                                }}
                                disabled={!shouldClaimInsurance(lead)}
                              >
                                Insurance Approval
                              </Button>
                            </td>
                          )}
                        </>
                      )}
                      {mode == 'whatsapp' && <SendWhatsApp leadId={lead.id} />}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}
