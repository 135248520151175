import { api } from '../api'

type Lead = {
  id: string
  firstName: string
  lastName?: string
  phone?: string
  email?: string
  nationality?: string
  whatsappNumber?: string
}

type Contact = {
  id: string
  phoneNumber: string
  leads: Lead[]
  name: string
  messages: Message[]
}

type Message = {
  content: string
  receiverId: string
  senderId: string
  sender: { firstName: string }
}

type Conversation = {
  messages: Message[]
  createdAt: Date
  leads: any
  phoneNumber: string
  name: string
  tags: any[]
  id: string
}

type MediaResponse = {
  media: string
}

type ContactResponse = Contact[]

type ProfileResponse = {
  profile_picture_url: string
}

type Template = {
  name: string
  status: string
  category: string
  id: string
  language: string
}

type TemplateResponse = {
  data: Template[]
}

export const whatsappApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getContacts: builder.query<ContactResponse, string>({
      query: (keyword: string) => `/whatsapp?keyword=${keyword}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'Contacts' as const,
                id,
              })),
              { type: 'Contacts', id: 'LIST' },
            ]
          : [{ type: 'Contacts', id: 'LIST' }],
    }),

    getConversation: builder.query<Conversation, string | undefined>({
      query: (id) => `/whatsapp/${id}`,
      providesTags: (result) => [
        'Conversations',
        { type: 'Conversations', id: result?.id },
      ],
    }),
    sendMessage: builder.mutation({
      query: (message) => ({
        url: '/whatsapp/message',
        method: 'POST',
        body: message,
      }),
      invalidatesTags: [
        { type: 'Contacts', id: 'LIST' },
        'Contacts',
        'Conversations',
      ],
    }),
    updateConversation: builder.mutation({
      query: (data) => ({
        url: `/whatsapp/${data?.id}`,
        method: 'PUT',
        body: { name: data?.name },
      }),
      invalidatesTags: [
        { type: 'Contacts', id: 'LIST' },
        'Contacts',
        'Conversations',
      ],
    }),
    markWhatsappConversationAsRead: builder.mutation({
      query: (id) => ({
        url: `/whatsapp/mark-as-read/${id}`,
        method: 'PUT',
      }),
      invalidatesTags: [
        { type: 'Contacts', id: 'LIST' },
        'Contacts',
        'Conversations',
      ],
    }),
    getMedia: builder.query<MediaResponse, string | undefined>({
      query: (mediaId) => `/whatsapp/message/${mediaId}`,
    }),
    sendMedia: builder.mutation({
      query: (message) => ({
        url: '/whatsapp/media',
        method: 'POST',
        body: message,
      }),
      invalidatesTags: [
        { type: 'Contacts', id: 'LIST' },
        'Contacts',
        'Conversations',
      ],
    }),
    getProfile: builder.query<ProfileResponse, void>({
      query: () => `/whatsapp/profile`,
      providesTags: ['WhatsappProfile'],
    }),
    updateProfile: builder.mutation({
      query: (profile) => ({
        url: `/whatsapp/profile`,
        method: 'POST',
        body: profile,
      }),
      invalidatesTags: [{ type: 'WhatsappProfile' }],
    }),
    getResponsesReport: builder.query<any, any>({
      query: (filters: any) =>
        `/whatsapp/responses-report?filters=${JSON.stringify(filters)}`,
    }),
    initAccount: builder.mutation({
      query: (data) => ({
        url: `/whatsapp/account`,
        method: 'POST',
        body: data,
      }),
    }),
    updateAccount: builder.mutation({
      query: (data) => ({
        url: `/whatsapp/account`,
        method: 'PUT',
        body: data,
      }),
    }),
    createTemplate: builder.mutation({
      query: (data) => ({
        url: `/whatsapp/template`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'Templates' }],
    }),
    getTemplates: builder.query<TemplateResponse, void>({
      query: () => `/whatsapp/template`,
      providesTags: ['Templates'],
    }),
    sendTemplate: builder.mutation({
      query: (data) => ({
        url: `/whatsapp/send-template`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [
        { type: 'Conversations' },
        { type: 'Contacts', id: 'LIST' },
        'Contacts',
      ],
    }),

    sessionUploadFile: builder.mutation({
      query: (data) => ({
        url: `/whatsapp/session-upload-file`,
        method: 'POST',
        body: data,
        formData: true,
        Accept: '*/*',
      }),
    }),
    mediaFile: builder.mutation({
      query: (message) => ({
        url: '/whatsapp/media-file',
        method: 'POST',
        body: message,
        formData: true,
        Accept: '*/*',
      }),
    }),
    sendCampaign: builder.mutation({
      query: (data) => ({
        url: `/whatsapp/send-campaign`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [
        { type: 'Conversations' },
        { type: 'Contacts', id: 'LIST' },
        'Contacts',
      ],
    }),
    sendBulkCampaign: builder.mutation({
      query: (data) => ({
        url: `/whatsapp/send-bulk-campaign`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [
        { type: 'Conversations' },
        { type: 'Contacts', id: 'LIST' },
        'Contacts',
      ],
    }),
    getCampaigns: builder.query<any, any>({
      query: (filters: any) =>
        `/whatsapp/campaigns?filters=${JSON.stringify(filters)}`,
      providesTags: ['Conversations'],
    }),
    getConversationTags: builder.query<any, void>({
      query: () => '/whatsapp/conversation-tags',
      providesTags: ['Conversations'],
    }),
    addConversationTag: builder.mutation({
      query: (tag) => ({
        url: '/whatsapp/conversation-tags',
        method: 'POST',
        body: {
          ...tag,
        },
      }),
      invalidatesTags: [
        'Conversations',
        { type: 'Contacts', id: 'LIST' },
        'Contacts',
      ],
    }),
    updateConversationTag: builder.mutation({
      query: (tag) => ({
        url: `/whatsapp/conversation-tags/${tag.id}`,
        method: 'PUT',
        body: {
          ...tag.data,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        'Conversations',
        { type: 'Contacts', id: 'LIST' },
        'Contacts',
      ],
    }),
    updateAssignedConversationTags: builder.mutation({
      query: (data) => ({
        url: `/whatsapp/update-assigned-tags/${data.conversationId}`,
        method: 'PUT',
        body: {
          tags: data.tags,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        'Conversations',
        { type: 'Contacts', id: 'LIST' },
        'Contacts',
      ],
    }),
    getMessageSourceMatchings: builder.query<any, void>({
      query: () => '/whatsapp/message-source-matchings',
      providesTags: ['Conversations'],
    }),
    addMessageSourceMatching: builder.mutation({
      query: (matching) => ({
        url: '/whatsapp/message-source-matchings',
        method: 'POST',
        body: {
          ...matching,
        },
      }),
      invalidatesTags: [
        'Conversations',
        { type: 'Contacts', id: 'LIST' },
        'Contacts',
      ],
    }),
    updateMessageSourceMatching: builder.mutation({
      query: (matching) => ({
        url: `/whatsapp/message-source-matchings/${matching.id}`,
        method: 'PUT',
        body: {
          ...matching.data,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        'Conversations',
        { type: 'Contacts', id: 'LIST' },
        'Contacts',
      ],
    }),
  }),
})

export const {
  useGetContactsQuery,
  useLazyGetContactsQuery,
  useGetConversationQuery,
  useUpdateConversationMutation,
  useSendMessageMutation,
  useMarkWhatsappConversationAsReadMutation,
  useGetMediaQuery,
  useSendMediaMutation,
  useGetProfileQuery,
  useGetResponsesReportQuery,
  useLazyGetResponsesReportQuery,
  useUpdateProfileMutation,
  useInitAccountMutation,
  useUpdateAccountMutation,
  useCreateTemplateMutation,
  useGetTemplatesQuery,
  useSendTemplateMutation,
  useSessionUploadFileMutation,
  useMediaFileMutation,
  useSendCampaignMutation,
  useSendBulkCampaignMutation,
  useGetCampaignsQuery,
  useAddConversationTagMutation,
  useGetConversationTagsQuery,
  useUpdateConversationTagMutation,
  useUpdateAssignedConversationTagsMutation,
  useGetMessageSourceMatchingsQuery,
  useUpdateMessageSourceMatchingMutation,
  useAddMessageSourceMatchingMutation,
} = whatsappApi
