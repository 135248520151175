import { Input } from 'components/ui/input'
import { useGetOfferByIdQuery } from 'app/features/offers'
import { Button } from 'components/ui/button'
import { TbX } from 'react-icons/tb'
import { useEffect } from 'react'

/**
 * A component to view an offer's details
 * @param props.offerId - the offer id to view
 * @param props.closeDialog - a function to close the dialog
 */
export default function ViewOffer(props: {
  offerId: string
  closeDialog: () => void
}) {
  const { offerId } = props

  const { data: offer, isLoading, isError } = useGetOfferByIdQuery(offerId)

  if (isLoading) {
    return <p>Loading...</p>
  }

  if (isError || !offer) {
    return <p>Error loading offer details.</p>
  }

  return (
    <div>
      <div className="flex justify-between mb-6">
        <div>
          <h1 className="text-lg font-medium">View Offer</h1>
        </div>
        <div>
          <Button variant="outline" onClick={props.closeDialog}>
            <TbX />
          </Button>
        </div>
      </div>

      <div className="flex flex-col gap-1 mb-4">Name: {offer.name}</div>
      <div className="mb-4">
        <div className="flex flex-col gap-1">
          Description: {offer.description || ''}
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 mb-4">
        <div className="flex flex-col gap-1">
          Price: {offer.price.toString()}
        </div>

        <div className="flex flex-col gap-1">
          Sessions Number: {offer.sessionsNumber.toString()}
        </div>
      </div>
    </div>
  )
}
