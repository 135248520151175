import { useGetStatisticsQuery } from 'app/features/whatsappStats'
import { useEffect, useState } from 'react'
import {
  TbAlertTriangle,
  TbAsterisk,
  TbCheck,
  TbChecks,
  TbInfoHexagon,
  TbMessage,
  TbMessages,
} from 'react-icons/tb'
import Stat from 'components/shared/dashboard-stat/dashboard-stat'
import { toast } from 'components/ui/use-toast'
import { FaAdversal } from 'react-icons/fa'
import { FaT } from 'react-icons/fa6'
import dayjs from 'dayjs'
import { DateProps } from './date-filters'

export const ConversationsStatistics = ({
  startDate,
  startHour,
  startAmpm,
  startMinute,
  endDate,
  endHour,
  endMinute,
  endAmpm,
}: DateProps) => {
  const [totalConversations, setTotalConversations] = useState(0)
  const [totalNewConversations, setTotalNewConversations] = useState(0)
  const [totalOpenConversations, setTotalOpenConversations] = useState(0)
  const [totalRepliedConversations, setTotalRepliedConversations] = useState(0)
  const [totalSentMessages, setTotalSentMessages] = useState(0)
  const [totalDeliveredMessages, setTotalDeliveredMessages] = useState(0)
  const [totalReadMessages, setTotalReadMessages] = useState(0)
  const [totalFailedMessages, setTotalFailedMessages] = useState(0)
  const [totalTemplateMessages, setTotalTemplateMessages] = useState(0)
  const [totalMetaAdMessages, setTotalMetaAdMessages] = useState(0)

  const {
    refetch: refetchStatistics,
    isLoading: isLoadingStatistics,
    data: statisticsData,
    isError: isErrorStatistics,
  } = useGetStatisticsQuery({
    from: dayjs(startDate)
      .hour(Number(startHour))
      .set('hour', Number(startHour) + (startAmpm === 'PM' ? 12 : 0))
      .minute(Number(startMinute))
      .toISOString(),
    to: dayjs(endDate)
      .hour(Number(endHour))
      .set('hour', Number(endHour) + (endAmpm === 'PM' ? 12 : 0))
      .minute(Number(endMinute))
      .toISOString(),
  })

  useEffect(() => {
    if (isErrorStatistics) {
      toast({
        title: 'Error occurred',
        description: 'Error occurred while fetching statistics',
      })
    }
  }, [isErrorStatistics])

  useEffect(() => {
    if (statisticsData) {
      const {
        totalConversations,
        totalNewConversations,
        totalOpenConversations,
        totalRepliedConversations,
        totalSentMessages,
        totalDeliveredMessages,
        totalReadMessages,
        totalFailedMessages,
        totalTemplateMessages,
        totalMetaAdMessages,
      } = statisticsData

      setTotalConversations(totalConversations)
      setTotalNewConversations(totalNewConversations)
      setTotalOpenConversations(totalOpenConversations)
      setTotalRepliedConversations(totalRepliedConversations)
      setTotalSentMessages(totalSentMessages)
      setTotalDeliveredMessages(totalDeliveredMessages)
      setTotalReadMessages(totalReadMessages)
      setTotalFailedMessages(totalFailedMessages)
      setTotalTemplateMessages(totalTemplateMessages)
      setTotalMetaAdMessages(totalMetaAdMessages)
    }
  }, [statisticsData])

  return (
    <>
      <h1 className="text-2xl font-bold">Conversations</h1>
      <div className="grid grid-cols-8 gap-4 m-4 max-lg:grid-cols-4 max-md:grid-cols-2">
        <Stat
          number={totalConversations}
          title="Total"
          icon={<TbMessage size={26} color="gray" />}
          description="Total number of conversations. That's include conversations having all messages created within the date range filter."
        />
        <Stat
          number={totalNewConversations}
          title="New"
          icon={<TbAsterisk size={26} color="gray" />}
          description="New conversations number. That's include conversations that have at least one lead (inquiry) that have been created within the date range filter."
        />
        <Stat
          number={totalOpenConversations}
          title="Open"
          icon={<TbInfoHexagon size={26} color="gray" />}
          description="Open conversations. That's include conversations that have unread messages within the date range filter."
        />
        <Stat
          number={totalRepliedConversations}
          title="Replied"
          icon={<TbMessages size={26} color="gray" />}
          description="Replied conversations. That's include conversations replied by lead within the date range filter."
        />
      </div>

      <h1 className="text-2xl font-bold">Messages </h1>
      <div className="grid grid-cols-8 gap-4 m-4 max-lg:grid-cols-4 max-md:grid-cols-2">
        <Stat
          number={totalSentMessages}
          title="Sent"
          icon={<TbCheck size={26} color="gray" />}
          description="Total number of sent messages"
        />
        <Stat
          number={totalDeliveredMessages}
          title="Delivered"
          icon={<TbChecks size={26} color="gray" />}
          description="Total number of sent messages"
        />
        <Stat
          number={totalReadMessages}
          title="Read"
          icon={<TbChecks size={26} />}
          description="Total number of read messages"
        />
        <Stat
          number={totalFailedMessages}
          title="Failed"
          icon={<TbAlertTriangle size={26} color="red" />}
          description="Total number of failed messages"
        />
        <Stat
          number={totalTemplateMessages}
          title="Template"
          icon={<FaT size={24} color="black" />}
          description="Total number of Template messages sent"
        />
      </div>

      <h1 className="text-2xl font-bold">Meta Ads </h1>
      <div className="grid grid-cols-8 gap-4 m-4 max-lg:grid-cols-4 max-md:grid-cols-2">
        <Stat
          number={totalMetaAdMessages}
          title="Received"
          icon={<FaAdversal size={24} />}
          description="Total number of Meta Ads received"
        />
      </div>
    </>
  )
}
