import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useToast } from 'components/ui/use-toast'
import { Link } from 'react-router-dom'
import { MatchHeaders } from '../../../../leads/components/matchHeaders'
import useApp from 'hooks/useApp'
import { cleanPhoneNumber } from 'lib/utils'
import { IoMdClose } from 'react-icons/io'

export default function BulkUpload({ open, setLeads }: any) {
  const { toast } = useToast()
  const { user, token } = useSelector((state: any) => state.user)
  const [jsonData, setJsonData] = useState<any>(null)
  const [data, setData] = useState<any>(null)
  const [error, setError] = useState<string>('')
  const [matcher, setMatcher] = useState<Map<string, string>>(new Map())
  const [reload, setReload] = useState(false)
  const [csvHeaders, setCsvHeaders] = useState<string[]>([])
  const tableColumns = ['first_name', 'phone']
  const fileRef = useRef<any>()

  const handleMatch = (firstItem: string, secondItem: string) => {
    const newmatcher = new Map(matcher)
    newmatcher.set(firstItem, secondItem)
    setMatcher(newmatcher)
  }

  const convertCSVToJson = (csvData: any) => {
    try {
      const lines = csvData.split('\n')
      const headers = lines[0].split(',')
      if (headers.length < 2)
        throw new Error(
          'Please review the csv file, invalid columns, columns must be  ( first_name, phone )'
        )

      for (let i = 0; i < headers.length; i++) {
        if (!headers[i].trim()) {
          throw new Error('Please review the csv file, some headers are empty')
        }
      }

      setCsvHeaders(headers)
      const result = []

      for (let i = 1; i < lines.length; i++) {
        if (!lines[i]) continue
        const obj: any = {}
        const currentLine = lines[i].split(',')

        for (let j = 0; j < headers.length; j++) {
          obj[headers[j].trim()] = currentLine[j].trim()
        }

        result.push(obj)
      }

      return result
    } catch (error: any) {
      setError(error.message || 'Invalid CSV file')
    }
  }

  const handleCSVInputChange = (event: any) => {
    event.preventDefault()
    setMatcher(new Map())
    setReload(true)
    setJsonData(null)
    setData(null)
    setLeads([])

    try {
      const file = event.target.files[0]
      const reader = new FileReader()

      reader.onload = (e: any) => {
        const csvData = e.target.result
        const jsonData: any = convertCSVToJson(csvData)
        setJsonData(jsonData)
      }

      reader.readAsText(file)
    } catch (error) {
      setError('Invalid CSV file')
    }
  }

  const replaceFileHandler = () => {
    fileRef.current.value = null
    setMatcher(new Map())
    setReload(true)
    setData(null)
    setJsonData(null)
    setError('')
    setLeads([])
  }

  useEffect(() => {
    try {
      if (jsonData) {
        setReload(false)

        if (csvHeaders.length >= 2) setError('')

        if (validateMatcher(matcher) && matcher.size == 2)
          setData(
            jsonData.map((jd: any) => ({
              firstName: jd[matcher.get('first_name')!.toString().trim()],
              phone: jd[matcher.get('phone')!.toString().trim()],
              userId: user.id,
            }))
          )
      }
    } catch (error) {}
  }, [matcher, jsonData])

  const validateMatcher = (matcher: any) => {
    return matcher.get('first_name') && matcher.get('phone')
  }

  useEffect(() => {
    setError('')
    for (let i = 0; i < data?.length; i++) {
      if (data[i].firstName.length < 1)
        return setError('some first names are missed!')

      if (!cleanPhoneNumber(data[i].phone))
        return setError('some phone numbers are invalid!')
    }

    if (data && data.length > 0) {
      const body = data.map(({ phone, ...item }: any) => {
        return {
          phone: cleanPhoneNumber(phone),
          ...item,
        }
      })
      setLeads(body)
    } else {
      if (jsonData) setError('Please review the csv file, invalid columns')
    }
  }, [data])

  useEffect(() => {
    setJsonData(null)
    setData(null)
    setMatcher(new Map())
    setCsvHeaders([])
    setLeads([])

    setError('')
  }, [open])

  const initiateCsvDownload = () => {
    const hiddenElement = document.createElement('a')
    hiddenElement.href =
      'data:text/plain;charset=utf-8,' +
      encodeURIComponent(
        `first_name,phone
John,+971123456789
Jane,+971123456780`
      )
    hiddenElement.download = `leads-template.csv`
    hiddenElement.click()
  }
  const exportAsCsvTemplateHandler = async () => {
    try {
      initiateCsvDownload()
    } catch (error: any) {
      toast({
        title: 'Error occured',
      })
    }
  }

  return (
    <div
      className={`sm:max-w-[425px] md:max-w-[745px] bg-white dark:bg-gray-900 ${jsonData && !reload && ' flex-1 h-full '} overflow-y-scroll`}
    >
      <div>
        <>
          <h1 className="font-semibold text-lg">Bulk Upload</h1>
          <p className="text-sm">
            Upload multiple leads, make sure to{' '}
            <i>
              match the columns of the csv file to be similar to the structure
              shown in the example below{' '}
            </i>
          </p>
          <p className="mt-5 text-sm max-md:hidden">Example CSV file</p>
          <div className="mt-2 text-xs p-3 border bg-gray-100 rounded-md max-md:hidden">
            <pre>
              first_name,phone
              <br />
              John,+971123456789
              <br />
              Jane,+971123456780
            </pre>
          </div>

          {jsonData && !reload && (
            <>
              <p className="my-3 text-md ">Headers Matching</p>
              <MatchHeaders
                onMatch={handleMatch}
                firstHeadersData={tableColumns}
                secondHeadersData={csvHeaders}
                matcher={matcher}
              />
            </>
          )}
          <div className="flex  mt-5 w-full  items-center gap-4 max-md:gap-2">
            <div className=" items-center flex relative w-full">
              {/* <Label>CSV File</Label> */}
              <Input
                id="leads"
                type="file"
                ref={fileRef}
                accept=".csv"
                className="cursor-pointer"
                onChange={handleCSVInputChange}
              />
              <IoMdClose
                size={20}
                className="text-gray-600 hover:cursor-pointer hover:text-gray-900 absolute right-2"
                onClick={replaceFileHandler}
              />
            </div>
            <div className="flex gap-4 justify-end">
              <Button onClick={exportAsCsvTemplateHandler} size="sm">
                Template
              </Button>
            </div>
          </div>

          <div className="flex h-5 w-full items-center text-xs font-bold text-rose-600 mt-">
            {error}
          </div>
        </>
      </div>
    </div>
  )
}
