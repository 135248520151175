import { useGetMediaQuery } from 'app/features/whatsapp'
import dayjs from 'dayjs'

interface ChatListProps {
  type: string
  mediaId: string
  content?: string
  filename?: string
  senderName?: string
  timestamp?: string
  isTemplate?: boolean
}

export function ChatMedia({
  type,
  mediaId,
  content,
  filename,
  senderName,
  timestamp,
  isTemplate = false,
}: ChatListProps) {
  const { data } = useGetMediaQuery(mediaId)

  return (
    <div className={`${!isTemplate && 'py-2 px-3 bg-gray-100 rounded-2xl'}`}>
      {senderName && (
        <span
          className={`font-bold text-[11px] text-gray-500 block ${!isTemplate ? 'max-md:max-w-[16rem]' : 'max-md:max-w-[14rem]'}`}
        >
          {senderName}
        </span>
      )}
      {type === 'IMAGE' && data && (
        <img
          className={`w-80 my-2 max-md:max-w-[14rem]`}
          src={data.media}
          alt="Image"
        />
      )}
      {type === 'STICKER' && data && (
        <img
          className={`h-52 my-2max-md:max-w-[14rem]`}
          src={data.media}
          alt="Image"
        />
      )}
      {type === 'VIDEO' && data && (
        <video
          className={`w-80 my-2 max-md:max-w-[14rem]`}
          src={data.media}
          controls
        />
      )}
      {type === 'AUDIO' && data && (
        <audio src={data.media} className={`max-md:max-w-[14rem]`} controls />
      )}
      {type === 'DOCUMENT' && data && (
        <div
          className={`bg-gray-200 p-4 rounded-lg my-2 max-md:max-w-[14rem]'}`}
        >
          <a
            href={data.media}
            className="document-link text-blue-500"
            target="_blank"
            rel="noopener noreferrer"
            download
          >
            {filename}
          </a>
        </div>
      )}
      {content && (
        <span
          className={`bg-accent p-3 rounded-md max-w-xs max-md:max-w-[14rem]`}
        >
          {content}
        </span>
      )}
      {timestamp && (
        <span
          className={`block mt-1 w-full text-gray-500 text-[11px] text-right max-md:max-w-[14rem]`}
        >
          {dayjs(parseInt(timestamp)).format('YYYY-MM-DD HH:mm A')}
        </span>
      )}
    </div>
  )
}
