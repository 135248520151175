import { api } from '../api'

export const settingsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSettings: builder.query<any, void>({
      query: () => '/settings',
      providesTags: ['Settings'],
    }),
    addSettings: builder.mutation({
      query: (setting) => ({
        url: '/settings',
        method: 'POST',
        body: {
          ...setting,
        },
      }),
      invalidatesTags: [
        { type: 'Sources', id: 'LIST' },
        'Leads',
        'Settings',
        'Dashboard',
      ],
    }),
    updateSettings: builder.mutation({
      query: (setting) => ({
        url: `/settings/${setting.id}`,
        method: 'PUT',
        body: {
          numberOfFollowUps: setting.numberOfFollowUps,
          followUpTimeGap: setting.followUpTimeGap,
          timeAccessType: setting.timeAccessType,
          timeAccessValue: setting.timeAccessValue,
        },
      }),
      invalidatesTags: [
        { type: 'Sources', id: 'LIST' },
        'Leads',
        'Settings',
        'Dashboard',
      ],
    }),
    getAccessPeriod: builder.query<any, void>({
      query: () => '/settings/access-period',
      providesTags: ['Settings'],
    }),
    getForms: builder.query<any, void>({
      query: () => '/settings/forms',
      providesTags: ['Settings'],
    }),
    getForm: builder.query<any, string | undefined>({
      query: (name: string) => `/settings/forms/${name}`,
      providesTags: ['Settings'],
    }),

    addForm: builder.mutation({
      query: (form) => ({
        url: '/settings/forms',
        method: 'POST',
        body: {
          ...form,
        },
      }),
      invalidatesTags: ['Settings'],
    }),
    updateForm: builder.mutation({
      query: (form) => ({
        url: `/settings/forms/${form.id}`,
        method: 'PUT',
        body: {
          ...form.data,
        },
      }),
      invalidatesTags: ['Settings'],
    }),

    disableForm: builder.mutation({
      query: (formId) => ({
        url: `/settings/forms/disable/${formId}`,
        method: 'PUT',
        body: {
          valid: false,
        },
      }),
      invalidatesTags: ['Settings'],
    }),
    getFormTokens: builder.mutation({
      query: (sourceId: any) => ({
        url: '/settings/forms/token',
        method: 'POST',
        body: {
          ...sourceId,
        },
      }),
      invalidatesTags: ['Settings'],
    }),
    getNotQualifiedReasons: builder.query<any, void>({
      query: () => '/settings/not-qualified-reasons',
      providesTags: ['Settings'],
    }),

    addNotQualifiedReason: builder.mutation({
      query: (reason) => ({
        url: '/settings/not-qualified-reasons',
        method: 'POST',
        body: {
          ...reason,
        },
      }),
      invalidatesTags: ['Settings'],
    }),
    updateNotQualifiedReason: builder.mutation({
      query: (reason) => ({
        url: `/settings/not-qualified-reasons/${reason.id}`,
        method: 'PUT',
        body: {
          ...reason.data,
        },
      }),
      invalidatesTags: (result, error, arg) => ['Settings'],
    }),
    updateBrand: builder.mutation({
      query: (data) => ({
        url: `/settings/brand/${data.id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Settings'],
    }),
    getActiveTabs: builder.query<any, void>({
      query: () => '/settings/active-tabs',
      providesTags: ['Settings'],
    }),
    updateActiveTabs: builder.mutation({
      query: (data) => ({
        url: `/settings/active-tabs`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Settings'],
    }),
    getInsuranceProviders: builder.query<any, void>({
      query: () => '/settings/insurance-providers',
      providesTags: ['Settings'],
    }),

    addInsuranceProvider: builder.mutation({
      query: (reason) => ({
        url: '/settings/insurance-providers',
        method: 'POST',
        body: {
          ...reason,
        },
      }),
      invalidatesTags: ['Settings'],
    }),
    updateInsuranceProvider: builder.mutation({
      query: (provider) => ({
        url: `/settings/insurance-providers/${provider.id}`,
        method: 'PUT',
        body: {
          ...provider.data,
        },
      }),
      invalidatesTags: (result, error, arg) => ['Settings'],
    }),

    getInsuranceStatuses: builder.query<any, void>({
      query: () => '/settings/insurance-statuses',
      providesTags: ['Settings'],
    }),

    addInsuranceStatus: builder.mutation({
      query: (reason) => ({
        url: '/settings/insurance-statuses',
        method: 'POST',
        body: {
          ...reason,
        },
      }),
      invalidatesTags: ['Settings'],
    }),
    updateInsuranceStatus: builder.mutation({
      query: (status) => ({
        url: `/settings/insurance-statuses/${status.id}`,
        method: 'PUT',
        body: {
          ...status.data,
        },
      }),
      invalidatesTags: (result, error, arg) => ['Settings'],
    }),
    updateLogo: builder.mutation({
      query: (data) => ({
        url: `/settings/brand/${data.id}/logo`,
        method: 'POST',
        formData: true,
        Accept: '*/*',
        body: data.formData,
      }),
      invalidatesTags: ['Settings'],
    }),
  }),
})

export const {
  useGetSettingsQuery,
  useLazyGetSettingsQuery,
  useAddSettingsMutation,
  useUpdateSettingsMutation,
  useGetAccessPeriodQuery,
  useGetFormsQuery,
  useGetFormQuery,
  useAddFormMutation,
  useUpdateFormMutation,
  useDisableFormMutation,
  useGetFormTokensMutation,
  useGetNotQualifiedReasonsQuery,
  useAddNotQualifiedReasonMutation,
  useUpdateNotQualifiedReasonMutation,
  useUpdateBrandMutation,
  useGetActiveTabsQuery,
  useLazyGetActiveTabsQuery,
  useUpdateActiveTabsMutation,
  useAddInsuranceProviderMutation,
  useGetInsuranceProvidersQuery,
  useUpdateInsuranceProviderMutation,
  useAddInsuranceStatusMutation,
  useGetInsuranceStatusesQuery,
  useUpdateInsuranceStatusMutation,
  useUpdateLogoMutation,
} = settingsApi
