import SendTemplate from './send-template'
import { useState, useEffect } from 'react'
import { SelectTemplate } from './actions/select-template'

export default function TemplateList({
  phoneNumber,
  lead,
  sendTemplate,
  TriggerIcon,
}: {
  phoneNumber?: string
  lead: any
  sendTemplate: (template: any) => void
  TriggerIcon?: any
}) {
  const [openSendTemplate, setOpenSendTemplate] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState<any>(null)

  return (
    <>
      <SelectTemplate
        selectTemplate={(template: any) => {
          setSelectedTemplate(template)
          setOpenSendTemplate(true)
        }}
        selectedTemplate={selectedTemplate}
        displayLabel={false}
        TriggerIcon={TriggerIcon}
        displayCheckIcon={false}
      />
      <SendTemplate
        open={openSendTemplate}
        setOpen={setOpenSendTemplate}
        template={selectedTemplate}
        phoneNumber={phoneNumber}
        lead={lead}
        displaySentTemplate={sendTemplate}
      />
    </>
  )
}
