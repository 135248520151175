import { useState } from 'react'
import Header, { availableMetaSections } from './header'
import Messages from './messages'
import Sidebar from './sidebar'
import { useEffect } from 'react'
import { IOEvents, SocketClient } from 'lib/socket'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ContactSection } from './contact/contact-section'
import { Button } from 'components/ui/button'

export default function Social() {
  const [activeTab, setActiveTab] = useState('WhatsApp')
  const [selectedConversation, setSelectedConversation] = useState<any>(null)
  const [conversationLeads, setConversationLeads] = useState<any>([])
  const [selectedLead, setSelectedLead] = useState<any>(null)
  const [socket, setSocket] = useState<SocketClient>()
  const [blurContent, setBlurContent] = useState(false)
  const location = useLocation()

  const { socketServerToken, user } = useSelector((state: any) => state.user)
  const locationState = location?.state

  const reauthenticateMeta = useSelector(
    (state: any) => state.app.reauthenticateMeta
  )

  useEffect(() => {
    setSocket(SocketClient.getInstance(socketServerToken))
  }, [user])

  useEffect(() => {
    if (!locationState) setSelectedConversation(null)
  }, [activeTab])

  useEffect(() => {
    if (socket) {
      const handleNewMetaMessage = (data: any) => {
        if (data?.savedMessage.conversationId === selectedConversation?.id) {
          setSelectedConversation(selectedConversation)
        }
      }
      const handleNewWhatsAppMessage = (data: any) => {
        if (data?.selectedUser === selectedConversation?.id) {
          setSelectedConversation(selectedConversation)
        }
      }
      socket.on(IOEvents.NEW_META_MESSAGE, handleNewMetaMessage)
      socket.on(IOEvents.NEW_WHATSAPP_MESSAGE, handleNewWhatsAppMessage)
      return () => {
        socket.off(IOEvents.NEW_META_MESSAGE, handleNewMetaMessage)
        socket.off(IOEvents.NEW_WHATSAPP_MESSAGE, handleNewWhatsAppMessage)
      }
    }
  }, [socket, selectedConversation])

  const initialLead = selectedLead
    ? selectedLead
    : {
        firstName: selectedConversation?.name?.split(' ')[0],
        lastName: selectedConversation?.name?.split(' ')[1],
      }

  const ExpiredTokenAlert = () => (
    <div className="dialog-box-sm">
      <div className="col-4">
        To start using WhatsApp Business, log in with your Meta account
        <div className="flex justify-end mt-10">
          <Button
            variant={'outline'}
            size={'sm'}
            className="destructive"
            onClick={() => {
              window.location.href = '/settings?screen=social'
            }}
          >
            Proceed
          </Button>
        </div>
      </div>
    </div>
  )

  useEffect(() => {
    if (reauthenticateMeta) {
      setBlurContent(true)
    } else {
      setBlurContent(false)
    }
  }, [reauthenticateMeta])

  return (
    <>
      {reauthenticateMeta && <ExpiredTokenAlert />}

      <div className={blurContent ? 'dialog-overlay' : ''}>
        <div className="relative p-3 w-full h-screen max-md:h-[90%] max-md:p-0 max-md:pb-1 overflow-hidden">
          <div className="flex flex-col w-full h-full overflow-hidden md:p-0">
            <Header activeTab={activeTab} setActiveTab={setActiveTab} />
            <div className="flex mt-3 w-full h-full overflow-hidden gap-3">
              <div className="flex-1 flex gap-2 px-1">
                {availableMetaSections.includes(activeTab) ? (
                  <>
                    <Sidebar
                      activeTab={activeTab}
                      selectedConversation={selectedConversation}
                      setSelectedConversation={setSelectedConversation}
                      selectedLead={selectedLead}
                      setSelectedLead={setSelectedLead}
                      conversationLeads={conversationLeads}
                      setConversationLeads={setConversationLeads}
                    />
                    {!selectedConversation && (
                      <div className="relative overflow-hidden w-full border rounded-xl">
                        <div className="p-20 grid w-full h-full place-content-center">
                          Start by selecting a user
                        </div>
                      </div>
                    )}
                    {selectedConversation && (
                      <Messages
                        selectedConversationId={selectedConversation.id}
                        selectedLead={selectedLead}
                        setSelectedLead={setSelectedLead}
                        conversationLeads={conversationLeads}
                        setConversationLeads={setConversationLeads}
                        lastReply={selectedConversation.lastReply}
                        initialLead={initialLead}
                        isDetailsPage={!selectedLead}
                        metaConversationId={selectedConversation.id}
                        activeTab={activeTab}
                      />
                    )}
                  </>
                ) : (
                  <div className="h-full w-full flex justify-center items-center">
                    <h1>Coming Soon</h1>
                  </div>
                )}
              </div>
              {selectedConversation && (
                <div className="hidden xl:block overflow-auto w-[300px] ">
                  <ContactSection
                    conversationLeads={conversationLeads}
                    setSelectedLead={setSelectedLead}
                    selectedLead={selectedLead}
                    initialLead={initialLead}
                    isDetailsPage={!selectedLead}
                    metaConversationId={selectedConversation?.id}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
