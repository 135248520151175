import React, { useRef } from 'react'
import ChatBottomBar from '../components/chat-bottom-bar'
import { Avatar, AvatarImage } from 'components/ui/avatar'
import { Link } from 'react-router-dom'
import { MessengerMessage } from './messenger-message'
import ContactSheet from '../contact/contact-sheet'

export function MessengerChatList({
  messages,
  selectedUserId,
  selectedUserName,
  sendMessage,
  sendMedia,
  isFetching,
  isLoading,
  initialLead,
  isDetailsPage,
  metaConversationId,
}: any) {
  const messagesContainerRef = useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight
    }
  }, [messages])

  return (
    <div className="relative overflow-y-hidden w-full border rounded-xl overflow-x-auto">
      {selectedUserName && (
        <div className="flex items-center justify-between border-b p-3">
          <Link
            className="flex items-center gap-2"
            to={`/lead-details/${selectedUserId}`}
          >
            <Avatar className="flex justify-center items-center">
              <AvatarImage
                src="https://i.pinimg.com/564x/0d/64/98/0d64989794b1a4c9d89bff571d3d5842.jpg"
                alt="@shadcn"
                width={6}
                height={6}
                className="w-10 h-10 "
              />
            </Avatar>
            <div className="flex flex-col w-full">
              <span className="text-[1.1rem] truncate">{selectedUserName}</span>
            </div>
          </Link>
          <div className="xl:hidden flex">
            <ContactSheet
              initialLead={initialLead}
              isDetailsPage={isDetailsPage}
              metaConversationId={metaConversationId}
            />
          </div>
        </div>
      )}

      {(isLoading || isFetching) && (
        <div className="grid text-center pt-52 w-full h-full">
          Loading messages ...
        </div>
      )}

      {messages && messages?.length < 1 && (
        <div className="grid text-center pt-52 w-full h-full">No messages</div>
      )}

      <div
        ref={messagesContainerRef}
        className="w-full overflow-y-auto overflow-x-hidden h-[75%] flex flex-col"
      >
        {messages?.map((message: any, index: number) => (
          <MessengerMessage
            index={index}
            message={message}
            selectedUserName={selectedUserName}
            selectedUserId={selectedUserId}
            created_time={message.created_time}
          />
        ))}
      </div>

      {selectedUserName && (
        <ChatBottomBar
          phoneNumber={''}
          lead={{}}
          sendMessage={sendMessage}
          sendMedia={sendMedia}
          sendTemplate={() => {}}
          isNewMessageEnabled={true}
        />
      )}
    </div>
  )
}
