import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu'
import { TbDotsVertical, TbProgressCheck } from 'react-icons/tb'
import { IoCheckmark, IoCheckmarkDone } from 'react-icons/io5'
import { Label } from 'components/ui/label'
import { Button } from 'components/ui/button'
import { MdClose } from 'react-icons/md'
export const Actions = ({
  setOpenUpdate,
  setUpdateStatus,
  setSelectedTask,
  task,
  isUpdateLoading,
  user,
}: any) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className="flex h-8 w-8 p-0 data-[state=open]:bg-muted relative"
        >
          <TbDotsVertical size={18} />
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent align="end" className="w-[260px]">
        {user.id == task.assigneeId && (
          <>
            <DropdownMenuItem
              className="hover:cursor-pointer"
              onClick={() => {
                setOpenUpdate(true)
                setUpdateStatus('In_Progress')
                setSelectedTask(task.id)
              }}
              disabled={isUpdateLoading || task.status == 'In_Progress'}
            >
              <div className="flex gap-3 items-center ">
                <TbProgressCheck size={15} className=" text-orange-700" />
                <span>In Progress</span>
              </div>
            </DropdownMenuItem>
            <DropdownMenuItem
              className="hover:cursor-pointer"
              onClick={() => {
                setOpenUpdate(true)
                setUpdateStatus('In_Review')
                setSelectedTask(task.id)
              }}
              disabled={isUpdateLoading || task.status != 'In_Progress'}
            >
              <div className="flex gap-3 items-center ">
                <IoCheckmark size={15} className=" text-pink-800" />
                <span>In Review</span>
              </div>
            </DropdownMenuItem>
          </>
        )}

        {(user.id == task.reporterId || user.role != 'AGENT') && (
          <>
            <DropdownMenuItem
              className="hover:cursor-pointer"
              onClick={() => {
                setOpenUpdate(true)
                setUpdateStatus('Pending')
                setSelectedTask(task.id)
              }}
              disabled={
                isUpdateLoading || task.status != 'In_Review'
                // ||(task.assigneeId == user.id && task.reporterId != user.id)
              }
            >
              <div className="flex gap-3 items-center ">
                <MdClose size={15} className=" text-orange-500" />
                <span>Pending</span>
              </div>
            </DropdownMenuItem>
            <DropdownMenuItem
              className="hover:cursor-pointer"
              onClick={() => {
                setOpenUpdate(true)
                setUpdateStatus('Completed')
                setSelectedTask(task.id)
              }}
              disabled={
                isUpdateLoading || task.status != 'In_Review'
                // ||(task.assigneeId == user.id && task.reporterId != user.id)
              }
            >
              <div className="flex gap-3 items-center ">
                <IoCheckmarkDone size={15} className=" text-green-600" />
                <span>Completed</span>
              </div>
            </DropdownMenuItem>

            <DropdownMenuItem
              className="hover:cursor-pointer"
              onClick={() => {
                setOpenUpdate(true)
                setUpdateStatus('Canceled')
                setSelectedTask(task.id)
              }}
              disabled={
                isUpdateLoading
                //  ||(task.assigneeId == user.id && task.reporterId != user.id)
              }
            >
              <div className="flex gap-3 items-center ">
                <MdClose size={15} className=" text-red-600" />
                <span>Canceled</span>
              </div>
            </DropdownMenuItem>
          </>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
