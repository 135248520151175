import { Cross1Icon } from '@radix-ui/react-icons'
import Loader from 'components/shared/loader'
import Error from 'components/shared/error'
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogTrigger,
} from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'
import { Label } from 'components/ui/label'
import { useEffect, useState } from 'react'
import { useLazyGetAllTasksQuery } from 'app/features/task'
import MySelect from 'components/shared/select/MySelect'
import { useGetAllAgentsNamesQuery } from 'app/features/users'
import DateAndTime from 'components/shared/date-and-time'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import dayjs from 'dayjs'
import { VscFilePdf } from 'react-icons/vsc'
import { useToast } from 'components/ui/use-toast'
import axios from '../../../lib/axios'

const initialFilters = {
  assignee: [],
  status: 'All',
}

export default function GenerateReport() {
  const { toast } = useToast()

  const {
    data: agents,
    isLoading: isAgentsLoading,
    isError: isAgentsError,
  } = useGetAllAgentsNamesQuery()

  const [error, setError] = useState('')
  const [open, setOpen] = useState(false)
  const [filters, setFilters] = useState<any>(initialFilters)
  const [startDate, setStartDate] = useState<Date | undefined>(undefined)
  const [startHour, setStartHour] = useState<string>('1')
  const [startMinute, setStartMinute] = useState<string>('0')
  const [startAmpm, setStartAmpm] = useState<string>('AM')

  const [endDate, setEndDate] = useState<Date | undefined>(undefined)
  const [endHour, setEndHour] = useState<string>('1')
  const [endMinute, setEndMinute] = useState<string>('0')
  const [endAmpm, setEndAmpm] = useState<string>('AM')

  const [selectedDate, setSelectedDate] = useState<string>('All')

  const [loadingReport, setLoadingReport] = useState(false)

  const handleClearFilters = () => {
    setSelectedDate('All')
    setStartDate(undefined)
    setStartHour('1')
    setStartMinute('0')
    setStartAmpm('AM')
    setEndDate(undefined)
    setEndHour('1')
    setEndMinute('0')
    setEndAmpm('AM')
    setFilters(initialFilters)

    //setOpen(false)
  }
  useEffect(() => {
    setError('')
    handleClearFilters()
  }, [open])

  /**
   * Updates the start and end dates based on the selected date
   */
  useEffect(() => {
    switch (selectedDate) {
      case 'Last 30 days':
        setStartDate(dayjs().subtract(29, 'day').startOf('day').toDate())
        setEndDate(dayjs().subtract(1, 'day').endOf('day').toDate())
        break
      case 'Last month':
        setStartDate(dayjs().subtract(1, 'month').startOf('month').toDate())
        setEndDate(dayjs().subtract(1, 'month').endOf('month').toDate())
        break
      case 'This week':
        setStartDate(dayjs().startOf('week').toDate())
        setEndDate(dayjs().endOf('week').toDate())
        break
      case 'Last week':
        setStartDate(dayjs().subtract(1, 'week').startOf('week').toDate())
        setEndDate(dayjs().subtract(1, 'week').endOf('week').toDate())
        break
      case 'Today':
        setStartDate(dayjs().startOf('day').toDate())
        setEndDate(dayjs().endOf('day').toDate())
        break
      case 'Yesterday':
        setStartDate(dayjs().subtract(1, 'day').startOf('day').toDate())
        setEndDate(dayjs().subtract(1, 'day').endOf('day').toDate())
        break
      case 'All':
        setStartDate(undefined)
        setEndDate(undefined)
        break

      default:
        break
    }
  }, [selectedDate])

  /**
   * Generates a PDF report based on the selected filters and downloads it
   * @returns promise
   */
  const requestReport = async () => {
    if (loadingReport) return

    setLoadingReport(true)

    const { dismiss } = toast({
      title: 'Generating PDF',
      description:
        'Please wait, PDF is being generated. This may take a few seconds.',
      duration: 30 * 1000,
    })
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const from = dayjs(startDate)
      .hour(Number(startHour))
      .minute(Number(startMinute))
      .tz(timezone)
      .toISOString()

    const to = dayjs(endDate)
      .hour(Number(endHour))
      .minute(Number(endMinute))
      .tz(timezone)
      .toISOString()

    try {
      const res = await axios.get('/reports/tasks', {
        params: {
          ...(startDate &&
            endDate && {
              from,
              to,
              ...(timezone && { timezone }),
            }),
          ...(filters.status !== 'All' && { status: filters.status }),
          ...(filters.assignee && {
            assignee: filters.assignee.map((el: any) => el.id),
          }),
        },
        responseType: 'blob',
      })

      downloadPDF(res.data)

      toast({
        title: 'Success',
        description: 'PDF generated successfully',
      })

      dismiss()
    } catch (error) {
      toast({
        title: 'Error occurred',
        description: 'An error occurred while generating dashboard report',
      })
    }

    setLoadingReport(false)
  }

  /**
   * Downloads the given PDF data as a file
   * @param {any} data The PDF data to download
   */
  function downloadPDF(data: string) {
    const pdfBlob = new Blob([data], { type: 'application/pdf' })

    const link = document.createElement('a')
    const url = window.URL.createObjectURL(pdfBlob)
    link.href = url
    link.download = `Doctorna - Tasks Report ${dayjs().format('YYYYMMDD HH:mm')}.pdf`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger asChild>
        <span>
          <Button size="sm">Generate Report</Button>
        </span>
      </AlertDialogTrigger>

      <AlertDialogContent className="sm:max-w-[425px] md:max-w-[745px] bg-white dark:bg-gray-900 pr-3">
        <div className="h-full w-full">
          {isAgentsLoading && (
            <div className="h-40 grid place-content-center">
              <Loader />
            </div>
          )}
          {isAgentsError && (
            <div className="h-40 grid place-content-center">
              <Error message="server error, please contact administrators" />
            </div>
          )}
          {!isAgentsError && !isAgentsLoading && (
            <div className="max-h-[70vh] overflow-hidden overflow-y-scroll">
              <div className="flex w-full items-center justify-between">
                <h1 className="text-lg font-semibold capitalize">
                  Generate Report
                </h1>

                <Button
                  variant="outline"
                  size="icon"
                  onClick={() => setOpen(false)}
                >
                  <Cross1Icon className="h-3 w-3" />
                </Button>
              </div>

              <div className="w-full space-y-5 mt-5 px-1">
                <div className="w-1/3  max-md:w-full">
                  <div className="space-y-2 flex flex-col">
                    <Label className="text-sm font-semibold">Status</Label>
                    <Select
                      value={filters.status.replace('_', ' ')}
                      onValueChange={(value: any) =>
                        setFilters((prev: any) => {
                          return { ...prev, status: value }
                        })
                      }
                    >
                      <SelectTrigger className="w-full ">
                        <SelectValue>{filters.status}</SelectValue>
                      </SelectTrigger>
                      <SelectContent className=" w-40  ">
                        <SelectGroup>
                          <SelectItem value={'Pending'}>Pending</SelectItem>
                          <SelectItem value={'In_Progress'}>
                            In Progress
                          </SelectItem>
                          <SelectItem value={'In_Review'}>In Review</SelectItem>
                          <SelectItem value={'Canceled'}>Canceled</SelectItem>
                          <SelectItem value={'Completed'}>Completed</SelectItem>
                          <SelectItem value="All">All</SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                </div>

                <div className="w-1/3  max-md:w-full">
                  <div className="space-y-2 flex flex-col">
                    <Label className="text-sm font-semibold">Assignee</Label>
                    <MySelect
                      selected={filters.assignee || []}
                      setSelected={(value: any) => {
                        setFilters((prev: any) => {
                          return {
                            ...prev,
                            assignee: [...value],
                          }
                        })
                      }}
                      // data={departmentsData}
                      data={agents?.map((agent: any) => {
                        return {
                          id: agent.id,
                          name: agent.firstName + agent.lastName,
                        }
                      })}
                      selectMeta="agent"
                    />
                  </div>
                </div>
                <div className="flex gap-2 max-md:flex-col">
                  <div className="space-y-2 w-full ">
                    <Label className="text-sm font-semibold">Date</Label>
                    <Select
                      value={selectedDate}
                      onValueChange={(value: any) => setSelectedDate(value)}
                    >
                      <SelectTrigger className="w-full">
                        <SelectValue>{selectedDate}</SelectValue>
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectItem value="Last 30 days">
                            Last 30 days
                          </SelectItem>
                          <SelectItem value="Last month">Last month</SelectItem>
                          <SelectItem value="This week">This week</SelectItem>
                          <SelectItem value="Last week">Last week</SelectItem>
                          <SelectItem value="Today">Today</SelectItem>
                          <SelectItem value="Yesterday">Yesterday</SelectItem>
                          <SelectItem value="Custom date">
                            Custom Date
                          </SelectItem>
                          <SelectItem value="All">All</SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="space-y-2 w-full">
                    <Label className="text-sm font-semibold">Start Date:</Label>
                    <DateAndTime
                      date={startDate}
                      setDate={setStartDate}
                      hour={startHour}
                      setHour={setStartHour}
                      minute={startMinute}
                      setMinute={setStartMinute}
                      ampm={startAmpm}
                      setAmpm={setStartAmpm}
                      disabled={!(selectedDate === 'Custom date')}
                    />
                  </div>
                  <div className="space-y-2 w-full">
                    <Label className="text-sm font-semibold">End Date:</Label>
                    <DateAndTime
                      date={endDate}
                      setDate={setEndDate}
                      hour={endHour}
                      setHour={setEndHour}
                      minute={endMinute}
                      setMinute={setEndMinute}
                      ampm={endAmpm}
                      setAmpm={setEndAmpm}
                      beforeSelectedDate={startDate}
                      disabled={!(selectedDate === 'Custom date')}
                    />
                  </div>
                </div>

                <div className="space-y-3 mt-5">
                  {error !== '' && (
                    <div className="text-rose-600 text-sm">{error}</div>
                  )}

                  <div className="flex items-center gap-3 justify-end">
                    <Button size="sm" onClick={requestReport}>
                      <VscFilePdf size={18} className="" />
                      Generate report
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </AlertDialogContent>
    </AlertDialog>
  )
}
