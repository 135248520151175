import Loader from 'components/shared/loader'
import { Button } from 'components/ui/button'
import { Label } from 'components/ui/label'
import dayjs from 'dayjs'
import { formatPrice } from 'lib/utils'
import { TbCloudDownload, TbUserScan } from 'react-icons/tb'
import { FcOvertime } from 'react-icons/fc'

export const LeadHistory = ({
  isLoading,
  loadingLead,
  loading,
  lead,
  numberOfFollowUps,
}: any) => {
  return (
    <>
      {(isLoading || loadingLead || loading) && (
        <div className="h-screen grid place-content-center">
          <Loader />
        </div>
      )}
      {lead && !isLoading && !loadingLead && !loading && (
        <div className="relative  rounded-lg p-6 max-lg:p-0">
          <div className="relative   rounded-lg p-4 bg-slate-50">
            {lead?.leadActions?.map((action: any, index: any) => (
              <div
                key={index}
                className={`mb-10 ${index === lead.leadActions.length - 1 ? 'mb-0' : 'mb-10'} `}
              >
                <div className=" p-4 pr-10 max-md:pr-6 pt-0 relative min-h-[12rem]  ">
                  <h2 className="text-indigo-500 font-bold  ">
                    {action.action.replaceAll('_', ' ')}
                  </h2>

                  <div className="mt-2">
                    {action.followUpComment && (
                      <p>• comment: {action.followUpComment}</p>
                    )}
                    {action.nextFollowUpTimeGap && (
                      <p className="test-xs ">
                        • Next follow up time gap: {action.nextFollowUpTimeGap}{' '}
                      </p>
                    )}
                    {action.bookDate && (
                      <p>
                        • date:{' '}
                        {dayjs(action.bookDate).format('DD/MM/YYYY hh:mm:A')}
                      </p>
                    )}
                    {action.bookBranch && <p>• branch: {action.bookBranch}</p>}
                    {action.bookDepartment && (
                      <p>• department: {action.bookDepartment}</p>
                    )}
                    {action.bookSpecialist && (
                      <p>• specialist: {action.bookSpecialist}</p>
                    )}
                    {action.bookComment && (
                      <p>• comment: {action.bookComment}</p>
                    )}
                    {action.bookStatusComment && (
                      <p>• comment: {action.bookStatusComment}</p>
                    )}
                    {action.action === 'Update_Book_Status' && (
                      <>
                        <p>
                          • status:{' '}
                          {action.bookStatusIsShow ? 'Show' : 'No Show'}
                        </p>
                        {action.bookStatusIsShow && (
                          <>
                            <p>
                              • is paid:{' '}
                              {action.bookStatusIsPaid ? 'Yes' : 'No'}
                            </p>
                            {action.bookStatusIsPaid && (
                              <p>
                                • amount: {formatPrice(action.bookStatusAmount)}
                              </p>
                            )}
                          </>
                        )}
                      </>
                    )}
                    {action.sendToInsuranceComment && (
                      <p>• comment: {action.sendToInsuranceComment}</p>
                    )}

                    {action.sendToInsuranceEndedComment && (
                      <p>• comment: {action.sendToInsuranceEndedComment}</p>
                    )}
                    {action.insuranceClaimStatus && (
                      <p>• status: {action.insuranceClaimStatus}</p>
                    )}
                    {action?.insuranceClaimExpiryDate && (
                      <p>
                        • expiry date:{' '}
                        {dayjs(action?.insuranceClaimExpiryDate).format(
                          'DD/MM/YYYY hh:mm A'
                        )}{' '}
                      </p>
                    )}
                    {action?.insuranceClaimAuthorizationNumber && (
                      <p>
                        • Authorization number:{' '}
                        {action?.insuranceClaimAuthorizationNumber}{' '}
                      </p>
                    )}
                    {action?.insuranceClaimApprovalAmount && (
                      <p>
                        • Approving amount:{' '}
                        {formatPrice(action?.insuranceClaimApprovalAmount)}{' '}
                      </p>
                    )}
                    {action.insuranceClaimComment && (
                      <p>• comment: {action.insuranceClaimComment}</p>
                    )}
                    {action.notQualifiedReason && (
                      <p>• reason: {action.notQualifiedReason}</p>
                    )}
                    {action.attachmentUrl && (
                      <Button
                        className='className="flex gap-3 items-center justify-start  h-8 mb-2 text-white'
                        onClick={async () => {
                          try {
                            const response = await fetch(action.attachmentUrl)
                            if (!response.ok) {
                              throw new Error('Network response was not ok')
                            }
                            const blob = await response.blob()
                            const downloadUrl = window.URL.createObjectURL(blob)

                            const a = document.createElement('a')
                            a.href = downloadUrl
                            a.download = action.attachmentName
                            document.body.appendChild(a)
                            a.click()
                            window.URL.revokeObjectURL(downloadUrl)
                          } catch (error) {
                            console.error('Download failed:', error)
                          }
                        }}
                      >
                        {' '}
                        <TbCloudDownload size={15} />
                        <span> Download</span>
                      </Button>
                    )}
                    {action?.attachmentDescription && (
                      <p>• Description: {action?.attachmentDescription} </p>
                    )}
                    {action?.saleData && (
                      <>
                        <p>• Offer name: {action?.saleData?.offer?.name}</p>
                        <p>• Value: {action?.saleData?.offer?.price} AED</p>
                        <p>• Note: {action?.saleData?.offer?.note ?? '-'}</p>
                      </>
                    )}
                  </div>
                  <div className="mt-2 text-gray-500 text-xs items-start">
                    <p className=" flex items-center gap-2 ">
                      {/* action at:{' '} */}
                      <FcOvertime size={18} />
                      {dayjs(action.createdAt).format('DD/MM/YYYY hh:mm A')}
                    </p>
                    <p className="flex items-center gap-2 pl-[3px]">
                      {/* action by: */}
                      <TbUserScan
                        size={16}
                        className="group-hover:text-indigo-600"
                      />
                      {action.user?.firstName} {action.user?.lastName}
                    </p>
                  </div>
                  <div className="absolute right-9  max-lg:right-2 top-1 w-3 h-3 bg-indigo-500 z-40 rounded-full" />
                  {index < lead?.leadActions.length - 1 && (
                    <div className="absolute right-[41px] max-lg:right-[13px]  top-8 w-[2px] h-full z-30 bg-slate-100" />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  )
}
