import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts'
import Stat from 'components/shared/dashboard-stat/dashboard-stat'
import { formatDuration } from 'lib/utils'
import { useGetResponseTimeReportQuery } from 'app/features/whatsappStats'
import dayjs from 'dayjs'
import { useEffect } from 'react'
import { toast } from 'components/ui/use-toast'
import { TbAlertTriangle, TbClock, TbRocket } from 'react-icons/tb'
import { DateProps } from './date-filters'

export const ResponseReport = ({
  startDate,
  startHour,
  startAmpm,
  startMinute,
  endDate,
  endHour,
  endMinute,
  endAmpm,
}: DateProps) => {
  const {
    refetch: refetchResponseTimeReport,
    isLoading: isLoadingResponseTimeReport,
    data: responseTimeReport,
    isError: isErrorResponseTimeReport,
  } = useGetResponseTimeReportQuery({
    startDate: dayjs(startDate)
      .hour(Number(startHour))
      .set('hour', Number(startHour) + (startAmpm === 'PM' ? 12 : 0))
      .minute(Number(startMinute))
      .toISOString(),
    endDate: dayjs(endDate)
      .hour(Number(endHour))
      .set('hour', Number(endHour) + (endAmpm === 'PM' ? 12 : 0))
      .minute(Number(endMinute))
      .toISOString(),
  })

  useEffect(() => {
    if (isErrorResponseTimeReport) {
      toast({
        title: 'Error occurred',
        description: 'Error occurred while fetching response time report',
      })
    }
  }, [isErrorResponseTimeReport])

  /**
   * Custom tooltip for displaying WhatsApp response analytics.
   *
   * @param {Object} param
   * @param {boolean} param.active - Whether the tooltip is active.
   * @param {Array} param.payload - Data payload for the tooltip containing response times.
   * @param {string} param.label - Label for the tooltip indicating the time period.
   *
   * @returns {ReactNode|null} - JSX for the tooltip displaying average response times
   *                             or null if inactive or no payload.
   */
  const customResponseTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="card bg-white dark:bg-gray-900 p-2 shadow">
          <p>{label}</p>
          <p style={{ color: '#8884d8' }}>
            Avg. First Response Time: {payload[0].value} seconds (
            {formatDuration(payload[0].value)} )
          </p>
          <p style={{ color: '#82ca9d' }}>
            Avg. Overall Response Time: {payload[1].value} seconds (
            {formatDuration(payload[1].value)})
          </p>
        </div>
      )
    }
  }

  return (
    <>
      <h1 className="text-2xl font-bold mb-2">Response report</h1>
      <div className="text-sm mb-8">
        Tracking the response time of conversations, this chart shows the
        efficiency of our agents in delivering prompt responses.
        <ul className="list-disc list-inside mt-2 ml-4">
          <li>
            The 'First Response Time' represents the time it takes for an agent
            to respond to the first message in a conversation.
          </li>
          <li>
            The 'Overall Response Time' shows the average time it takes for an
            agent to respond to all messages in a conversation.
          </li>
          <li>
            The 'Missed Messages' indicator indicates the number of times an
            agent missed a message in a conversation
          </li>
        </ul>
      </div>

      <div className="grid grid-cols-5 gap-4 m-4 max-lg:grid-cols-4 max-md:grid-cols-2">
        <Stat
          number={formatDuration(
            responseTimeReport?.system?.avgFirstResponseTime
          )}
          title="Avg. first"
          icon={<TbClock size={26} color="gray" />}
          description="The average time it takes for an agent to respond to the first message in a conversation."
        />
        <Stat
          number={formatDuration(
            responseTimeReport?.system?.avgOverallResponseTime
          )}
          title="Avg. All"
          icon={<TbRocket size={26} color="gray" />}
          description="The average time it takes for an agent to respond to all messages in a conversation."
        />
        <Stat
          number={responseTimeReport?.system?.missedMessages}
          title="Missed"
          icon={<TbAlertTriangle size={26} color="gray" />}
          description="The number of messages that were not responded to by an agent within 24h time frame."
        />
      </div>
      <BarChart
        width={1000}
        height={200}
        data={responseTimeReport?.agents}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip content={customResponseTooltip} />
        <Legend />
        <Bar dataKey="avgFirstResponseTime" fill="#8884d8" name="Avg. First" />
        <Bar dataKey="avgOverallResponseTime" fill="#82ca9d" name="Avg. All" />
      </BarChart>
    </>
  )
}
