import { api } from '../api'
import { setCredentials } from '../slices/user'

type TaskFilters = {
  take: number
  skip: number
  status: string
}

type UpdateTask = {
  taskId: string
  status: string
  recurring?: boolean
  recurrenceType?: any
  recurrenceEnd?: Date
}
type CreateTask = {
  title: string
  details: string
  assignee: string[]
  recurring?: boolean
  recurrenceType?: any
  recurrenceEnd?: Date
}

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTasks: builder.query<any, any>({
      query: (filters: TaskFilters) =>
        `/tasks?filters=${JSON.stringify(filters)}`,
      providesTags: ['Tasks'],
    }),
    getAllTasks: builder.query<any, any>({
      query: (filters: TaskFilters) =>
        `/tasks/all?filters=${JSON.stringify(filters)}`,
      providesTags: ['Tasks'],
    }),

    createTask: builder.mutation({
      query: (data: CreateTask) => ({
        url: `/tasks`,
        method: 'Post',
        body: { ...data },
      }),
      invalidatesTags: ['Tasks'],
    }),
    updateTask: builder.mutation({
      query: (data: UpdateTask) => ({
        url: `/tasks/${data.taskId}`,
        method: 'PUT',
        body: { status: data.status },
      }),
      invalidatesTags: ['Tasks'],
    }),
    markTaskAsRead: builder.mutation({
      query: (data: UpdateTask) => ({
        url: `/tasks/${data.taskId}/mark-as-read`,
        method: 'PUT',
      }),
      invalidatesTags: ['Tasks'],
    }),
    getPendingTasks: builder.query<any, any>({
      query: () => `/tasks/pending-tasks-count`,
      providesTags: ['Tasks'],
    }),
    addComment: builder.mutation({
      query: (data: { content: string; taskId: string }) => ({
        url: `/tasks/${data.taskId}/add-comment`,
        method: 'POST',
        body: { content: data.content },
      }),
      invalidatesTags: ['Tasks'],
    }),
  }),
})

export const {
  useCreateTaskMutation,
  useGetTasksQuery,
  useLazyGetAllTasksQuery,
  useUpdateTaskMutation,
  useMarkTaskAsReadMutation,
  useGetPendingTasksQuery,
  useAddCommentMutation,
} = authApi
