import { cn } from 'lib/utils'
import { TooltipProvider } from 'components/ui/tooltip'
import { useMarkWhatsappConversationAsReadMutation } from 'app/features/whatsapp'
import dayjs from 'dayjs'

export default function List({
  contacts,
  selectedConversation,
  setSelectedConversation,
  loading,
}: any) {
  const [markAsRead] = useMarkWhatsappConversationAsReadMutation()

  if (loading) {
    return (
      <div className="w-full h-full grid place-content-center">Loading ...</div>
    )
  }

  return (
    <TooltipProvider delayDuration={0}>
      <div className="flex flex-col gap-2 text-sm animate-smooth-appear ">
        {contacts?.map((contact: any) => (
          <div
            key={contact.id}
            className={cn(
              contact?.id === selectedConversation?.id
                ? 'bg-gray-300'
                : 'bg-gray-50 hover:bg-gray-200 border',
              'cursor-pointer flex gap-2  rounded-xl p-2 w-auto relative min-h-[3.5rem] '
            )}
            onClick={() => {
              setSelectedConversation(contact)
              markAsRead(contact.id)
            }}
          >
            <div className="flex flex-col justify-start gap-1 text-[12px] md:text-xs w-full px-2">
              <div className="flex justify-between w-full items-center">
                <span className="whitespace-nowrap font-medium">
                  {contact?.name?.trim(20)}
                </span>

                <span className="text-[10px] text-gray-500 font-medium">
                  {dayjs(contact?.updatedAt).format('HH:mm')}
                </span>
              </div>
              <div className="flex justify-between items-center w-full  ">
                <div className="flex items-center gap-1  flex-wrap">
                  {contact?.tags?.map((item: any, i: number) => {
                    if (i < 2)
                      return (
                        <span
                          className={`rounded-md px-2 py-[2px] max-w-[6rem] whitespace-nowrap text-ellipsis overflow-hidden text-white text-xs ${i % 2 === 0 ? 'bg-customBlue' : 'bg-customGreen'}  `}
                        >
                          {item.name}
                        </span>
                      )
                  })}
                </div>
                {contact?.messages && contact?.messages?.length > 0 && (
                  <div className="grid place-content-center z-10 text-[9px] w-4 h-4 rounded-full bg-rose-600 text-white">
                    {contact?.messages?.length > 99
                      ? '99+'
                      : contact?.messages?.length}
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </TooltipProvider>
  )
}
