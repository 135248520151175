import { Cross1Icon } from '@radix-ui/react-icons'
import { CustomInput } from 'components/shared/custom-input'
import Loader from 'components/shared/loader'
import Error from 'components/shared/error'
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogTrigger,
} from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'
import { Label } from 'components/ui/label'
import { useEffect, useState } from 'react'
import { useCreateTaskMutation } from 'app/features/task'
import MySelect from 'components/shared/select/MySelect'
import { useGetAllUsersNamesQuery } from 'app/features/users'
import { CustomTextArea } from 'components/shared/custom-text-area'
import DateAndTime from 'components/shared/date-and-time'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import dayjs from 'dayjs'

const initialData = {
  title: '',
  details: '',
  recurring: false,
  recurrenceType: 'Daily',
  assignee: [],
}

export default function CreateTask() {
  const [createTask, { isLoading }] = useCreateTaskMutation()
  const {
    data: users,
    isLoading: isUsersLoading,
    isError: isUsersError,
  } = useGetAllUsersNamesQuery()

  const [error, setError] = useState('')
  const [open, setOpen] = useState(false)
  const [data, setData] = useState<any>(initialData)
  const [recurrenceEndDate, setRecurrenceEndDate] = useState<Date | undefined>(
    undefined
  )
  const [recurrenceEndHour, setRecurrenceEndHour] = useState<string>('1')
  const [recurrenceMinute, setRecurrenceEndMinute] = useState<string>('0')
  const [recurrenceEndAmpm, setRecurrenceEndAmpm] = useState<string>('AM')
  const [dueDate, setDueDate] = useState<Date | undefined>(undefined)
  const [dueHour, setDueHour] = useState<string>('1')
  const [dueMinute, setDueMinute] = useState<string>('0')
  const [dueAmpm, setDueAmpm] = useState<string>('AM')

  useEffect(() => {
    setError('')
    setData(initialData)
    setRecurrenceEndDate(undefined)
  }, [open])

  const handleChange = (e: { target: { id: any; value: any } }) => {
    setData({ ...data, [e.target.id]: e.target.value })
  }

  async function createTaskHandler() {
    if (data.title == '') {
      return setError('title is required')
    }
    if (data.assignee.length == 0) {
      return setError('assignee is required')
    }

    setError('')
    try {
      const result: any = await createTask({
        ...data,
        assignee: data.assignee.map((assignee: any) => assignee.id),
        ...(dueDate && {
          dueDate: dayjs(dueDate)
            .hour(parseInt(dueHour) + (dueAmpm === 'PM' ? 12 : 0))
            .minute(parseInt(dueMinute))
            .toDate(),
        }),
        ...(data.recurring &&
          recurrenceEndDate && {
            recurrenceEnd: dayjs(recurrenceEndDate)
              .hour(
                parseInt(recurrenceEndHour) +
                  (recurrenceEndAmpm === 'PM' ? 12 : 0)
              )
              .minute(parseInt(recurrenceMinute))
              .toDate(),
          }),
      })
      if (result?.error?.status === 409) return setError(result?.error?.data)
      if (result?.error)
        return setError('Failed to create task, please try again')
      setData(initialData)
      setRecurrenceEndDate(undefined)
      setOpen(false)
    } catch (error) {
      return setError('Failed to create task, please try again')
    }
  }

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger asChild>
        <span>
          <Button size="sm">New Task</Button>
        </span>
      </AlertDialogTrigger>

      <AlertDialogContent className="sm:max-w-[425px] md:max-w-[745px] bg-white dark:bg-gray-900 pr-3">
        <div className="h-full w-full">
          {isLoading ||
            (isUsersLoading && (
              <div className="h-40 grid place-content-center">
                <Loader />
              </div>
            ))}
          {isUsersError && (
            <div className="h-40 grid place-content-center">
              <Error message="server error, please contact administrators" />
            </div>
          )}
          {!isUsersError && !isUsersLoading && (
            <div className="max-h-[70vh] overflow-hidden overflow-y-scroll">
              <div className="flex w-full items-center justify-between">
                <h1 className="text-lg font-semibold capitalize">New Task</h1>

                <Button
                  variant="outline"
                  size="icon"
                  onClick={() => setOpen(false)}
                >
                  <Cross1Icon className="h-3 w-3" />
                </Button>
              </div>

              <div className="w-full space-y-3 mt-5 px-1">
                <div className="w-full flex items-center gap-3">
                  <CustomInput
                    name="title"
                    label="Title"
                    value={data.title}
                    setValue={handleChange}
                    type="text"
                    required
                  />
                </div>
                <div className="w-full flex items-center gap-3 max-md:flex-col">
                  <CustomTextArea
                    label="Description"
                    name="details"
                    value={data.details}
                    setValue={handleChange}
                    type="text"
                  />
                </div>
                <div className="flex items-center gap-4 max-md:flex-col py-4">
                  <div className="space-y-2 w-full flex flex-col">
                    <Label>
                      Assign users <span className="ml-1 text-rose-500">*</span>
                    </Label>
                    <MySelect
                      selected={data.assignee}
                      setSelected={(value: any) => {
                        setData((prev: any) => {
                          return {
                            ...prev,
                            assignee: [...value],
                          }
                        })
                      }}
                      // data={departmentsData}
                      data={users?.map((user: any) => {
                        return {
                          id: user.id,
                          name: user.firstName + user.lastName,
                        }
                      })}
                      selectMeta="user"
                    />
                  </div>
                  <div className="flex flex-col gap-2 w-full  ">
                    <Label>Due date</Label>
                    <DateAndTime
                      date={dueDate}
                      setDate={setDueDate}
                      hour={dueHour}
                      setHour={setDueHour}
                      minute={dueMinute}
                      setMinute={setDueMinute}
                      ampm={dueAmpm}
                      setAmpm={setDueAmpm}
                    />
                  </div>
                </div>
                <div className="w-full flex items-start gap-3  py-3">
                  <Label
                    htmlFor="is-insurance-checked"
                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                  >
                    Recurring
                  </Label>
                  <div className="space-y-6 md:ml-10 flex flex-col  items-start">
                    <div className="flex justify-center items-center">
                      <Button
                        className={` transition-all w-20 h-8 rounded-none ${!data?.recurring ? 'bg-indigo-600' : 'bg-indigo-100'}`}
                        onClick={() => {
                          setData((prev: any) => {
                            return {
                              ...prev,
                              recurring: false,
                            }
                          })
                        }}
                      >
                        One Time
                      </Button>
                      <Button
                        className={` transition-all w-20 h-8 rounded-none ${data?.recurring ? 'bg-indigo-600' : 'bg-indigo-100'}`}
                        onClick={() => {
                          setData((prev: any) => {
                            return {
                              ...prev,
                              recurring: true,
                            }
                          })
                        }}
                      >
                        Recurrent
                      </Button>
                    </div>
                    {data?.recurring && (
                      <div className="w-full flex items-center gap-6 max-md:flex-col   ">
                        <div className="flex flex-col gap-2 w-full  ">
                          <Label>
                            Recurrence type{' '}
                            <span className="ml-1 text-rose-500">*</span>
                          </Label>
                          <Select
                            value={data.recurrenceType}
                            onValueChange={(value: any) =>
                              setData((prev: any) => {
                                return { ...prev, recurrenceType: value }
                              })
                            }
                          >
                            <SelectTrigger className="w-full">
                              <SelectValue>{data.recurrenceType}</SelectValue>
                            </SelectTrigger>
                            <SelectContent>
                              <SelectGroup>
                                <SelectItem value="Daily">Daily</SelectItem>
                                <SelectItem value="Weekly">Weekly</SelectItem>
                                <SelectItem value="Monthly">Monthly</SelectItem>
                                <SelectItem value="Yearly">Yearly</SelectItem>
                              </SelectGroup>
                            </SelectContent>
                          </Select>
                        </div>

                        <div className="flex flex-col gap-2 w-full  ">
                          <Label>Recurrence end</Label>
                          <DateAndTime
                            date={recurrenceEndDate}
                            setDate={setRecurrenceEndDate}
                            hour={recurrenceEndHour}
                            setHour={setRecurrenceEndHour}
                            minute={recurrenceMinute}
                            setMinute={setRecurrenceEndMinute}
                            ampm={recurrenceEndAmpm}
                            setAmpm={setRecurrenceEndAmpm}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="space-y-3 mt-5">
                  {error !== '' && (
                    <div className="text-rose-600 text-sm">{error}</div>
                  )}

                  <div className="flex items-center gap-3 justify-end">
                    <Button size="sm" onClick={createTaskHandler}>
                      Create Task
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </AlertDialogContent>
    </AlertDialog>
  )
}
